import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { useAbortedEffect, useConfirm, usePop } from "../hooks";

import ModulePopConfirmFileUpload from "./popConfirm";
import {
  com_downloadFileSingle,
  com_downloadFileMulti,
} from "../../service/downloadFile";
import { getImgData } from "../../service/img";
import { system_file_type } from "../../service/system";

import ImgFile from "../../images/fileUpload.png";

import ComCss from "../../styles/components/upload.module.scss";

import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function ModuleUploadFile(props) {
  const {
    iptId = "",
    isMulti = true,
    zipName = "download",
    pathSecret = "",
    pathPrefix = "",
    data: rawData = [],
    setData = () => {},
    formUpdate = () => {},
    disabled = false,
  } = props;
  const dispatch = useDispatch();
  const { confirmOption, setConfirmOption, renderConfirm } = useConfirm();
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "backup",
      title: () => "確認",
      isOpen: false,
    },
  ]);
  const [uploadData, setUploadData] = useState([]);
  const [isRender, setIsRender] = useState(false);
  // 是否開啟全部下載
  const [isDownload, setIsDownload] = useState(false);

  useAbortedEffect(
    (signal) => {
      if (!isRender) {
        formatDetail({ signal });
      }
    },
    [rawData]
  );

  const formatDetail = async ({ signal }) => {
    if (rawData.length === 0) return;

    setIsRender(true);

    dispatch(setProgress(true));

    let tempRaw = [];
    for (let i = 0; i < rawData.length; i++) {
      let data = rawData[i];
      if (data.name) {
        tempRaw.push({
          ...data,
          key: i,
        });
        continue;
      }

      let ext = data.split(".")[data.split(".").length - 1] ?? null,
        fileName =
          data.split("/")[data.split("/").length - 1].split("_att_")[0] +
          "." +
          ext,
        isImg = system_file_type.img.indexOf(ext) !== -1,
        isPdf = ext === "pdf",
        imgResult = {};

      if (!ext) fileName = data;

      if (isImg) {
        let jsonData = {
          path: pathSecret,
          filename: data,
        };

        imgResult = await getImgData({ jsonData });

        if (!imgResult.data) {
          dispatch(
            setToast({
              severity: "error",
              summary: imgResult.message,
              detail: "",
            })
          );
          return;
        }
      }

      tempRaw.push({
        key: i,
        id: null,
        name: fileName,
        file: "",
        path: imgResult.blobUrl,
        base64: imgResult.data,
        raw: data,
        isImg,
        isPdf,
        isDelete: null,
      });
    }

    setUploadData(tempRaw);

    dispatch(setProgress(false));
  };

  // 判斷是否為備份檔
  function isBackupFile(fileName) {
    return fileName.startsWith("~$") ? true : false;
  }

  // 上傳檔案
  const uploadFile = (e) => {
    let { files } = e.target,
      isBackup = false;

    // 至少一個圖檔
    if (files.length != 0) {
      let temp = [];
      for (let i = 0; i < files.length; i++) {
        temp.push({
          key:
            uploadData.length > 0
              ? uploadData[uploadData.length - 1].key + i + 1
              : i + 1,
          id: null,
          name: files[i].name,
          file: files[i],
          path: "",
          isImg: files[i].type.indexOf("image") !== -1,
          isDelete: null,
          isBackup: isBackupFile(files[i].name),
        });
        let reader = new FileReader();
        reader.onload = (event) => {
          let path = event.target.result;

          // 所有圖皆有小圖
          temp[i].path = path ?? null;
        };
        reader.readAsDataURL(files[i]);
      }

      isBackup = temp.some((item) => item.isBackup);

      if (!isBackup) setFileAction(temp);
      else openPop({ type: "backup", data: temp });
    }
  };

  const setFileAction = (tempFile) => {
    setTimeout(() => {
      let tempData = [...uploadData];
      if (!isMulti) {
        tempData.forEach((item) => {
          item.isDelete = true;
          item.raw = `${pathPrefix}${item.raw}`;
        });
      }

      tempFile = tempData.concat(tempFile);
      setUploadData(tempFile);
      formUpdate();

      document.getElementById(`uploadFile_${iptId}`).value = "";
    }, 200);
  };

  // 復原圖片
  const replayAction = (index) => {
    let temp = [...uploadData];
    temp[index].isDelete = false;
    setUploadData(temp);
    formUpdate();
  };

  // 刪除圖片
  const deleteAction = (index) => {
    let temp = [...uploadData];
    temp[index].isDelete = true;
    temp[index].raw = `${pathPrefix}${temp[index].raw}`;
    setUploadData(temp);
    formUpdate();
  };

  // 取得圖片
  const getFile = async ({ data, uploadIdx, upload }) => {
    let jsonData = {
      path: pathSecret,
      filename: data.raw,
    };
    let result = await getImgData({ jsonData });
    if (!result.data) {
      dispatch(
        setToast({
          severity: "error",
          summary: result.message,
          detail: "",
        })
      );
      return "error";
    }

    upload[uploadIdx] = {
      ...upload[uploadIdx],
      path: result.blobUrl,
      base64: result.data,
    };
    return {
      file: result,
      upload,
    };
  };

  // 顯示檔案 (img、pdf)
  const showFile = async ({ data, index }) => {
    dispatch(setProgress(true));

    let path = data.path;
    if (!path) {
      let temp = [...uploadData];
      let result = await getFile({ data, uploadIdx: index, upload: temp });
      path = result.file.blobUrl;
      setUploadData(result.upload);
    }

    window.open(path, "_blank");
    dispatch(setProgress(false));
  };

  // 下載檔案 (單筆)
  const downloadSingleAction = async ({ data, index }) => {
    try {
      dispatch(setProgress(true));

      let path = data.path;
      if (!path) {
        let temp = [...uploadData];
        let result = await getFile({ data, uploadIdx: index, upload: temp });
        path = result.file.blobUrl;
        setUploadData(result.upload);
      }

      com_downloadFileSingle({
        fileName: data.name,
        url: path,
        finishAction: () => dispatch(setProgress(false)),
      });
    } catch (e) {
      console.log(e);
      dispatch(
        setToast({
          severity: "error",
          summary: "檔案下載失敗",
          detail: "",
        })
      );
      dispatch(setProgress(false));
    }
  };

  // 下載檔案 (多筆)
  const downloadMultiAction = async () => {
    try {
      dispatch(setProgress(true));

      let isCall = false,
        temp = [...uploadData];
      for (let i = 0; i < uploadData.length; i++) {
        let path = uploadData[i].path;
        if (!path) {
          isCall = true;
          let result = await getFile({
            data: uploadData[i],
            uploadIdx: i,
            upload: temp,
          });
          temp = result.upload;
        }
      }
      if (isCall) setUploadData(temp);

      com_downloadFileMulti({
        zipName: zipName,
        fileData: temp,
        finishAction: () => dispatch(setProgress(false)),
      });
    } catch (e) {
      console.log(e);
      dispatch(
        setToast({
          severity: "error",
          summary: "檔案下載失敗",
          detail: "",
        })
      );
      dispatch(setProgress(false));
    }
  };

  // 回傳檔案
  useEffect(() => {
    // 是否有以上傳的檔案 => 有，開啟全部下載
    let isUploaded = uploadData.some((item) => !item.file);
    setIsDownload(isUploaded);
    setData([...uploadData]);
  }, [uploadData]);

  return (
    <>
      {renderConfirm}

      <div className={classNames(ComCss["upload-wrap"])}>
        <div className="flex align-items-center mb-2">
          {!disabled && (
            <>
              <Button
                type="button"
                label={
                  !isMulti && uploadData.length > 0 ? "變更檔案" : "上傳檔案"
                }
                className="px-3 mr-2"
                onClick={() =>
                  document.getElementById(`uploadFile_${iptId}`).click()
                }
              />
              <input
                id={`uploadFile_${iptId}`}
                className="hidden"
                name={`uploadFile_${iptId}`}
                type="file"
                multiple={isMulti}
                onChange={(e) => uploadFile(e)}
              />
            </>
          )}

          {isDownload && isMulti && (
            <Button
              type="button"
              label="全部下載(已上傳)"
              className="p-button-secondary px-3"
              onClick={() => downloadMultiAction()}
            />
          )}

          {uploadData.length > 0 && (
            <>
              <div className="flex flex-column text-sm ml-3">
                <div className="flex align-items-center">
                  <div className="inline-block w-1rem h-1rem bg-green-200 border-circle"></div>
                  <span className="ml-1">已上傳</span>
                </div>

                <div className="flex align-items-center mt-1">
                  <div className="inline-block w-1rem h-1rem bg-red-200 border-circle"></div>
                  <span className="ml-1">未上傳</span>
                </div>
              </div>
            </>
          )}

          {uploadData.length === 0 && disabled && (
            <div className="mt-2">無</div>
          )}
        </div>

        <div className={classNames(ComCss["upload-body"], "grid mt-2")}>
          {uploadData.map((item, index) => (
            <div
              key={`item_${item.key}`}
              className={classNames({
                "col-6 md:col-4 lg:col-3": isMulti,
                "col-12 max-w-20rem": !isMulti,
                hidden: item.isDelete,
              })}
            >
              <div
                className={classNames(ComCss["upload-item"])}
                style={{
                  backgroundImage: `url(${item.isImg ? item.path : ImgFile})`,
                }}
              >
                <div
                  className={classNames(
                    "absolute top-0 left-0 inline-block w-1rem h-1rem border-circle mt-1 ml-1",
                    {
                      "bg-green-200": !item.file,
                      "bg-red-200": item.file,
                    }
                  )}
                ></div>

                {isMulti && (
                  <div className={classNames(ComCss["upload-action"])}>
                    {item.isDelete && !disabled && (
                      <Button
                        className="p-button-secondary"
                        type="button"
                        icon="pi pi-replay"
                        iconPos="right"
                        onClick={() => replayAction(index)}
                      />
                    )}

                    {!item.isDelete && !disabled && (
                      <Button
                        id={`button_img_delete_${index}`}
                        className="p-button-danger"
                        type="button"
                        icon="pi pi-trash"
                        iconPos="right"
                        onClick={() =>
                          setConfirmOption({
                            setting: {
                              target: document.getElementById(
                                `button_img_delete_${index}`
                              ),
                              message: "確認是否刪除",
                              icon: "pi pi-exclamation-triangle",
                              visible: !confirmOption.setting.visible,
                              acceptLabel: "是",
                              rejectLabel: "否",
                              accept: () => deleteAction(index),
                              reject: () => null,
                            },
                            reOpen: !!confirmOption.setting.visible,
                          })
                        }
                      />
                    )}
                  </div>
                )}

                <div
                  className={classNames(ComCss["upload-pointer"], {
                    [ComCss["upload-local"]]: item.file,
                    [ComCss["upload-delete"]]: item.isDelete,
                  })}
                >
                  {!item.file && !item.isDelete && (
                    <>
                      {(item.isImg || item.isPdf) && (
                        <i
                          className="pi pi-search hover:text-blue-100 cursor-pointer mx-3"
                          title="檢視"
                          onClick={() => showFile({ data: item, index })}
                        ></i>
                      )}
                      <i
                        className="pi pi-download hover:text-blue-100 cursor-pointer mx-3"
                        title="下載"
                        onClick={() =>
                          downloadSingleAction({ data: item, index })
                        }
                      ></i>
                    </>
                  )}
                </div>
              </div>
              <div className={ComCss["upload-filename"]}>{item.name}</div>
            </div>
          ))}
        </div>
      </div>

      {renderDialog({
        className: "w-11 lg:w-8 md:w-9 sm:w-10 max-w-30rem",
        children: (
          <>
            {isPop.backup?.isOpen && (
              <ModulePopConfirmFileUpload
                prePopOption={popOption}
                closePrePop={closePop}
                setFileAction={setFileAction}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
