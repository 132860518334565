import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function ModuleChartStackbar({ cData, cOptions = {} }) {
  const colors = ["#ffa39c", "#aad5c9", "#88b4cc", "#f1cd85", "#dc824f"];

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={cData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={cOptions.dataKey || "name"} />
          <YAxis />
          <Tooltip />
          <Legend />
          {cOptions.barKey.map((key, index) => (
            <Bar key={key} dataKey={key} stackId="a" fill={colors[index]} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
