import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";

export default function FormCheckbox(props) {
  const {
    className,
    setting: {
      Controller,
      control,
      setValue,
      trigger,
      isSubmitted,
      isEdit = true,
    },
    data: {
      name,
      label,
      value,
      type = "multi",
      disabled = false,
      isWrap = false,
    },
  } = props;

  const onChange = (e, field) => {
    const {
      checked,
      target: { name, value },
    } = e;

    if (type === "multi") {
      if (checked) field.value.push(e.value);
      else field.value.splice(field.value.indexOf(value), 1);
    } else if (type === "checked") {
      field.value = checked;
    }

    // 因為是客製化的 function，設定欄位值後(setValue)，需要觸發(trigger) hookform，才會被驗證
    setValue(name, field.value);
    if (isSubmitted) {
      trigger();
    } else {
      trigger(name);
    }
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <div
              className={classNames(
                className,
                "relative flex align-items-center min-w-220 ml-1 mr-3 my-2"
              )}
            >
              <Checkbox
                inputId={`${field.name}_${value}`}
                className={classNames({ "p-invalid": fieldState.invalid })}
                {...field}
                value={value}
                checked={
                  type === "multi"
                    ? field.value.indexOf(value) !== -1
                    : field.value
                }
                onChange={(e) => onChange(e, field)}
                disabled={!isEdit || disabled}
              />
              <label
                htmlFor={`${field.name}_${value}`}
                className={classNames("ml-1", {
                  "white-space-nowrap": !isWrap,
                })}
              >
                {label}
              </label>
            </div>
          </>
        )}
      />
    </>
  );
}
