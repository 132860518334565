import { useEffect } from "react";
import { useNavigate, useBlocker } from "react-router-dom";
import { useBeforeUnload } from "react-use";

export default function useLeavePageConfirm({ isFormUpdate, setIsFormUpdate }) {
  const route_navigate = useNavigate();
  const message = "系統不會儲存您所做的變更。";
  // 阻擋跳轉
  const blocker = useBlocker(isFormUpdate);

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (window.confirm(message)) {
        setIsFormUpdate(false);
      }
    }
  }, [blocker]);

  useEffect(() => {
    if (isFormUpdate || !blocker?.location?.pathname) return;

    route_navigate(blocker.location.pathname);
  }, [isFormUpdate]);

  // 瀏覽器 重整 或 關閉頁簽 阻擋
  useBeforeUnload(isFormUpdate, message);
}
