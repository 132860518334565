import request from "../request";
import Url from "../url/assessment";

const getExamItemsList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getExamItemsList + path, data, options);
};
const getExamItemsDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getExamItemsDetails + path, data, options);
};
const setExamItems = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setExamItems + path, data, options);
};
const updateExamItems = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateExamItems + path, data, options);
};

const getTmpExamExamplesList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getTmpExamExamplesList + path, data, options);
};
const getTmpExamExamplesDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getTmpExamExamplesDetails + path, data, options);
};
const setTmpExamExamples = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setTmpExamExamples + path, data, options);
};
const updateTmpExamExamples = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateTmpExamExamples + path, data, options);
};

const getExamExamplesList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getExamExamplesList + path, data, options);
};
const getExamExamplesDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getExamExamplesDetails + path, data, options);
};
const setExamExamples = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setExamExamples + path, data, options);
};
const updateExamExamples = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateExamExamples + path, data, options);
};

const getMyExamList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getMyExamList + path, data, options);
};
const getMyExamDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getMyExamDetails + path, data, options);
};
const setExamResult = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setExamResult + path, data, options);
};
const updateExamResult = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateExamResult + path, data, options);
};

const getHistoryExamList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getHistoryExamList + path, data, options);
};
const getHistoryExamDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getHistoryExamDetails + path, data, options);
};

const getRaterList = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.getRaterList + path, data, options);
};
const getRatederList = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.getRatederList + path, data, options);
};

const getAllExamList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getAllExamList + path, data, options);
};
const getAllExamDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getAllExamDetails + path, data, options);
};
const getAllHistoryList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getAllHistoryList + path, data, options);
};
const getAllHistoryExamDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getAllHistoryExamDetails + path, data, options);
};
const setExamResultBonus = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setExamResultBonus + path, data, options);
};

export default {
  getExamItemsList,
  getExamItemsDetails,
  setExamItems,
  updateExamItems,

  getTmpExamExamplesList,
  getTmpExamExamplesDetails,
  setTmpExamExamples,
  updateTmpExamExamples,

  getExamExamplesList,
  getExamExamplesDetails,
  setExamExamples,
  updateExamExamples,

  getMyExamList,
  getMyExamDetails,
  setExamResult,
  updateExamResult,

  getHistoryExamList,
  getHistoryExamDetails,

  getRaterList,
  getRatederList,

  getAllExamList,
  getAllExamDetails,
  getAllHistoryList,
  getAllHistoryExamDetails,
  setExamResultBonus,
};
