import request from "../request";
import Url from "../url/setting";

const getGoodAtToolsList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getGoodAtToolsList + path, data, options);
};
const getGoodAtToolsDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getGoodAtToolsDetails + path, data, options);
};
const setGoodAtTools = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setGoodAtTools + path, data, options);
};
const updateGoodAtTools = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateGoodAtTools + path, data, options);
};

const getLanguagesList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getLanguagesList + path, data, options);
};
const getLanguagesDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getLanguagesDetails + path, data, options);
};
const setLanguages = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setLanguages + path, data, options);
};
const updateLanguages = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateLanguages + path, data, options);
};

const getDepartmentTreeList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getDepartmentTreeList + path, data, options);
};
const getDepartmentList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getDepartmentList + path, data, options);
};
const setDepartmentTree = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setDepartmentTree + path, data, options);
};
const updateDepartmentTree = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateDepartmentTree + path, data, options);
};

const getJobTitleTreeList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getJobTitleTreeList + path, data, options);
};
const getJobTitleList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getJobTitleList + path, data, options);
};
const setJobTitleTree = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setJobTitleTree + path, data, options);
};
const updateJobTitleTree = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateJobTitleTree + path, data, options);
};

const getAttendanceLeaveScoreType = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.get(Url.getAttendanceLeaveScoreType + path, data, options);
};
const getAttendanceLeaveScoreTypeDetails = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.get(
    Url.getAttendanceLeaveScoreTypeDetails + path,
    data,
    options
  );
};
const setAttendanceLeaveScoreType = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.post(Url.setAttendanceLeaveScoreType + path, data, options);
};
const updateAttendanceLeaveScoreType = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.post(Url.updateAttendanceLeaveScoreType + path, data, options);
};

const edTest = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.edTest + path, data, options);
};
const deTest = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.deTest + path, data, options);
};

export default {
  getGoodAtToolsList,
  getGoodAtToolsDetails,
  setGoodAtTools,
  updateGoodAtTools,

  getLanguagesList,
  getLanguagesDetails,
  setLanguages,
  updateLanguages,

  getDepartmentTreeList,
  getDepartmentList,
  setDepartmentTree,
  updateDepartmentTree,

  getJobTitleTreeList,
  getJobTitleList,
  setJobTitleTree,
  updateJobTitleTree,

  getAttendanceLeaveScoreType,
  getAttendanceLeaveScoreTypeDetails,
  setAttendanceLeaveScoreType,
  updateAttendanceLeaveScoreType,

  edTest,
  deTest,
};
