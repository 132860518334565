import { useState, useEffect } from "react";
import {
  usePDF,
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
} from "@react-pdf/renderer";

import ModulePdfOutputMain from "./output/main";

import { saveAs } from "file-saver";

import { Button } from "primereact/button";

export function ModulePdfDownloadLink(props) {
  const { optionData, memberData, signImg, setBlobFile } = props;
  const [isDownload, setIsDownload] = useState(false);

  return (
    <>
      <PDFDownloadLink
        document={
          <ModulePdfOutputMain
            optionData={optionData}
            memberData={memberData}
            signImg={signImg}
          />
        }
        fileName={`${memberData.ch_last_name + memberData.ch_first_name}.pdf`}
      >
        {({ blob, url, loading, error }) => {
          if (blob) setBlobFile(blob);
          return loading ? "Loading document..." : "Download now!";
        }}
      </PDFDownloadLink>
    </>
  );
}

export function ModulePdfExport(props) {
  const { className, btnLabel, pdfName, finishAction } = props;
  const [instance] = usePDF({
    document: <ModulePdfOutputMain />,
  });

  const exportPDF = () => {
    saveAs(instance.blob, `${pdfName}.pdf`);
    finishAction();
  };

  return (
    <>
      <Button
        className={className}
        type="button"
        label={btnLabel}
        onClick={() => exportPDF()}
      />
    </>
  );
}

export function ModulePdfView({ optionData, memberData, signImg }) {
  return (
    <>
      <PDFViewer
        width="100%"
        height="90%"
        style={{ border: "none" }}
        showToolbar={false}
      >
        <ModulePdfOutputMain
          optionData={optionData}
          memberData={memberData}
          signImg={signImg}
        />
      </PDFViewer>
    </>
  );
}
