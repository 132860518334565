import { useDispatch } from "react-redux";
import { setUser } from "../../redux/slice/user";

const useClearLocalStorage = () => {
  const dispatch = useDispatch();

  return () => {
    let localData = [
      {
        name: "user",
        action: (e) => setUser(e),
      },
    ];
    localData.forEach((item) => dispatch(item.action(null)));
  };
};

export default useClearLocalStorage;
