import { Button } from "primereact/button";

export default function ModulePopConfirmFileUpload(props) {
  const { prePopOption, closePrePop, setFileAction } = props;

  const rejectAction = () => {
    closePrePop({ type: "backup" });
    setFileAction(prePopOption.data.filter((item) => !item.isBackup));
  };

  const confirmAction = () => {
    closePrePop({ type: "backup" });
    setFileAction(prePopOption.data);
  };

  return (
    <>
      <div className="px-3">
        <div className="text-lg text-center">
          以下檔案可能為暫存的備份檔，請確認是否上傳
        </div>
        <div className="text-lg text-center text-red-400 mt-2">
          * 建議選擇原始檔案上傳
        </div>

        <table className="w-full text-center mt-3 custom-tb">
          <thead>
            <tr>
              <th>檔名</th>
            </tr>
          </thead>
          <tbody>
            {prePopOption.data
              ?.filter((item) => item.isBackup)
              .map((item, index) => (
                <tr key={`item_${item.key}`}>
                  <td>{item.name}</td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="w-full text-center mt-3">
          <Button
            className="p-button-secondary px-4"
            type="button"
            label="否"
            onClick={() => rejectAction()}
          />
          <Button
            className="p-button-danger ml-4 px-4"
            type="button"
            label="是"
            onClick={() => confirmAction()}
          />
        </div>
      </div>
    </>
  );
}
