import { CommonAPI } from "../axios/api";
import { Buffer } from "buffer";
import CryptoJS from "crypto-js";

import { dataURLtoFile, dataURLtoBase64 } from "./common";
import { system_file_type } from "./system";

export async function getImgData({ signal, jsonData }) {
  return new Promise((reslove) => {
    CommonAPI.getImg({
      path: `${jsonData.path}/${jsonData.filename}`,
      options: { signal, responseType: "arraybuffer" },
    }).then((result) => {
      const { data } = result;

      if (data?.data) {
        let blobRaw = new Blob([data.data], {
          type: data.headers["content-type"],
        });

        let reader = new FileReader();
        reader.readAsArrayBuffer(blobRaw);
        reader.onloadend = function () {
          let wordArray = CryptoJS.lib.WordArray.create(reader.result);
          // let hash = CryptoJS.SHA256(wordArray).toString();
          // or CryptoJS.MD5(wordArray).toString(); for SHA-2
          reslove({
            data: `data:${data.headers["content-type"]};base64,${Buffer.from(
              data.data,
              "binary"
            ).toString("base64")}`,
            blob: blobRaw,
            blobUrl: URL.createObjectURL(blobRaw),
            message: "已取得圖片",
          });
        };
      } else {
        reslove({
          data: null,
          blob: null,
          blobUrl: null,
          message: "未取得圖片",
        });
      }
    });
  });
}

export async function displayProtectedImage({ signal, path, imageId }) {
  CommonAPI.getImgTeach({
    path,
    options: { signal, responseType: "arraybuffer" },
  }).then((result) => {
    const { data } = result;

    if (data?.data) {
      let blob = new Blob([data.data], {
        type: data.headers["content-type"],
      });

      // Create an object URL from the data.
      const objectUrl = URL.createObjectURL(blob);

      // Update the source of the image.
      const imageElement = document.getElementById(imageId);
      imageElement.src = objectUrl;
    }
  });
}

export async function getWatermark({ data }) {
  return new Promise((reslove) => {
    let reader = new FileReader();
    reader.onload = (event) => {
      reslove({
        path: event.target.result ?? null,
      });
    };
    reader.readAsDataURL(data.file);
  });
}
