import axios from "axios";

const AxiosMulti = (apiList = []) => {
  return axios.all(apiList);
};

function formatApiResult({ data = [], action = () => {} } = {}) {
  let result = data.map((item) => ({
    Response: item.Response,
    message: item.message,
  }));

  result = result.filter(
    (item) => item.Response !== 1 && item.Response !== undefined
  );
  result.forEach((item) => (item.message ? action(item.message) : null));

  return result.length > 0;
}

export { axios, AxiosMulti, formatApiResult };
