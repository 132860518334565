import React, { useEffect, useState, useMemo } from "react";
import { useFilterList } from "../hooks";

import { FormDropdown, FormCheckbox, FormInputnumber } from "../formElement";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";

export default function ModuleTemplateForm({
  moduleType,
  setting,
  errors,
  getValues,
  disabled,
  calculateTotalWeights,
  calculateCategoryScore,
  optionData,
}) {
  const { getFilterListData } = useFilterList();
  const [category, setCategory] = useState({
    term: "",
    data: null,
  });
  const [manager, setManager] = useState({
    term: "",
    data: null,
  });
  const [employee, setEmployee] = useState({
    term: "",
    data: null,
  });

  const categoryData = useMemo(
    () =>
      getFilterListData({
        data: category.data,
        filterKey: "name",
        serachTerm: category.term,
      }) || [],
    [category]
  );
  const managerData = useMemo(
    () =>
      getFilterListData({
        data: manager.data,
        filterKey: "label",
        serachTerm: manager.term,
      }) || [],
    [manager]
  );

  useEffect(() => {
    setCategory((state) => ({
      ...state,
      data: optionData.category || [],
    }));
    setManager((state) => ({
      ...state,
      data: optionData.rater || [],
      data_obj: optionData.rater_obj || [],
    }));
    setEmployee((state) => ({
      ...state,
      data: optionData.rateder || [],
    }));
  }, [optionData]);

  return (
    <>
      <div className="flex flex-column align-items-start sm:flex-row field col-12">
        <label
          htmlFor="category"
          className={classNames(
            { "p-error": errors.category },
            "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
          )}
        >
          考核項目
        </label>
        <div className="col-12 sm:col-10 md:col-8">
          <div className="flex align-items-center mb-1">
            <InputText
              value={category.term}
              onChange={(e) =>
                setCategory((state) => ({ ...state, term: e.target.value }))
              }
            />
            <Button
              type="button"
              icon="pi pi-refresh"
              className="p-button-secondary p-button-sm ml-2"
              onClick={() => setCategory((state) => ({ ...state, term: "" }))}
            />

            <span className="text-lg font-bold text-blue-400 ml-auto">
              總分：{calculateCategoryScore()}分
            </span>
          </div>
          <div className="flex flex-wrap align-items-start border-1 border-400 min-h-5rem p-1">
            {((category.data || []).length === 0 ||
              categoryData.length === 0) &&
              category.data !== null && (
                <div className="text-red-400 ml-1 mt-1">無資料</div>
              )}
            {categoryData.map((item, index) => (
              <div
                key={`category_${item.id}`}
                className="inline-flex align-items-center w-9rem mr-3"
              >
                <FormCheckbox
                  className="flex justify-content-start m-0"
                  setting={setting}
                  data={{
                    name: "category",
                    label: item.name,
                    value: item.id,
                    type: "multi",
                    disabled: disabled,
                  }}
                />
                <i
                  className={`pi pi-question-circle text-blue-400 hover:text-blue-200 cursor-pointer -ml-2 tooltip-category-${index}`}
                ></i>

                <Tooltip
                  className="bg-dark"
                  target={`.tooltip-category-${index}`}
                  autoHide={false}
                  hideDelay={100}
                  position="bottom"
                >
                  {item.content.length > 0 ? (
                    <ul className="line-height-4 pl-4 my-1">
                      {item.content.map((detail, dIdx) => (
                        <li key={`category_${item.id}_${dIdx}`}>
                          {detail.content} ({detail.score}分)
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <>無資料</>
                  )}
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      </div>

      {moduleType !== "template" && (
        <div className="flex flex-column align-items-start sm:flex-row field col-12">
          <label
            htmlFor="manager"
            className={classNames(
              { "p-error": errors.manager },
              "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
            )}
          >
            評核者
          </label>
          <div className="col-12 sm:col-10 md:col-8">
            <div className="flex align-items-center mb-1">
              <InputText
                value={manager.term}
                onChange={(e) =>
                  setManager((state) => ({ ...state, term: e.target.value }))
                }
              />
              <Button
                type="button"
                icon="pi pi-refresh"
                className="p-button-secondary p-button-sm ml-2"
                onClick={() => setManager((state) => ({ ...state, term: "" }))}
              />
            </div>
            <div className="flex flex-wrap align-items-start border-1 border-400 min-h-5rem p-1">
              <div className="text-lg font-bold w-full ml-1 mb-1">名單</div>
              {((manager.data || []).length === 0 ||
                managerData.length === 0) &&
                manager.data !== null && (
                  <div className="text-red-400 ml-1 mt-1">無資料</div>
                )}
              {managerData
                .filter((item) => !getValues("manager").includes(item.username))
                .map((item, index) => (
                  <div
                    key={`manager_${item.username}`}
                    className="inline-flex align-items-center w-9rem mr-3"
                  >
                    <FormCheckbox
                      className="flex justify-content-start m-0"
                      setting={setting}
                      data={{
                        name: "manager",
                        label: item.label,
                        value: item.username,
                        disabled: disabled,
                      }}
                    />
                  </div>
                ))}

              <Divider className="my-1" />

              <div className="text-lg font-bold w-full ml-1 mt-2 mb-1">
                <span>權重</span>
                {(getValues("manager") || []).length !== 0 && (
                  <span
                    className={classNames("ml-2", {
                      "text-blue-400": calculateTotalWeights() === 100,
                      "text-red-400": calculateTotalWeights() !== 100,
                    })}
                  >
                    {`(${calculateTotalWeights()})%`}
                  </span>
                )}
              </div>
              <div className="ml-1">
                {(getValues("manager") || []).length === 0 && (
                  <div className="text-red-400 mt-1">尚未選擇評核者</div>
                )}
                {(getValues("manager") || []).map((item, index) => (
                  <div
                    key={`manager_weights_${item}`}
                    className="flex align-items-center my-1"
                  >
                    <FormCheckbox
                      className="flex justify-content-start w-6rem m-0"
                      setting={setting}
                      data={{
                        name: "manager",
                        label: manager.data_obj?.[item]?.label,
                        value: manager.data_obj?.[item]?.username,
                        disabled: disabled,
                      }}
                    />
                    <FormInputnumber
                      className="w-8rem"
                      setting={setting}
                      data={{
                        name: `manager_weights.${manager.data_obj?.[item]?.username}`,
                        disabled: disabled,
                      }}
                    />
                    <span className="ml-2">%</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {moduleType !== "template" && (
        <div className="flex flex-column align-items-start sm:flex-row field col-12">
          <label
            htmlFor="employee"
            className={classNames(
              { "p-error": errors.employee },
              "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
            )}
          >
            受評者
          </label>
          <div className="col-12 sm:col-10 md:col-8">
            <div className="flex align-items-center mb-1">
              <InputText
                value={employee.term}
                onChange={(e) =>
                  setEmployee((state) => ({ ...state, term: e.target.value }))
                }
              />
              <Button
                type="button"
                icon="pi pi-refresh"
                className="p-button-secondary p-button-sm ml-2"
                onClick={() => setEmployee((state) => ({ ...state, term: "" }))}
              />
            </div>
            <div className="flex flex-wrap align-items-start border-1 border-400 min-h-5rem p-1">
              {(employee.data || []).map((department, dIdx) => (
                <React.Fragment key={`employee_department_${department.id}`}>
                  {dIdx !== 0 && <Divider className="my-2" />}

                  <div className="flex flex-wrap align-items-start">
                    <div className="text-lg font-bold w-full ml-1 mb-1">
                      {department.name}
                    </div>
                    {((department.data || []).length === 0 ||
                      (
                        getFilterListData({
                          data: department.data,
                          filterKey: "label",
                          serachTerm: employee.term,
                        }) || []
                      ).length === 0) &&
                      department.data !== null && (
                        <div className="text-red-400 ml-1 mt-1">無資料</div>
                      )}
                    {(
                      getFilterListData({
                        data: department.data,
                        filterKey: "label",
                        serachTerm: employee.term,
                      }) || []
                    ).map((item) => (
                      <div
                        key={`employee_department_${department.id}_${item.username}`}
                        className="inline-flex align-items-center w-9rem mr-3"
                      >
                        <FormCheckbox
                          className="flex justify-content-start m-0"
                          setting={setting}
                          data={{
                            name: "employee",
                            label: item.label,
                            value: item.username,
                            disabled: disabled,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
