import { useEffect } from "react";
import { useHookForm, useMultiRecords } from "../../../../../components/hooks";
import moment from "moment";

import {
  FormInputtext,
  FormInputnumber,
  FormDropdown,
} from "../../../../../components/formElement";
import { option_salary_type } from "../../../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function ModulePopHumanChangeRecordsSalary({
  prePopOption,
  closePrePop,
  options: { updateSalary, disabled },
}) {
  // 表單欄位預設值
  const defaultValues = {
    salary_info: [],
  };
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
    yup,
    setSchema,
    errors,
    setting,
    setIsFormUpdate,
  } = useHookForm({
    defaultValues,
  });
  const { addDetailBlock, deleteMulti, replayMulti, renderMultiRecordsTb } =
    useMultiRecords({
      blockData: {
        salary_info: {
          type: null,
          salary: null,
          remark: "",
        },
      },
      updateForm: ({ name, data }) => {
        setValue(name, data);
        trigger(name);
        setIsFormUpdate(true);
      },
    });

  useEffect(() => {
    setSchema(
      yup
        .object({
          salary_info: yup.array().of(
            yup.object().shape({
              type: yup.number().required("必填"),
              salary: yup.number().required("必填"),
              remark: yup.string(),
            })
          ),
        })
        .required()
    );
  }, []);

  useEffect(() => {
    reset(
      prePopOption.data
        ? { salary_info: prePopOption.data.data ?? [] }
        : defaultValues
    );
  }, [prePopOption]);

  const onSubmit = (submitData) => {
    updateSalary({ submitData, parentIdx: prePopOption.data.parentIdx });
    closePrePop({ type: "salary" });
  };

  return (
    <>
      <form className="text-center px-3">
        {renderMultiRecordsTb({
          columnLength: 4,
          data: getValues("salary_info") || [],
          columnKey: "salary_info",
          headTemplate: (
            <>
              <tr>
                <th className="w-10rem">類型</th>
                <th className="w-7rem">薪資</th>
                <th>備註</th>
                <th className="w-4rem">動作</th>
              </tr>
            </>
          ),
          bodyTemplate: ({ data, rowIndex }) => (
            <>
              <td>
                <FormDropdown
                  setting={setting}
                  data={{
                    name: `salary_info[${rowIndex}].type`,
                    options: option_salary_type,
                    optionLabel: "label",
                    optionValue: "value",
                    showClear: false,
                    disabled: disabled || data.flag === "delete",
                  }}
                />
              </td>
              <td className="text-center">
                <FormInputnumber
                  setting={setting}
                  data={{
                    name: `salary_info[${rowIndex}].salary`,
                    disabled: disabled || data.flag === "delete",
                  }}
                />
              </td>
              <td>
                <FormInputtext
                  setting={setting}
                  data={{
                    name: `salary_info[${rowIndex}].remark`,
                    disabled: disabled || data.flag === "delete",
                  }}
                />
              </td>
            </>
          ),
        })}

        <div className="w-full mt-3">
          <Button
            className="p-button-secondary px-4"
            type="button"
            label="取消"
            onClick={() => closePrePop({ type: "salary" })}
          />
          <Button
            className="px-4 ml-3"
            type="button"
            label="確認"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </>
  );
}
