import { useState, useEffect, useCallback } from "react";
import { usePop, useHookForm } from "../hooks";

import { ModuleTablePaginator } from "../../service/table";
import { FormCheckbox } from "../formElement";
import { system_table } from "../../service/system";
import { decimalAddZero } from "../../service/common";

import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { Button } from "primereact/button";

const useTable = ({
  tbType = "",
  initData = [],
  initSize = system_table.size,
  isFrozen = false,
} = {}) => {
  const [tbData, setTbData] = useState(initData);
  const [tbTotalRecords, setTbTotalRecords] = useState(0);
  const [tbFirstIndex, setTbFirstIndex] = useState(0);
  const [tbRows, setTbRows] = useState(initSize);
  const [tbColumns, setTbColumns] = useState([]);
  const [tbFootGroup, setTbFootGroup] = useState(null);
  const [tbExpandRows, setTbExpandRows] = useState(null);
  const [pageAction, setPageAction] = useState(null);
  const { isPop, openPop, closePop, renderDialog } = usePop([
    {
      key: "tbSetting",
      title: () => "列表欄位設定",
      isOpen: false,
    },
  ]);
  // 表單欄位預設值
  const defaultValues = {
    isFrozen: true,
  };
  const { handleSubmit, reset, setting } = useHookForm({
    defaultValues,
  });
  const [tbSetting, setTbSetting] = useState({});

  useEffect(() => {
    resetSetting();
  }, [tbType, tbColumns]);

  const resetSetting = () => {
    let setting = localStorage.getItem("tbSetting"),
      temp = null;
    if (setting) {
      setting = JSON.parse(setting);
      if (setting[tbType]) {
        temp = setting[tbType];
        tbColumns?.forEach((item) => {
          if (temp[item.field] === undefined) temp[item.field] = true;
        });
      }
    }

    if (temp === null) {
      let format =
        tbColumns?.reduce((pre, cur) => ({ ...pre, [cur.field]: true }), {}) ??
        {};
      temp = { ...defaultValues, ...format };
    }

    reset(temp);
    setTbSetting(temp);
  };

  const onPage = (e) => {
    const startIndex = e.first,
      rows = e.rows;

    // 同一頁面，不刷新
    if (startIndex === tbFirstIndex && rows === tbRows) return;

    setTbFirstIndex(startIndex);
    setTbRows(rows);

    if (pageAction) pageAction({ startIndex, rows });
  };

  const onSubmit = (submitData) => {
    let setting = localStorage.getItem("tbSetting") ?? "{}";
    setting = JSON.parse(setting);
    setting[tbType] = submitData;
    localStorage.setItem("tbSetting", JSON.stringify(setting));
    setTbSetting(submitData);
    closePop({ type: "tbSetting" });
  };

  const renderTbSetting = useCallback(
    ({ className = "" } = {}) => {
      if (tbColumns.length === 0) return;

      return (
        <>
          <Button
            className={classNames("p-button-secondary w-auto px-2", className)}
            type="button"
            label="欄位設定"
            onClick={() =>
              openPop({
                type: "tbSetting",
                data: null,
              })
            }
          />

          {renderDialog({
            className: "w-11 lg:w-6 md:w-8 sm:w-10",
            children: (
              <>
                {isPop.tbSetting?.isOpen && (
                  <div className="px-3">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="formgrid grid"
                    >
                      <div className="col-12 text-xl text-blue-300 font-bold mb-2 pl-1">
                        顯示欄位
                      </div>
                      {tbColumns
                        ?.filter((columns) => columns.field && columns.header)
                        ?.map((columns) => (
                          <div
                            key={`columns_${columns.field}_${columns.header}`}
                            className="flex align-items-center field col-12 sm:col-6 md:col-3 lg:col-4 mb-1 px-0"
                          >
                            <FormCheckbox
                              className="flex justify-content-start m-0"
                              setting={setting}
                              data={{
                                name: columns.field,
                                label: columns.header,
                                type: "checked",
                                value: true,
                              }}
                            />
                          </div>
                        ))}

                      {isFrozen && (
                        <>
                          <div className="col-12 border-top-1 border-400 mb-3"></div>

                          <div className="col-12 text-xl text-blue-300 font-bold mb-2 pl-1">
                            其他功能
                          </div>
                          <div
                            className={classNames(
                              "flex align-items-center field col-12 sm:col-6 md:col-3 lg:col-4 mb-1 px-0"
                            )}
                          >
                            <FormCheckbox
                              className="flex justify-content-start m-0"
                              setting={setting}
                              data={{
                                name: "isFrozen",
                                label: "是否凍結",
                                type: "checked",
                                value: true,
                              }}
                            />
                          </div>
                        </>
                      )}

                      <div className="col-12 text-right mt-3">
                        <Button
                          type="button"
                          label="取消"
                          className="p-button-secondary p-button-outlined ml-auto px-3"
                          onClick={() => {
                            resetSetting();
                            closePop({ type: "tbSetting" });
                          }}
                        />
                        <Button
                          type="submit"
                          label="儲存"
                          className="ml-2 px-3"
                        />
                      </div>
                    </form>
                  </div>
                )}
              </>
            ),
          })}
        </>
      );
    },
    [tbColumns, isPop]
  );

  const createTbFooter = ({ headColumns, footData, decimal = 3 }) => {
    let temp = (
      <ColumnGroup>
        <Row>
          {headColumns.map((item) => (
            <Column
              key={item.key}
              footer={
                footData[item.key] !== undefined
                  ? typeof footData[item.key] === "number"
                    ? decimalAddZero({
                        rawVal: footData[item.key],
                        decimalCount:
                          String(footData[item.key]).indexOf(".") !== -1
                            ? decimal
                            : 0,
                      })
                    : footData[item.key]
                  : ""
              }
            />
          ))}
        </Row>
      </ColumnGroup>
    );

    setTbFootGroup(temp);
  };

  const renderTable = useCallback(
    ({
      className = "",
      isLazy = true,
      isPaginator = true,
      options = {},
    } = {}) => {
      return (
        <DataTable
          value={tbData}
          className={className}
          size="small"
          paginator={isPaginator}
          paginatorTemplate={ModuleTablePaginator}
          footerColumnGroup={tbFootGroup}
          lazy={isLazy}
          first={tbFirstIndex}
          totalRecords={tbTotalRecords}
          onPage={onPage}
          rows={tbRows}
          emptyMessage={system_table.empty}
          {...options}
        >
          {tbColumns?.map(
            (col) =>
              tbSetting[col.field] && (
                <Column
                  key={`${col.field}_${col.header}`}
                  {...col}
                  frozen={col.frozen ? tbSetting.isFrozen : false}
                />
              )
          )}
        </DataTable>
      );
    },
    [
      tbColumns,
      tbData,
      tbFootGroup,
      tbFirstIndex,
      tbTotalRecords,
      tbRows,
      tbSetting,
    ]
  );

  return {
    tbData,
    setTbData,
    tbTotalRecords,
    setTbTotalRecords,
    tbFirstIndex,
    setTbFirstIndex,
    tbRows,
    setTbRows,
    tbColumns,
    setTbColumns,
    setTbFootGroup,
    tbExpandRows,
    setTbExpandRows,
    createTbFooter,
    renderTbSetting,
    renderTable,
    setPageAction,
  };
};

export default useTable;
