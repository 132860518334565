import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { SettingAPI } from "../../../../axios/api";
import {
  useAbortedEffect,
  useSearch,
  useTreeList,
} from "../../../../components/hooks";

import ModulePopJobtitleTreeForm from "./popJobtitleForm";
import {
  formatOptions,
  setPageSetting,
  formatTreeData,
} from "../../../../service/common";
import { system_dropdown } from "../../../../service/system";
import { option_enabled } from "../../../../service/option";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

export default function ModulePopSettingJobtitleList({
  prePopOption,
  closePrePop,
}) {
  const dispatch = useDispatch();
  const [reload, setReload] = useState({ list: false });
  const [apiData, setApiData] = useState([]);
  // 篩選條件
  const defaultFilter = {
      page: -1,
      status: null,
    },
    pagePath = "/setting/jobtitle";
  const {
    filterData,
    setFilterData,
    search,
    setSearch,
    changeFilter,
    renderSearchWrap,
  } = useSearch({
    defaultFilter,
    pagePath: [pagePath],
    reloadAction: () => setReload((state) => ({ ...state, list: true })),
  });
  const { treeData, setTreeData, setTreeColumns, renderList, addRoot } =
    useTreeList({
      keyObj: {
        node: {
          key: "",
          label: "職稱",
        },
      },
      action: {
        delete: (data) =>
          SettingAPI.updateJobTitleTree({
            data: {
              data: { insert: [], update: [], delete: data.ids },
            },
          }),
        reload: () => setReload((state) => ({ ...state, list: true })),
      },
      ModuleEditForm: ModulePopJobtitleTreeForm,
      formOptions: {
        departmentid: prePopOption.data?.data.id,
      },
    });
  const [optionData, setOptionData] = useState(null);

  useEffect(() => {
    let temp = formatOptions({
      enabled: option_enabled,
    });

    setOptionData((state) => ({
      ...state,
      ...temp,
    }));
  }, []);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = {
        ...search,
        departmentid: Number(prePopOption.data?.data.id),
      };

      SettingAPI.getJobTitleTreeList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, total, message } = result;

        if (Response === 1) {
          let formatData = formatTreeData({ module: "table", childs: data });
          if (formatData[0]) formatData[0].isRoot = true;
          setTreeData((state) => ({ ...state, data: formatData }));
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload]
  );

  useEffect(() => {
    setTreeColumns([
      {
        field: "name",
        header: "職稱",
        className: "p-2",
        expander: true,
      },
    ]);
  }, []);

  return (
    <>
      <div className="border-round-lg bg-white p-3">
        {treeData.data.length === 0 &&
          renderSearchWrap({
            isShow: false,
            template: (
              <>
                {/* <div className="col-12 md:col-4 xl:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                <label
                  htmlFor="username"
                  className="w-4rem white-space-nowrap text-left mb-1"
                >
                  啟用狀態
                </label>
                <Dropdown
                  name="status"
                  className="w-full"
                  value={filterData.status}
                  onChange={(e) => changeFilter(e)}
                  options={optionData?.enabled ?? []}
                  optionLabel="label"
                  optionValue="value"
                  emptyFilterMessage={system_dropdown.emptyFilter}
                  showClear
                />
              </div> */}
                <div
                  className={classNames(
                    "col-12 flex align-items-end mt-2 md:mt-0"
                  )}
                >
                  {/* <Button
                  className="p-button-info p-button-sm w-auto ml-auto md:ml-0 md:mt-0 px-3"
                  type="button"
                  icon="pi pi-search"
                  label="查詢"
                  onClick={() =>
                    setSearch({
                      ...filterData,
                    })
                  }
                /> */}

                  <Button
                    className="p-button-warning p-button-sm w-auto md:ml-auto ml-3 px-3"
                    type="button"
                    label="新增根節點"
                    onClick={() => addRoot()}
                  />
                </div>
              </>
            ),
          })}

        <div className="mt-2">{renderList()}</div>

        <div className="text-center mt-3">
          <Button
            className="p-button-secondary px-4"
            type="button"
            label="關閉"
            onClick={() => closePrePop({ type: "jobtitle" })}
          />
        </div>
      </div>
    </>
  );
}
