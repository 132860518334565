import { Password } from "primereact/password";
import { classNames } from "primereact/utils";

export default function FormInputpassword(props) {
  const {
    setting: { Controller, control, isEdit = true },
    data: { name, placeholder = "", disabled = false },
  } = props;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <div className="flex align-items-center">
            <Password
              id={field.name}
              className={classNames(
                { "p-invalid": fieldState.invalid },
                "w-full"
              )}
              {...field}
              toggleMask
              feedback={false}
              placeholder={placeholder}
              onChange={(e) => field.onChange(e.target.value)}
              disabled={!isEdit || disabled}
            />
          </div>
        )}
      />
    </>
  );
}
