import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";

export default function FormInputtextarea(props) {
  const {
    className,
    setting: { Controller, control, isEdit = true },
    data: {
      name,
      maxlength,
      placeholder = "",
      rows = 5,
      cols = 30,
      disabled = false,
    },
  } = props;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <div className={classNames("flex align-items-center", className)}>
            <InputTextarea
              id={field.name}
              className={classNames(
                { "p-invalid": fieldState.invalid },
                "w-full"
              )}
              {...field}
              maxLength={maxlength}
              placeholder={placeholder}
              rows={rows}
              cols={cols}
              // autoResize
              disabled={!isEdit || disabled}
            />
          </div>
        )}
      />
    </>
  );
}
