import request from "../request";
import Url from "../url/record";

const getAwardRecordsList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getAwardRecordsList + path, data, options);
};
const getAwardRecordsDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getAwardRecordsDetails + path, data, options);
};
const setAwardRecords = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setAwardRecords + path, data, options);
};
const updateAwardRecords = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateAwardRecords + path, data, options);
};
const deleteAwardRecords = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.deleteAwardRecords + path, data, options);
};
const awardRecordsFileUpload = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.awardRecordsFileUpload + path, data, options, {
    requestSecret: false,
  });
};
const awardRecordsDeleteFile = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.awardRecordsDeleteFile + path, data, options);
};

const getPunishRecordsList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getPunishRecordsList + path, data, options);
};
const getPunishRecordsDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getPunishRecordsDetails + path, data, options);
};
const setPunishRecords = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setPunishRecords + path, data, options);
};
const updatePunishRecords = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updatePunishRecords + path, data, options);
};
const deletePunishRecords = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.deletePunishRecords + path, data, options);
};
const punishRecordsFileUpload = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.punishRecordsFileUpload + path, data, options, {
    requestSecret: false,
  });
};
const punishRecordsDeleteFile = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.punishRecordsDeleteFile + path, data, options);
};

export default {
  getAwardRecordsList,
  getAwardRecordsDetails,
  setAwardRecords,
  updateAwardRecords,
  deleteAwardRecords,
  awardRecordsFileUpload,
  awardRecordsDeleteFile,

  getPunishRecordsList,
  getPunishRecordsDetails,
  setPunishRecords,
  updatePunishRecords,
  deletePunishRecords,
  punishRecordsFileUpload,
  punishRecordsDeleteFile,
};
