import { useEffect } from "react";
import { useTable } from "../../components/hooks";

import { Button } from "primereact/button";

export default function ModulePopList({ prePopOption, closePrePop }) {
  const {
    setTbData,
    setTbFirstIndex,
    setTbRows,
    setTbColumns,
    tbExpandRows,
    setTbExpandRows,
    renderTable,
    setPageAction,
  } = useTable();

  useEffect(() => {
    if (!prePopOption.data) return;

    setTbData(prePopOption.data);
  }, [prePopOption.data]);

  useEffect(() => {
    const renderDate = (data) => (
      <>
        {data.leave_work_start_datetime}-{data.leave_work_end_datetime}
      </>
    );

    let columns = {
      attendance: [
        {
          field: "date",
          header: "日期",
          className: "flex-grow-1",
          style: { flexBasis: "100px" },
        },
        {
          field: "weeks",
          header: "星期",
          className: "flex-grow-1",
          style: { flexBasis: "50px" },
        },
        // {
        //   field: "actual_on_duty",
        //   header: "實上班",
        //   className: "flex-grow-1",
        //   style: { flexBasis: "80px" },
        // },
        // {
        //   field: "actual_off_duty",
        //   header: "實下班",
        //   className: "flex-grow-1",
        //   style: { flexBasis: "80px" },
        // },
        {
          field: "actual_work_time",
          header: "實際工時",
          className: "flex-grow-1",
          style: { flexBasis: "80px" },
        },
        {
          field: "total_add_work_time",
          header: "加班總計",
          className: "flex-grow-1",
          style: { flexBasis: "80px" },
        },
      ],
      leaveWork: [
        {
          field: "name",
          header: "請假起訖時間",
          className: "w-11rem",
          body: (data) => renderDate(data),
        },
        {
          field: "total_leave_work_times",
          header: "請假總時數",
          className: "flex-grow-1",
          style: { flexBasis: "70px" },
        },
        {
          field: "type_name",
          header: "假別",
          className: "flex-grow-1",
          style: { flexBasis: "70px" },
        },
      ],
    };
    setTbColumns(columns[prePopOption.type]);
  }, [prePopOption]);

  return (
    <>
      <div className="text-center px-3">
        {prePopOption.data.length > 0 && (
          <div className="text-left font-bold text-blue-700 text-lg">
            {`${prePopOption.data[0].department_name} / ${prePopOption.data[0].username} / ${prePopOption.data[0].name}`}
          </div>
        )}

        {renderTable({
          isLazy: false,
          className: "mt-2",
          options: {
            scrollable: true,
            scrollDirection: "both",
          },
        })}

        <div className="w-full mt-3">
          <Button
            className="p-button-secondary px-4"
            type="button"
            label="關閉"
            onClick={() => closePrePop({ type: prePopOption.type })}
          />
        </div>
      </div>
    </>
  );
}
