export default {
  // 出缺勤
  getAttendanceRecordList: "BackEnd/getAttendanceRecordList",
  getAttendanceRecordDetails: "BackEnd/getAttendanceRecordDetails",
  importsAttendanceRecordData: "BackEnd/importsAttendanceRecordData",
  deleteAttendanceRecord: "BackEnd/deleteAttendanceRecord",

  // 請假
  getLeaveWorksRecordList: "BackEnd/getLeaveWorksRecordList",
  getLeaveWorksRecordDetails: "BackEnd/getLeaveWorksRecordDetails",
  importsLeaveWorksRecordData: "BackEnd/importsLeaveWorksRecordData",
  deleteLeaveWorksRecord: "BackEnd/deleteLeaveWorksRecord",
  updateLeaveWorksRecordData: "BackEnd/updateLeaveWorksRecordData",
};
