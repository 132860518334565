import { useEffect, useRef } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setToast } from "./redux/slice/system";
import { useLocalStorageToRedux } from "./components/hooks";

import { system_toast } from "./service/system";

import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

export default function App() {
  const system = useSelector((state) => state.system);
  const dispatch = useDispatch();
  const localStorageToRedux = useLocalStorageToRedux();
  const toast = useRef(null);

  // 控制 toast 顯示內容
  useEffect(() => {
    if (!system.toast) {
      return;
    }

    toast.current.show({
      ...system.toast,
    });

    // toast 顯示結束後，將全域 toast 資料清除
    setTimeout(() => {
      dispatch(setToast(null));
    }, system.toast.life ?? system_toast.life);
  }, [system.toast]);

  // 偵測是否有 localStorage，有則寫入 store
  useEffect(() => {
    localStorageToRedux();
  }, []);

  return (
    <>
      {system.isProgress && (
        <div
          className="fixed flex align-items-center justify-content-center w-full h-full bg-black-alpha-20"
          style={{ zIndex: 1199 }}
        >
          <ProgressSpinner />
        </div>
      )}

      <Toast ref={toast} />
    </>
  );
}
