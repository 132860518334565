import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import moment from "moment";

import ModuleChartStackbar from "../../../../components/chart/stackbar";
import { option_salary_type } from "../../../../service/option";

import { Button } from "primereact/button";

export default function ModulePopSalaryChart({ prePopOption, closePrePop }) {
  const dispatch = useDispatch();
  // const data = [
  //   {
  //     name: "Page A",
  //     uv: 4000,
  //     pv: 2400,
  //     amt: 2400,
  //   },
  //   {
  //     name: "Page B",
  //     uv: 3000,
  //     pv: 1398,
  //     amt: 2210,
  //   },
  // ];
  const [chartData, setChartData] = useState({
    data: null,
    barKey: [],
  });

  useEffect(() => {
    let formatData = prePopOption.data.map((main) => {
      let salary_info = main.salary_info.reduce(
        (pre, cur) => ({ ...pre, [cur.type]: cur }),
        {}
      );
      return {
        name: moment(main.createdatetime).format("YYYY/MM"),
        ...option_salary_type.reduce(
          (pre, cur) => ({
            ...pre,
            [cur.label]: salary_info[cur.value]?.salary || 0,
          }),
          {}
        ),
      };
    });
    setChartData({
      data: formatData,
      barKey: option_salary_type.map((item) => item.label),
    });
  }, [prePopOption.data]);

  return (
    <div className="text-center px-3 pb-3">
      <div className="h-30rem">
        {chartData.data && (
          <ModuleChartStackbar
            cData={chartData.data}
            cOptions={{
              barKey: chartData.barKey,
            }}
          />
        )}
      </div>

      <div className="w-full mt-4">
        <Button
          className="p-button-secondary px-4"
          type="button"
          label="關閉"
          onClick={() => closePrePop({ type: "salary_chart" })}
        />
      </div>
    </div>
  );
}
