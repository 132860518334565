export const system_api = {
  delay: 500,
  timeout: 1000 * 60 * 30,
};

export const system_toast = {
  life: 5000,
};

export const system_dropdown = {
  empty: "無資料",
  emptyFilter: "查無資料",
};

export const system_table = {
  empty: "無資料",
  size: 10,
};

export const system_email = {
  valid: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
};

export const system_file_type = {
  img: ["png", "jpg", "jpeg", "bmp", "gif"],
};
