import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { AssessmentAPI } from "../../../../axios/api";
import {
  useAbortedEffect,
  useSearch,
  useTable,
} from "../../../../components/hooks";

import ModuleBreadCrumb from "../../../../components/breadCrumb";
import {
  formatOptions,
  setPageSetting,
  formatNumber,
} from "../../../../service/common";
import { system_dropdown, system_table } from "../../../../service/system";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

export default function BackAssessmentTemplateList() {
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbItem = [{ key: 1, label: "考核模板管理" }];
  const [reload, setReload] = useState({ list: false });
  const {
    setTbData,
    tbTotalRecords,
    setTbTotalRecords,
    setTbFirstIndex,
    setTbRows,
    setTbColumns,
    renderTable,
    setPageAction,
  } = useTable();
  // 篩選條件
  const defaultFilter = {
      page: 1,
      size: system_table.size,
      enabled: null,
    },
    pagePath = "/assessment/template";
  const {
    filterData,
    setFilterData,
    search,
    setSearch,
    changeFilter,
    renderSearchWrap,
  } = useSearch({
    defaultFilter,
    pagePath: [pagePath],
    tbSetting: { setTbFirstIndex, setTbRows },
    reloadAction: () => setReload((state) => ({ ...state, list: true })),
  });

  useEffect(() => {
    const action = ({ startIndex, rows }) => {
      let temp = {
        ...search,
        page: Math.floor(startIndex / rows) + 1,
        size: rows,
      };
      setFilterData(temp);
      setSearch(temp);
    };
    setPageAction(
      () =>
        ({ startIndex, rows }) =>
          action({ startIndex, rows })
    );
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = {
        ...search,
      };

      AssessmentAPI.getTmpExamExamplesList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, total, message } = result;

        if (Response === 1) {
          setTbData(data);
          setTbTotalRecords(total);
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload]
  );

  useEffect(() => {
    const renderBtnAction = (data) => (
      <>
        <Button
          className="p-button-sm"
          type="button"
          label="編輯"
          onClick={() => route_navigate(`/back${pagePath}/${data.id}`)}
        />
      </>
    );

    setTbColumns([
      {
        field: "name",
        header: "模板名稱",
        className: "flex-grow-1",
        style: { flexBasis: "70px" },
      },
      {
        field: "remark",
        header: "備註",
        className: "flex-grow-1 white-space-prewrap",
        style: { flexBasis: "150px" },
      },
      {
        field: "id",
        header: "進階",
        className: "w-5rem froze-right",
        alignFrozen: "right",
        frozen: true,
        body: (data) => renderBtnAction(data),
      },
    ]);
  }, [pagePath]);

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <h2 className="my-3 ml-1">考核模板管理</h2>

      <div className="border-round-lg bg-white p-3">
        {renderSearchWrap({
          isShow: false,
          template: (
            <>
              <div
                className={classNames(
                  "col-12 flex align-items-end mt-2 md:mt-0"
                )}
              >
                <Button
                  className="p-button-warning p-button-sm w-auto md:ml-auto ml-3 px-3"
                  type="button"
                  label="新增"
                  onClick={() => route_navigate(`/back${pagePath}/add`)}
                />
              </div>
            </>
          ),
        })}

        {renderTable({
          className: "mt-2",
          options: { scrollable: true, scrollDirection: "both" },
        })}
      </div>
    </>
  );
}
