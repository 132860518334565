import { useCallback } from "react";

import moment from "moment";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

/*
  @params blockData: 新增區塊的預設資料
  @params updateForm: 新增/刪除/復原 後需執行的函數
*/
function useMultiRecords({ blockData = {}, updateForm = () => null } = {}) {
  // 新增區塊
  const addDetailBlock = ({ name, data }) => {
    let temp = [...data];
    temp.push({
      id: `insert_${moment().format("YYYYMMDDHHmmssss") + Math.random()}`,
      flag: "insert",
      ...blockData[name],
    });
    updateForm({ name, data: temp });
  };

  // 刪除資料
  const deleteMulti = ({ name, data, index }) => {
    let temp = [...data];
    temp[index].flag = "delete";
    updateForm({ name, data: temp });
  };

  // 復原資料
  const replayMulti = ({ name, data, index }) => {
    let temp = [...data];
    delete temp[index].flag;
    updateForm({ name, data: temp });
  };

  // 處理資料 新增/修改/刪除
  const formatMultiRecords = ({ data, isAdd }) => {
    if (isAdd) {
      return data
        .filter((item) => item.flag !== "delete")
        .map(({ id, flag, ...rest }) => ({ ...rest }));
    } else {
      return {
        insert: data
          .filter(
            (item) =>
              String(item.id).includes("insert_") && item.flag === "insert"
          )
          .map(({ id, flag, ...rest }) => ({ ...rest })),
        update: data
          .filter(
            (item) =>
              !String(item.id).includes("insert_") && item.flag !== "delete"
          )
          .map(({ flag, ...rest }) => ({ ...rest })),
        delete: data
          .filter(
            (item) =>
              !String(item.id).includes("insert_") && item.flag === "delete"
          )
          .map((item) => item.id),
      };
    }
  };

  const renderMultiRecordsTb = useCallback(
    ({
      className = "",
      isLimitOne = false,
      columnLength = 20,
      data = [],
      columnKey = "",
      headTemplate = "",
      bodyTemplate = () => null,
    } = {}) => {
      if (isLimitOne && data.length === 0)
        addDetailBlock({
          name: columnKey,
          data,
        });

      return (
        <>
          <table className="w-full custom-tb table-layout-fixed">
            <thead>{headTemplate}</thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={`detail_${item.id}`}
                  className={classNames({
                    "bg-tb-fifthtitle": index % 2 === 1,
                  })}
                >
                  {bodyTemplate({ data: item, rowIndex: index })}
                  {!isLimitOne && (
                    <td className="text-center bg-tb-fourthtitle">
                      {item.flag === "delete" ? (
                        <Button
                          type="button"
                          label="復原"
                          className="p-button-secondary p-button-sm"
                          onClick={() =>
                            replayMulti({
                              name: columnKey,
                              data,
                              index,
                            })
                          }
                        />
                      ) : (
                        <Button
                          type="button"
                          label="刪除"
                          className="p-button-danger p-button-sm"
                          onClick={() =>
                            deleteMulti({
                              name: columnKey,
                              data,
                              index,
                            })
                          }
                        />
                      )}
                    </td>
                  )}
                </tr>
              ))}
              {!isLimitOne && (
                <tr>
                  <td className="text-center" colSpan={columnLength}>
                    <Button
                      type="button"
                      label="新增"
                      className="p-button-success w-full px-2 py-1"
                      onClick={() =>
                        addDetailBlock({
                          name: columnKey,
                          data,
                        })
                      }
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      );
    },
    []
  );

  return {
    addDetailBlock,
    deleteMulti,
    replayMulti,
    formatMultiRecords,
    renderMultiRecordsTb,
  };
}

export default useMultiRecords;
