import { useDispatch } from "react-redux";
import { setUser } from "../../redux/slice/user";

const useLocalStorageToRedux = () => {
  const dispatch = useDispatch();

  return () => {
    let localData = [
      {
        type: "json",
        name: "user",
        action: (e) => setUser(e),
      },
    ];

    localData.forEach((item) => {
      let local = localStorage.getItem(item.name);
      if (local) {
        local = item.type === "json" ? JSON.parse(local) : local;
        dispatch(item.action(local));
      }
    });
  };
};

export default useLocalStorageToRedux;
