import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { SettingAPI } from "../../../../axios/api";
import {
  useAbortedEffect,
  useSearch,
  useChipForm,
} from "../../../../components/hooks";

import ModuleBreadCrumb from "../../../../components/breadCrumb";
import { formatOptions, setPageSetting } from "../../../../service/common";
import { system_dropdown } from "../../../../service/system";
import { option_enabled } from "../../../../service/option";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

export default function BackSettingLeaveTypeList() {
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbItem = [{ key: 1, label: "請假類型管理" }];
  const [reload, setReload] = useState({ list: false });
  const [apiData, setApiData] = useState([]);
  // 篩選條件
  const defaultFilter = {
      page: -1,
      status: null,
    },
    pagePath = "/setting/leaveType";
  const {
    filterData,
    setFilterData,
    search,
    setSearch,
    changeFilter,
    renderSearchWrap,
  } = useSearch({
    defaultFilter,
    pagePath: [pagePath],
    reloadAction: () => setReload((state) => ({ ...state, list: true })),
  });
  const { setChipData, addChip, renderChipBlock } = useChipForm({
    keyObj: {
      id: {
        key: "id",
        label: "",
        isForm: false,
      },
      label: {
        key: "name",
        label: "名稱",
        isForm: true,
        ui: "text",
        isRequired: true,
      },
      socre: {
        key: "socre",
        label: "分數",
        isForm: true,
        ui: "number",
        isRequired: true,
      },
      remark: {
        key: "remark",
        label: "備註",
        isForm: true,
        ui: "textarea",
      },
    },
    chipLabel: (data) => `${data.name} (-${data.socre || 0}分)`,
    action: {
      insert: (data) =>
        SettingAPI.setAttendanceLeaveScoreType({
          data: { ...data, type: 2 },
        }),
      update: (data) =>
        SettingAPI.updateAttendanceLeaveScoreType({
          data,
        }),
      reload: () => setReload((state) => ({ ...state, list: true })),
    },
  });
  const [optionData, setOptionData] = useState(null);

  useEffect(() => {
    let temp = formatOptions({
      enabled: option_enabled,
    });

    setOptionData((state) => ({
      ...state,
      ...temp,
    }));
  }, []);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = {
        ...search,
        type: 2,
      };

      SettingAPI.getAttendanceLeaveScoreType({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, total, message } = result;

        if (Response === 1) {
          setChipData((state) => ({
            ...state,
            data,
          }));
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload]
  );

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <h2 className="my-3 ml-1">請假類型管理</h2>

      <div className="border-round-lg bg-white p-3">
        {renderSearchWrap({
          isShow: false,
          template: (
            <>
              <div className="col-12 md:col-4 xl:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                <label
                  htmlFor="username"
                  className="w-4rem white-space-nowrap text-left mb-1"
                >
                  啟用狀態
                </label>
                <Dropdown
                  name="status"
                  className="w-full"
                  value={filterData.status}
                  onChange={(e) => changeFilter(e)}
                  options={optionData?.enabled ?? []}
                  optionLabel="label"
                  optionValue="value"
                  emptyFilterMessage={system_dropdown.emptyFilter}
                  showClear
                />
              </div>
              <div
                className={classNames(
                  "col-12 md:col-8 xl:col-9 flex align-items-end mt-2 md:mt-0"
                )}
              >
                <Button
                  className="p-button-info p-button-sm w-auto ml-auto md:ml-0 md:mt-0 px-3"
                  type="button"
                  icon="pi pi-search"
                  label="查詢"
                  onClick={() =>
                    setSearch({
                      ...filterData,
                    })
                  }
                />

                <Button
                  className="p-button-warning p-button-sm w-auto md:ml-auto ml-3 px-3"
                  type="button"
                  label="新增"
                  onClick={() => addChip()}
                />
              </div>
            </>
          ),
        })}

        <div className="mt-2">{renderChipBlock()}</div>
      </div>
    </>
  );
}
