import request from "../request";
import Url from "../url/upload";

const getAttendanceRecordList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getAttendanceRecordList + path, data, options);
};
const getAttendanceRecordDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getAttendanceRecordDetails + path, data, options);
};
const importsAttendanceRecordData = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.post(Url.importsAttendanceRecordData + path, data, options);
};
const deleteAttendanceRecord = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.deleteAttendanceRecord + path, data, options);
};

const getLeaveWorksRecordList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getLeaveWorksRecordList + path, data, options);
};
const getLeaveWorksRecordDetails = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getLeaveWorksRecordDetails + path, data, options);
};
const importsLeaveWorksRecordData = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.post(Url.importsLeaveWorksRecordData + path, data, options);
};
const deleteLeaveWorksRecord = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.deleteLeaveWorksRecord + path, data, options);
};
const updateLeaveWorksRecordData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateLeaveWorksRecordData + path, data, options);
};

export default {
  getAttendanceRecordList,
  getAttendanceRecordDetails,
  importsAttendanceRecordData,
  deleteAttendanceRecord,

  getLeaveWorksRecordList,
  getLeaveWorksRecordDetails,
  importsLeaveWorksRecordData,
  deleteLeaveWorksRecord,
  updateLeaveWorksRecordData,
};
