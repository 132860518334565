import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

export default function FormInputtext(props) {
  const {
    className,
    setting: { Controller, control, trigger, isSubmitted, isEdit = true },
    data: { name, maxlength, placeholder = "", disabled = false },
  } = props;

  const onChange = (e, field) => {
    field.onChange(e);

    if (isSubmitted) {
      trigger();
    } else {
      trigger(field.name);
    }
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <div className={classNames("flex align-items-center", className)}>
            <InputText
              id={field.name}
              className={classNames(
                { "p-invalid": fieldState.invalid },
                "w-full"
              )}
              {...field}
              onChange={(e) => onChange(e, field)}
              maxLength={maxlength}
              placeholder={placeholder}
              disabled={!isEdit || disabled}
            />
          </div>
        )}
      />
    </>
  );
}
