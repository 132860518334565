import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { UploadAPI } from "../../../../axios/api";
import {
  useAbortedEffect,
  useSearch,
  useTable,
  useUploadXlsx,
  usePop,
  useConfirm,
} from "../../../../components/hooks";
import moment from "moment";

import ModuleBreadCrumb from "../../../../components/breadCrumb";
import ModulePopAttendanceDetail from "./PopDetail";
import {
  formatOptions,
  setPageSetting,
  formatNumber,
} from "../../../../service/common";
import { system_dropdown, system_table } from "../../../../service/system";
import { option_enabled } from "../../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

export default function BackUploadAttendanceList() {
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbItem = [{ key: 1, label: "出缺勤管理" }];
  const [reload, setReload] = useState({ list: false });
  const {
    setTbData,
    tbTotalRecords,
    setTbTotalRecords,
    setTbFirstIndex,
    setTbRows,
    setTbColumns,
    renderTable,
    setPageAction,
  } = useTable();
  // 篩選條件
  const defaultFilter = {
      page: 1,
      size: system_table.size,
      keywords: "",
    },
    pagePath = "/upload/attendance";
  const {
    filterData,
    setFilterData,
    search,
    setSearch,
    changeFilter,
    renderSearchWrap,
  } = useSearch({
    defaultFilter,
    pagePath: [pagePath],
    tbSetting: { setTbFirstIndex, setTbRows },
    reloadAction: () => setReload((state) => ({ ...state, list: true })),
  });
  const { renderUpload } = useUploadXlsx({
    action: {
      format: (data) => formatAction(data),
      upload: (data) =>
        UploadAPI.importsAttendanceRecordData({
          data,
        }),
      reload: () => setReload((state) => ({ ...state, list: true })),
    },
  });
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "detail",
      title: () => "明細",
      isOpen: false,
    },
  ]);
  const { confirmOption, setConfirmOption, renderConfirm } = useConfirm();
  const uploadColumns = [
    {
      key: "department_name",
      label: "部門",
    },
    {
      key: "username",
      label: "員工編號",
    },
    {
      key: "name",
      label: "員工姓名",
    },
    {
      key: "date",
      label: "日期",
      type: "date",
    },
    {
      key: "weeks",
      label: "星期",
    },
    {
      key: "should_on_duty",
      label: "應上班1",
      type: "time",
    },
    {
      key: "should_off_duty",
      label: "應下班1",
      type: "time",
    },
    {
      key: "actual_on_duty",
      label: "實上班1",
      type: "time",
    },
    {
      key: "actual_off_duty",
      label: "實下班1",
      type: "time",
    },
    {
      key: "late",
      label: "遲到1",
      type: "time",
    },
    {
      key: "leave_early",
      label: "早退1",
      type: "time",
    },
    {
      key: "absenteeism",
      label: "曠職1",
      type: "time",
    },
    {
      key: "no_card_on_duty",
      label: "上班未刷1",
    },
    {
      key: "no_card_off_duty",
      label: "下班未刷1",
    },
    {
      key: "actual_work_time",
      label: "實際工時",
      type: "time",
    },
    {
      key: "leave_work_data",
      label: "請假資料",
    },
    {
      key: "add_work_data",
      label: "加班資料",
    },
    {
      key: "total_add_work_time",
      label: "加班總計",
    },
    {
      key: "remark",
      label: "備註",
    },
    {
      key: "leave_work_remark",
      label: "請假備註",
    },
    {
      key: "add_work_remark",
      label: "加班備註",
    },
    {
      key: "schedule_name",
      label: "班表名稱",
    },
  ];

  useEffect(() => {
    const action = ({ startIndex, rows }) => {
      let temp = {
        ...search,
        page: Math.floor(startIndex / rows) + 1,
        size: rows,
      };
      setFilterData(temp);
      setSearch(temp);
    };
    setPageAction(
      () =>
        ({ startIndex, rows }) =>
          action({ startIndex, rows })
    );
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = {
        ...search,
      };

      UploadAPI.getAttendanceRecordList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, total, message } = result;

        if (Response === 1) {
          setTbData(data);
          setTbTotalRecords(total);
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload]
  );

  useEffect(() => {
    const renderBtnAction = (data) => (
      <>
        <Button
          className="p-button-sm"
          type="button"
          label="查看"
          onClick={() => openPop({ type: "detail", data })}
        />
        <Button
          id={`button_delete_${data.id}`}
          className="p-button-danger p-button-sm ml-2"
          type="button"
          label="刪除"
          onClick={() =>
            setConfirmOption({
              setting: {
                target: document.getElementById(`button_delete_${data.id}`),
                message: "確認是否刪除?",
                icon: "pi pi-exclamation-triangle",
                visible: !confirmOption.setting.visible,
                acceptLabel: "是",
                rejectLabel: "否",
                accept: () => deleteAction({ data }),
                reject: () => null,
              },
              reOpen: confirmOption.setting.visible,
            })
          }
        />
      </>
    );

    const renderDate = (data) => (
      <>
        {data.leave_work_start_datetime}-{data.leave_work_end_datetime}
      </>
    );

    setTbColumns([
      {
        field: "department_name",
        header: "部門",
        className: "flex-grow-1",
        style: { flexBasis: "70px" },
      },
      {
        field: "username",
        header: "員工編號",
        className: "flex-grow-1",
        style: { flexBasis: "75px" },
      },
      {
        field: "name",
        header: "員工姓名",
        className: "flex-grow-1",
        style: { flexBasis: "75px" },
      },
      {
        field: "date",
        header: "日期",
        className: "flex-grow-1",
        style: { flexBasis: "100px" },
      },
      {
        field: "weeks",
        header: "星期",
        className: "flex-grow-1",
        style: { flexBasis: "50px" },
      },
      // {
      //   field: "actual_on_duty",
      //   header: "實上班",
      //   className: "flex-grow-1",
      //   style: { flexBasis: "80px" },
      // },
      // {
      //   field: "actual_off_duty",
      //   header: "實下班",
      //   className: "flex-grow-1",
      //   style: { flexBasis: "80px" },
      // },
      {
        field: "actual_work_time",
        header: "實際工時",
        className: "flex-grow-1",
        style: { flexBasis: "80px" },
      },
      {
        field: "id",
        header: "進階",
        className: "w-9rem froze-right",
        alignFrozen: "right",
        frozen: true,
        body: (data) => renderBtnAction(data),
      },
    ]);
  }, [pagePath]);

  const deleteAction = ({ data }) => {
    dispatch(setProgress(true));

    let jsonData = { data: [data.id] };

    UploadAPI.deleteAttendanceRecord({ data: jsonData }).then((result) => {
      let { Response } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "成功刪除",
            detail: "",
          })
        );
        setReload((state) => ({
          ...state,
          list: true,
        }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: "刪除失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  const formatAction = (data) => {
    let isValid = true,
      message = "",
      startIndex = 2;

    data.splice(0, startIndex);
    data = data.map((item) => {
      let details = Object.values(item);
      return {
        ...uploadColumns.reduce(
          (pre, cur, cIdx) => ({
            ...pre,
            [cur.key]:
              cur.type === "date"
                ? moment(details[cIdx]).format("YYYY-MM-DD")
                : cur.type === "time"
                ? details[cIdx]
                  ? `${details[cIdx]}:00`
                  : "00:00:00"
                : details[cIdx],
          }),
          {}
        ),
      };
    });

    return {
      isValid,
      message,
      data,
    };
  };

  return (
    <>
      {renderConfirm}

      <ModuleBreadCrumb crumbItem={crumbItem} />

      <h2 className="my-3 ml-1">出缺勤管理</h2>

      <div className="border-round-lg bg-white p-3">
        {renderSearchWrap({
          isShow: false,
          template: (
            <>
              <div className="col-12 md:col-4 xl:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                <label
                  htmlFor="username"
                  className="w-4rem white-space-nowrap text-left mb-1"
                >
                  關鍵字
                </label>
                <InputText
                  className="w-full"
                  name="keywords"
                  value={filterData.keywords}
                  onChange={(e) => changeFilter(e)}
                />
              </div>
              <div
                className={classNames(
                  "col-12 md:col-8 xl:col-9 flex align-items-end mt-2 md:mt-0"
                )}
              >
                <Button
                  className="p-button-info p-button-sm w-auto ml-auto md:ml-0 md:mt-0 px-3"
                  type="button"
                  icon="pi pi-search"
                  label="查詢"
                  onClick={() => {
                    setSearch({
                      ...filterData,
                      page: 1,
                    });
                    setTbFirstIndex(0);
                  }}
                />

                {renderUpload()}
              </div>
            </>
          ),
        })}

        {renderTable({
          className: "mt-2",
          options: { scrollable: true, scrollDirection: "both" },
        })}
      </div>

      {renderDialog({
        className: "w-11 md:w-10 lg:w-8 max-w-40rem",
        children: (
          <>
            {isPop.detail?.isOpen && (
              <ModulePopAttendanceDetail
                popPreOption={popOption}
                closePrePop={closePop}
                uploadColumns={uploadColumns}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
