import { useState, useEffect, useCallback } from "react";

import { getPageSetting } from "../../service/common";
import { system_table } from "../../service/system";

import { classNames } from "primereact/utils";

const useSearch = ({
  keyPrefix = null,
  defaultFilter = {},
  pagePath = [],
  tbSetting = null,
  reloadAction = null,
} = {}) => {
  const [filterData, setFilterData] = useState(defaultFilter);
  const [search, setSearch] = useState(null);
  const [isExpand, setIsExpand] = useState(true);

  const changeFilter = ({ target }) => {
    let { name, value } = target;

    setFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // 取 該頁搜尋參數
  useEffect(() => {
    let result = getPageSetting(pagePath);

    let temp = { ...defaultFilter, ...filterData };
    if (result)
      temp = {
        ...temp,
        ...(keyPrefix ? result.params[keyPrefix] : result.params),
      };

    setFilterData(temp);
    setSearch(temp);
  }, [pagePath?.[0]]);

  // 判斷是否搜尋
  useEffect(() => {
    if (!search) return;

    if (tbSetting) {
      let size = search.size ?? system_table.size;
      tbSetting.setTbFirstIndex(((search.page ?? 1) - 1) * size);
      tbSetting.setTbRows(size);
    }

    if (reloadAction) reloadAction();
  }, [search]);

  const formatAction = ({ type, name } = {}) => {
    if (type === "dateRange") {
      return filterData.dateRange
        ? [
            filterData.dateRange[0] ? new Date(filterData.dateRange[0]) : null,
            filterData.dateRange[1] ? new Date(filterData.dateRange[1]) : null,
          ]
        : null;
    } else {
      return null;
    }
  };

  const renderSearchWrap = useCallback(
    ({ className, isShow = true, template }) => {
      return (
        <>
          <div
            className={classNames(
              "relative formgrid grid p-fluid w-full text-lg bg-fourth border-1 border-solid border-400 m-0 px-2",
              className,
              {
                "max-h-2rem ml-auto": !isExpand,
                "py-2": isExpand,
              }
            )}
          >
            {isShow && (
              <div
                className="absolute top-0 right-0 flex align-items-center justify-content-center text-white surface-400 hover:bg-blue-200 p-1 cursor-pointer"
                onClick={() => setIsExpand(!isExpand)}
              >
                <i
                  className={classNames("pi", {
                    "pi-chevron-up": isExpand,
                    "pi-chevron-down": !isExpand,
                  })}
                ></i>
              </div>
            )}

            {isExpand ? (
              <>{template}</>
            ) : (
              <div className="text-blue-400 py-1">篩選區塊</div>
            )}
          </div>
        </>
      );
    },
    [isExpand]
  );

  return {
    filterData,
    setFilterData,
    search,
    setSearch,
    changeFilter,
    formatAction,
    renderSearchWrap,
  };
};

export default useSearch;
