import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { AssessmentAPI, MemberAPI } from "../../../../axios/api";
import {
  useAbortedEffect,
  useTab,
  useSearch,
  useTable,
  usePop,
} from "../../../../components/hooks";
import moment from "moment";

import ModuleBreadCrumb from "../../../../components/breadCrumb";
import ModulePopMemberAssessment from "../member/popAssessment";
import {
  formatOptions,
  setPageSetting,
  formatNumber,
} from "../../../../service/common";
import { system_dropdown, system_table } from "../../../../service/system";
import {
  option_season,
  option_system_startYear,
} from "../../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function BackAssessmentManagerMainList() {
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbItem = [{ key: 1, label: "總經理加分" }];
  const [reload, setReload] = useState({ list: false });
  const { tabData, activeIndex, renderTab } = useTab({
    moduleType: "underline",
    className: "mb-3",
    data: [
      { key: 1, label: "當季", value: 0, type: "now" },
      { key: 2, label: "歷史紀錄", value: 1, type: "history" },
    ],
    matchTab: ["/assessment/manager"],
    changeAction: (index) => tabChange(index),
  });
  const {
    tbData,
    setTbData,
    setTbFirstIndex,
    setTbRows,
    setTbColumns,
    tbExpandRows,
    setTbExpandRows,
    renderTable,
    setPageAction,
  } = useTable();
  // 篩選條件
  const defaultFilter = {
      page: 1,
      size: system_table.size,
      year: null,
      season: null,
    },
    pagePath = "/assessment/manager";
  const {
    filterData,
    setFilterData,
    search,
    setSearch,
    changeFilter,
    renderSearchWrap,
  } = useSearch({
    defaultFilter,
    pagePath: [pagePath],
    tbSetting: { setTbFirstIndex, setTbRows },
    reloadAction: () => setReload((state) => ({ ...state, list: true })),
  });
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "assessment",
      title: (props) => `考核 - ${props.data.name}`,
      isOpen: false,
    },
  ]);
  const [memberData, setMemberData] = useState([]);
  const [optionData, setOptionData] = useState({});

  useEffect(() => {
    let nowYear = Number(moment().format("YYYY")),
      nowMonth = Number(moment().format("MM")),
      yearArr = [];
    if (nowMonth === 1) nowYear -= 1;
    for (let i = option_system_startYear; i <= nowYear; i++) yearArr.push(i);
    setOptionData({
      year: yearArr,
      season: option_season,
    });
  }, []);

  const tabChange = () => {
    setTbData([]);
    setReload((state) => ({ ...state, list: true }));
  };

  useEffect(() => {
    const action = ({ startIndex, rows }) => {
      let temp = {
        ...search,
        page: Math.floor(startIndex / rows) + 1,
        size: rows,
      };
      setFilterData(temp);
      setSearch(temp);
    };
    setPageAction(
      () =>
        ({ startIndex, rows }) =>
          action({ startIndex, rows })
    );
  }, [search]);

  useAbortedEffect((signal) => {
    let params = {
      page: -1,
    };

    MemberAPI.getMemberDetailsList({
      data: params,
      options: { signal },
    }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        data = data.map((item) => ({
          ...item,
          name_format: `${item.ch_last_name}${item.ch_first_name}(${item.username})`,
        }));
        setMemberData(data);
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }
    });
  }, []);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) return;

      if (activeIndex === 1) {
        let valid = true,
          nowYear = Number(moment().format("YYYY")),
          nowMonth = Number(moment().format("MM")),
          nowSeason = [2, 3, 4].includes(nowMonth)
            ? 1
            : [5, 6, 7].includes(nowMonth)
            ? 2
            : [8, 9, 10].includes(nowMonth)
            ? 3
            : [11, 12, 1].includes(nowMonth)
            ? 4
            : "";
        if (nowMonth === 1) nowYear -= 1;
        if (!search.year || !search.season) {
          valid = false;
          setTbData([]);
          dispatch(
            setToast({
              severity: "warn",
              summary: "請選擇搜尋年份、季度",
              detail: "",
            })
          );
        } else if (search.year === nowYear && search.season >= nowSeason) {
          valid = false;
          dispatch(
            setToast({
              severity: "warn",
              summary: "請選擇過去年份、季度",
              detail: "",
            })
          );
        }
        if (!valid) {
          setReload((state) => ({
            ...state,
            list: false,
          }));
          return;
        }
      }

      dispatch(setProgress(true));

      setPageSetting({
        params: search,
        page: pagePath,
        tab: tabData[activeIndex].type,
      });

      let params = {
          ...search,
        },
        promise;

      if (activeIndex === 0)
        promise = AssessmentAPI.getAllExamList({
          data: params,
          options: { signal },
        });
      else if (activeIndex === 1)
        promise = AssessmentAPI.getAllHistoryList({
          data: params,
          options: { signal },
        });

      promise.then((result) => {
        let { Response, data, message } = result;

        if (Response === 1) {
          setTbData(data);
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload]
  );

  useEffect(() => {
    const renderEnabled = (data) => (
      <div
        className={classNames("white-space-prewrap", {
          "text-red-400": data.status === 0,
          "text-green-400": data.status === 1,
        })}
      >
        {data.finish_format}
      </div>
    );

    setTbColumns([
      {
        header: "展開",
        expander: (rowData) => rowData.memberList.length > 0,
        style: { width: "50px" },
      },
      {
        field: "name",
        header: "考核名稱",
        className: "flex-grow-1",
        style: { flexBasis: "70px" },
      },
      {
        field: "remark",
        header: "備註",
        className: "flex-grow-1 white-space-prewrap",
        style: { flexBasis: "150px" },
      },
    ]);
  }, [pagePath, activeIndex]);

  const rowExpansionTemplate = (groupData) => {
    return (
      <DataTable
        className="w-full"
        value={groupData.memberList}
        emptyMessage={system_table.empty}
      >
        <Column
          field="ch_first_name"
          header="員工姓名"
          className="flex-grow-1 w-6"
          style={{ flexBasis: "150px" }}
          body={(data) => `${data.ch_last_name}${data.ch_first_name}`}
        ></Column>
        <Column
          field="username"
          header="員工編號"
          className="flex-grow-1 w-6"
          style={{ flexBasis: "150px" }}
        ></Column>
        {/* <Column
          field="is_exam_result"
          header="是否評核"
          className="w-5rem"
          body={(data) => (
            <div
              className={classNames({
                "text-green-400": data.is_exam_result === 1,
                "text-red-400": data.is_exam_result === 0,
              })}
            >
              {data.is_exam_result === 1 ? "完成" : "未完成"}
            </div>
          )}
        ></Column> */}
        <Column
          field="result.total_score"
          header="總分"
          className="w-4rem"
        ></Column>
        <Column
          field="result.bonus"
          header="加/扣分"
          className="w-5rem"
        ></Column>
        <Column
          field="result.award_score"
          header="獎勵"
          className="w-5rem"
          body={(data) => (
            <>{data.result.award_score ? `+${data.result.award_score}` : 0}</>
          )}
        ></Column>
        <Column
          field="result.punish_score"
          header="懲罰"
          className="w-5rem"
          body={(data) => (
            <>{data.result.punish_score ? `-${data.result.punish_score}` : 0}</>
          )}
        ></Column>
        <Column
          field="result.attendance_score"
          header="出缺勤/請假"
          className="w-5rem"
          body={(data) => (
            <>
              {data.result.attendance_score
                ? `-${data.result.attendance_score}`
                : 0}
            </>
          )}
        ></Column>
        <Column
          field="id"
          header="進階"
          style={{ width: "4.5rem" }}
          body={(data) => (
            <Button
              className={classNames("p-button-sm", {
                "p-button-warning": data.status === 0,
              })}
              type="button"
              label="查看"
              onClick={() =>
                openPop({
                  type: "assessment",
                  data: {
                    ...groupData,
                    member: data,
                    tab: tabData[activeIndex].type,
                  },
                })
              }
              disabled={!data.result || !data.result.exam_score}
            />
          )}
        ></Column>
      </DataTable>
    );
  };

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <h2 className="my-3 ml-1">總經理加分</h2>

      <div className="border-round-lg bg-white p-3">
        <div className="flex flex-column md:flex-row md:align-items-center">
          {renderTab}

          <div className="text-red-400 md:ml-auto mb-3">
            * 評核者尚未評分無法查看
          </div>
        </div>

        {activeIndex === 1 && (
          <div className="mb-2">
            {renderSearchWrap({
              isShow: false,
              template: (
                <>
                  <div className="col-12 md:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                    <label
                      htmlFor="year"
                      className="w-4rem white-space-nowrap text-left mb-1"
                    >
                      <span className="text-red-400 mr-1">*</span>年份
                    </label>
                    <Dropdown
                      name="year"
                      className="w-full"
                      value={filterData.year}
                      onChange={(e) => changeFilter(e)}
                      options={optionData.year}
                      showClear
                    />
                  </div>
                  <div className="col-12 md:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                    <label
                      htmlFor="season"
                      className="w-4rem white-space-nowrap text-left mb-1"
                    >
                      <span className="text-red-400 mr-1">*</span>季度
                    </label>
                    <Dropdown
                      name="season"
                      className="w-full"
                      value={filterData.season}
                      onChange={(e) => changeFilter(e)}
                      options={optionData.season}
                      showClear
                    />
                  </div>
                  <div className="col-12 md:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                    <label
                      htmlFor="username"
                      className="w-4rem white-space-nowrap text-left mb-1"
                    >
                      被評核者
                    </label>
                    <Dropdown
                      name="rateder"
                      className="w-full"
                      value={filterData.rateder}
                      onChange={(e) => changeFilter(e)}
                      options={memberData ?? []}
                      optionLabel="name_format"
                      optionValue="username"
                      filter={true}
                      filterBy="name_format"
                      emptyMessage={system_dropdown.empty}
                      emptyFilterMessage={system_dropdown.emptyFilter}
                      showClear
                    />
                  </div>
                  <div
                    className={classNames(
                      "col-12 md:col-3 flex align-items-end mt-2 md:mt-0"
                    )}
                  >
                    <Button
                      className="p-button-info p-button-sm w-auto ml-auto md:ml-0 md:mt-0 px-3"
                      type="button"
                      icon="pi pi-search"
                      label="查詢"
                      onClick={() => {
                        setSearch({
                          ...filterData,
                          page: 1,
                        });
                        setTbFirstIndex(0);
                      }}
                    />
                  </div>
                </>
              ),
            })}
          </div>
        )}

        {renderTable({
          isPaginator: false,
          options: {
            // scrollable: true,
            // scrollDirection: "both",
            expandedRows: tbExpandRows,
            onRowToggle: (e) => setTbExpandRows(e.data),
            rowExpansionTemplate: rowExpansionTemplate,
            dataKey: "id",
          },
        })}
      </div>

      {renderDialog({
        className: "w-11 md:w-10 lg:w-8",
        children: (
          <>
            {isPop.assessment?.isOpen && (
              <ModulePopMemberAssessment
                closePrePop={closePop}
                prePopOption={popOption}
                setPreReload={setReload}
                isManager={true}
                searchParams={search}
                action={{
                  getNow: ({ signal, params }) =>
                    AssessmentAPI.getAllExamDetails({
                      data: params,
                      options: { signal },
                    }),
                  getHistory: ({ signal, params }) =>
                    AssessmentAPI.getAllHistoryExamDetails({
                      data: params,
                      options: { signal },
                    }),
                }}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
