import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setBackLayout } from "../../redux/slice/system";
import { useNavigate } from "react-router-dom";
import { useClearLocalStorage } from "../hooks";

import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";

export default function BackTopbar(props) {
  let { LayoutCss } = props;
  const system = useSelector((state) => state.system);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const clearLocalStorage = useClearLocalStorage();
  const route_navigate = useNavigate();
  // 是否顯示 使用者資訊
  const overlay_user = useRef(null);

  // 登出
  const logout = () => {
    clearLocalStorage();
    route_navigate("/login");
  };

  return (
    <>
      <div className={`${LayoutCss["layout-topbar"]}`}>
        <Button
          className="p-button-main p-button-sm w-auto px-2 py-1"
          icon="pi pi-bars"
          onClick={() =>
            dispatch(
              setBackLayout({
                isSideShow: !system.backLayout.isSideShow,
              })
            )
          }
        />
        {/* <span className={`${LayoutCss["layout-title"]}`}>
          Hello! {user.userDetail?.username} (admin)
        </span> */}

        {/* {process.env.REACT_APP_MODE === "development" && (
          <div className="inline-block text-center text-xl text-white bg-red-500 font-bold px-2 py-0 ml-4">
            測試站
          </div>
        )} */}

        <span className="text-main ml-auto">
          使用者：{user.userDetail?.username}
        </span>
        <div className="relative">
          <Button
            className="p-button-white p-button-sm p-button-rounded p-0 ml-3"
            style={{ width: "2rem", height: "2rem" }}
            icon="pi pi-user"
            onClick={(e) => overlay_user.current.toggle(e)}
          />
        </div>
        <Button
          className="p-button-main p-button-sm w-auto px-3 py-1 ml-3"
          label="登出"
          onClick={() => logout()}
        />
      </div>

      <OverlayPanel
        className="w-15rem mt-3 ml-5 p-0 overflow-y-auto"
        ref={overlay_user}
      >
        <div className="text-center">
          <div
            className="text-main text-lg font-bold hover:bg-green-50 cursor-pointer py-2 transition-all transition-duration-150 transition-ease-in-out"
            onClick={(e) => {
              overlay_user.current.toggle(e);
              route_navigate("/back/password");
            }}
          >
            變更密碼
          </div>
        </div>
      </OverlayPanel>
    </>
  );
}
