import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { SettingAPI, MemberAPI } from "../../../../axios/api";
import {
  useAbortedEffect,
  useSearch,
  useTable,
  usePop,
} from "../../../../components/hooks";

import ModuleBreadCrumb from "../../../../components/breadCrumb";
import ModulePopMemberAccount from "./popAccount";
import ModulePopContract from "./popContract";
import {
  formatOptions,
  setPageSetting,
  formatTreeData,
} from "../../../../service/common";
import { system_dropdown, system_table } from "../../../../service/system";
import { getImgData } from "../../../../service/img";
import { option_member_type } from "../../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TreeSelect } from "primereact/treeselect";

export default function BackMemberProfileList() {
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const redux_user = useSelector((state) => state.user);
  const crumbItem = [{ key: 1, label: "員工資料" }];
  const [reload, setReload] = useState({ list: false });
  const {
    setTbData,
    tbTotalRecords,
    setTbTotalRecords,
    setTbFirstIndex,
    setTbRows,
    setTbColumns,
    renderTable,
    setPageAction,
  } = useTable();
  // 篩選條件
  const defaultFilter = {
      page: 1,
      size: system_table.size,
      type: null,
      departmentid: null,
    },
    pagePath = "/member/profile";
  const {
    filterData,
    setFilterData,
    search,
    setSearch,
    changeFilter,
    renderSearchWrap,
  } = useSearch({
    defaultFilter,
    pagePath: [pagePath],
    tbSetting: { setTbFirstIndex, setTbRows },
    reloadAction: () => setReload((state) => ({ ...state, list: true })),
  });
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "account",
      title: () => "",
      isOpen: false,
    },
    {
      key: "contract",
      title: () => "新增勞動契約",
      isOpen: false,
    },
  ]);
  const [optionData, setOptionData] = useState(null);

  useEffect(() => {
    const action = ({ startIndex, rows }) => {
      let temp = {
        ...search,
        page: Math.floor(startIndex / rows) + 1,
        size: rows,
      };
      setFilterData(temp);
      setSearch(temp);
    };
    setPageAction(
      () =>
        ({ startIndex, rows }) =>
          action({ startIndex, rows })
    );
  }, [search]);

  useAbortedEffect((signal) => {
    dispatch(setProgress(true));

    let tempOptions = {};
    SettingAPI.getDepartmentTreeList({
      data: { page: -1 },
      options: { signal },
    }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        tempOptions = {
          ...tempOptions,
          department: formatTreeData({
            module: "select",
            key: "id",
            label: "name",
            childs: data,
          }),
        };
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }

      tempOptions = {
        ...tempOptions,
        ...formatOptions({
          member_type: option_member_type,
        }),
      };
      setOptionData(tempOptions);
    });
  }, []);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list || !optionData) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = {
        ...search,
      };

      MemberAPI.getMemberDetailsList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, total, message } = result;

        if (Response === 1) {
          data = data.map((item) => ({
            ...item,
            name_format: `${item.ch_last_name ?? ""}${
              item.ch_first_name ?? ""
            }`,
            type_format: optionData.member_type_obj[item.type]?.label,
          }));
          setTbData(data);
          setTbTotalRecords(total);
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload, optionData]
  );

  useEffect(() => {
    if (!optionData) return;

    const renderAccount = (data) => (
      <>
        {[3, 4, 9].includes(redux_user.userDetail?.type) ? (
          <>
            {data.account_is_create && data.account_is_enabled ? (
              <Button
                className="p-button-danger p-button-sm"
                type="button"
                label="關閉"
                onClick={() =>
                  openPop({
                    type: "account",
                    data: { ...data, isClosed: true },
                  })
                }
              />
            ) : (
              <Button
                className="p-button-info p-button-sm"
                type="button"
                label="開通"
                onClick={() => openPop({ type: "account", data })}
              />
            )}
          </>
        ) : (
          <span
            className={classNames({
              "text-green-400": data.account_is_create,
              "text-red-400": !data.account_is_create,
            })}
          >{`${data.account_is_create ? "已" : "未"}開通`}</span>
        )}
      </>
    );

    const renderBtnAction = (data) => (
      <>
        <Button
          className={classNames("p-button-sm mr-2", {
            "p-button-secondary": data.labor_contract_file || data.sign_file,
            "p-button-danger": !data.labor_contract_file && !data.sign_file,
          })}
          type="button"
          label={`${
            data.labor_contract_file || data.sign_file ? "查看" : "新增"
          }契約`}
          onClick={() =>
            data.labor_contract_file || data.sign_file
              ? getDetail({ id: data.id })
              : openPop({
                  type: "contract",
                  data,
                })
          }
        />
        <Button
          className="p-button-sm"
          type="button"
          label="編輯"
          onClick={() => route_navigate(`/back${pagePath}/${data.id}`)}
        />
      </>
    );

    setTbColumns([
      {
        field: "username",
        header: "員工編號",
        className: "flex-grow-1",
        style: { flexBasis: "70px" },
      },
      {
        field: "name_format",
        header: "姓名",
        className: "flex-grow-1",
        style: { flexBasis: "60px" },
      },
      {
        field: "type_format",
        header: "人事狀態",
        className: "flex-grow-1",
        style: { flexBasis: "70px" },
      },
      {
        field: "account_is_create",
        header: "帳號開通",
        className: "w-6rem",
        body: (data) => renderAccount(data),
      },
      {
        field: "id",
        header: "進階",
        className: "w-10rem froze-right",
        alignFrozen: "right",
        frozen: true,
        body: (data) => renderBtnAction(data),
      },
    ]);
  }, [pagePath, optionData]);

  const getDetail = ({ id }) => {
    dispatch(setProgress(true));

    MemberAPI.getMemberDetailsData({
      data: { id },
    }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        showContract({ data });
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  const showContract = async ({ data }) => {
    let jsonData = {
        path: data.sign_file_path ?? data.labor_contract_file_path,
        filename: data.sign_fileList[0] ?? data.labor_contract_fileList[0],
      },
      fileResult = await getImgData({ jsonData });

    window.open(fileResult.blobUrl, "_blank");
    dispatch(setProgress(false));
  };

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <h2 className="my-3 ml-1">員工資料</h2>

      <div className="border-round-lg bg-white p-3">
        {renderSearchWrap({
          isShow: false,
          template: (
            <>
              <div className="col-12 md:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                <label
                  htmlFor="username"
                  className="w-4rem white-space-nowrap text-left mb-1"
                >
                  人事狀態
                </label>
                <Dropdown
                  name="type"
                  className="w-full"
                  value={filterData.type}
                  onChange={(e) => changeFilter(e)}
                  options={optionData?.member_type ?? []}
                  optionLabel="label"
                  optionValue="value"
                  emptyMessage={system_dropdown.empty}
                  emptyFilterMessage={system_dropdown.emptyFilter}
                  showClear
                />
              </div>
              <div className="col-12 md:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                <label
                  htmlFor="departmentid"
                  className="w-4rem white-space-nowrap text-left mb-1"
                >
                  部門
                </label>
                <div className="relative w-full">
                  <TreeSelect
                    className="text-lg"
                    name="departmentid"
                    value={filterData.departmentid}
                    options={optionData?.department ?? []}
                    filter={true}
                    emptyMessage={system_dropdown.empty}
                    onChange={(e) => changeFilter({ target: e.target })}
                  ></TreeSelect>
                  {filterData.departmentid && (
                    <i
                      className="absolute top-0 right-0 cursor-pointer mt-2 pi pi-times"
                      style={{ marginRight: "2.5rem" }}
                      onClick={() =>
                        changeFilter({
                          target: { name: "departmentid", value: null },
                        })
                      }
                    ></i>
                  )}
                </div>
              </div>
              <div
                className={classNames(
                  "col-12 md:col-6 flex align-items-end mt-2 md:mt-0"
                )}
              >
                <Button
                  className="p-button-info p-button-sm w-auto ml-auto md:ml-0 md:mt-0 px-3"
                  type="button"
                  icon="pi pi-search"
                  label="查詢"
                  onClick={() => {
                    setSearch({
                      ...filterData,
                      page: 1,
                    });
                    setTbFirstIndex(0);
                  }}
                />

                <Button
                  className="p-button-warning p-button-sm w-auto md:ml-auto ml-3 px-3"
                  type="button"
                  label="新增"
                  onClick={() => route_navigate(`/back${pagePath}/add`)}
                />
              </div>
            </>
          ),
        })}

        {renderTable({
          className: "mt-2",
          options: { scrollable: true, scrollDirection: "both" },
        })}
      </div>

      {renderDialog({
        className: classNames("w-11 md:w-10 lg:w-8", {
          "max-w-35rem": isPop.account?.isOpen,
        }),
        children: (
          <>
            {isPop.account?.isOpen && (
              <ModulePopMemberAccount
                prePopOption={popOption}
                closePrePop={closePop}
                setPreReload={setReload}
              />
            )}
            {isPop.contract?.isOpen && (
              <ModulePopContract
                prePopOption={popOption}
                closePrePop={closePop}
                setPreReload={setReload}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
