import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../redux/slice/system";
import { setUser } from "../redux/slice/user";
import { useHookForm } from "../components/hooks";
import { CommonAPI } from "../axios/api";
import moment from "moment";

import LoginCss from "../styles/components/login.module.scss";

import ImgLogo from "../images/logo.png";

import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function BackLogin(props) {
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  // 用於控制表單刷新
  const [formKey, setFormKey] = useState(new Date());
  // 表單欄位預設值
  const defaultValues = {
    username: "",
    password: "",
  };
  const {
    Controller,
    handleSubmit,
    control,
    reset,
    setValue,
    yup,
    setSchema,
    errors,
  } = useHookForm({
    defaultValues,
  });
  // 驗證碼
  const [captchaSetting, setCaptchaSetting] = useState({
    code: "",
    refresh: true,
  });

  useEffect(() => {
    setSchema(
      yup
        .object({
          username: yup.string().required("必填"),
          password: yup.string().required("必填"),
        })
        .required()
    );
  }, []);

  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    CommonAPI.login({ data: submitData }).then((result) => {
      let { Response, data } = result;

      if (Response === 1) {
        if (result.type === 1) {
          dispatch(
            setToast({
              severity: "warn",
              summary: "登入失敗",
              detail: "無權限",
            })
          );
          dispatch(setProgress(false));
          return;
        }

        result.now = moment().format("YYYY/MM/DD HH:mm:ss");

        // 是否可以評核
        let nowMonth = Number(moment(result.server_datetime).format("MM"));
        result.isAssessment = false; // [1, 4, 7, 10].includes(nowMonth);

        reset();
        setFormKey(new Date());
        dispatch(setUser(result));

        route_navigate(
          result.type === 2
            ? "/back/assessment/member"
            : result.type === 4
            ? "/back/assessment/manager"
            : "/back/member/profile"
        );
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: "登入失敗",
            detail: "帳密錯誤",
          })
        );
      }

      dispatch(setProgress(false));
    });
  };

  return (
    <>
      <div
        className={`${LoginCss["login-wrap"]} flex flex-column w-full h-full p-2`}
      >
        <div className="flex flex-column align-items-center justify-content-center h-full">
          <Card
            className={`${LoginCss["login-inner-wrap"]} w-11 sm:w-6 text-2xl px-3`}
            style={{ maxWidth: "50rem" }}
          >
            <div className="flex align-items-center lg:flex-row flex-column">
              <div className="w-12 lg:w-5 text-center lg:mr-5 mr-0">
                <img
                  src={ImgLogo}
                  alt="logo"
                  className="w-10 max-w-30rem lg:mb-0 mb-4 opacity-60"
                />
              </div>

              <div className="w-12 lg:w-7 text-center">
                {process.env.REACT_APP_MODE === "development" && (
                  <div className="text-center mb-4 mt-0 lg:-mt-4">
                    <div className="inline-block text-xl text-white bg-red-500 font-bold px-2 py-1">
                      測試站
                    </div>
                  </div>
                )}

                <h3 className="text-main-dark text-center mt-0 mb-4">
                  人資系統管理後台
                </h3>

                <form
                  key={formKey}
                  onSubmit={handleSubmit(onSubmit)}
                  className="p-fluid text-lg"
                >
                  <div className="field flex align-items-center">
                    <label
                      htmlFor="username"
                      className={classNames(
                        { "p-error": errors.username },
                        "white-space-nowrap mr-2 mb-0"
                      )}
                    >
                      帳號
                    </label>
                    <Controller
                      name="username"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                  </div>
                  <div className="field flex align-items-center">
                    <label
                      htmlFor="password"
                      className={classNames(
                        { "p-error": errors.password },
                        "white-space-nowrap mr-2 mb-0"
                      )}
                    >
                      密碼
                    </label>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Password
                          id={field.name}
                          {...field}
                          toggleMask
                          feedback={false}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                  </div>

                  <Button
                    type="submit"
                    label="登入"
                    className="p-button-main"
                  />
                </form>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
