import { system_dropdown } from "../../service/system";

import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

export default function FormDropdown(props) {
  const {
    className,
    setting: { Controller, control, trigger, isSubmitted, isEdit = true },
    data: {
      name,
      options = [],
      optionLabel = "label",
      optionValue = "value",
      placeholder = "",
      filter = false,
      filterBy = "value",
      disabled = false,
      showClear = true,
      changeFun = () => null,
    },
  } = props;

  const onChange = (e, field) => {
    field.onChange(e.value === undefined ? null : e.value);
    if (isSubmitted) {
      trigger();
    } else {
      trigger(field.name);
    }
    changeFun();
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <Dropdown
            id={field.name}
            className={classNames(
              { "p-invalid": fieldState.invalid },
              "w-full",
              className
            )}
            {...field}
            onChange={(e) => onChange(e, field)}
            disabled={!isEdit || disabled}
            placeholder={placeholder}
            options={options}
            optionLabel={optionLabel}
            optionValue={optionValue}
            filter={filter}
            filterBy={filterBy}
            emptyFilterMessage={system_dropdown.emptyFilter}
            emptyMessage={system_dropdown.empty}
            showClear={showClear}
          />
        )}
      />
    </>
  );
}
