import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import {
  axios,
  AxiosMulti,
  formatApiResult,
} from "../../../../axios/request/common";
import { AssessmentAPI, SettingAPI } from "../../../../axios/api";
import {
  useAbortedEffect,
  useHookForm,
  usePop,
} from "../../../../components/hooks";
import moment from "moment";

import ModuleBreadCrumb from "../../../../components/breadCrumb";
import ModuleTemplateForm from "../../../../components/common/templateForm";
import ModulePopTemplateConfirm from "../template/popConfirm";
import {
  FormInputtext,
  FormDropdown,
  FormRadio,
} from "../../../../components/formElement/index";
import {
  formatResData,
  checkFormUpdate,
  formatOptions,
} from "../../../../service/common";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function BackAssessmentPublishDetail() {
  const route_params = useParams();
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const redux_user = useSelector((state) => state.user);
  const crumbItem = [
    {
      key: 1,
      label: "考核表管理",
      command: () => route_navigate("/back/assessment/publish"),
    },
    { key: 2, label: "編輯" },
  ];
  const [reload, setReload] = useState({
    list: true,
  });
  const [disabled, setDisabled] = useState(false);
  // 明細資料
  const [apiData, setApiData] = useState({ raw: {}, format: {} });
  const [templateBtnDisabled, setTemplateBtnDisabled] = useState(false);
  // 表單欄位預設值
  const defaultValues = {
    name: "",
    isTemplate: 1,
    template: null,
    category: [],
    manager: [],
    manager_weights: {},
    employee: [],
    remark: "",
  };
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
    watch,
    yup,
    setSchema,
    errors,
    setting,
    setIsFormUpdate,
  } = useHookForm({
    defaultValues,
  });
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "confirm",
      title: () => "確認",
      isOpen: false,
    },
  ]);
  const [optionData, setOptionData] = useState({});
  // 監聽欄位
  const watchColumn = {
    manager_weights: watch("manager_weights"),
  };

  useEffect(() => {
    setSchema(
      yup
        .object({
          name: yup.string().required("必填"),
          category: yup
            .array()
            .test("isValidPass", "必填", (value) => value.length > 0),
          manager: yup
            .array()
            .test("isValidPass", "必填", (value) => value.length > 0),
          manager_weights: yup.object().shape({
            ...Object.keys(watchColumn.manager_weights).reduce(
              (pre, cur) => ({ ...pre, [cur]: yup.number().required("必填") }),
              {}
            ),
          }),
          employee: yup.array(),
          remark: yup.string(),
        })
        .required()
    );
  }, [watchColumn.manager_weights]);

  useEffect(() => {
    setDisabled(!!redux_user.userDetail?.isAssessment);
  }, [redux_user]);

  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      let isAdd = route_params.detailid === "add",
        params = { id: route_params.detailid },
        promise = [
          AssessmentAPI.getTmpExamExamplesList({ options: { signal } }),
          AssessmentAPI.getExamItemsList({
            data: { page: -1, status: 1 },
            options: { signal },
          }),
          SettingAPI.getDepartmentList({ options: { signal } }),
          AssessmentAPI.getRaterList({
            data: { page: -1 },
            options: { signal },
          }),
          AssessmentAPI.getRatederList({
            data: { page: -1 },
            options: { signal },
          }),
        ];
      if (!isAdd)
        promise.push(
          AssessmentAPI.getExamExamplesDetails({
            data: params,
            options: { signal },
          })
        );

      AxiosMulti(promise).then(
        axios.spread(
          (
            result_template = {},
            result_category = {},
            result_department = {},
            result_rater = {},
            result_rateder = {},
            result_detail = {}
          ) => {
            let tempOptions = {};

            // 考核模板
            let { data: result_template_data } = result_template;
            if (result_template.Response === 1) {
              tempOptions.template = result_template_data;
            }

            // 考核項目
            let { data: result_category_data } = result_category;
            if (result_category.Response === 1) {
              tempOptions = {
                ...tempOptions,
                ...formatOptions(
                  { category: result_category_data },
                  { uniqueKey: "id", showLabel: "name" }
                ),
              };
            }

            // 部門
            let { data: result_department_data } = result_department;
            if (result_department.Response === 1) {
              tempOptions = {
                ...tempOptions,
                ...formatOptions(
                  { department: result_department_data },
                  { uniqueKey: "id", showLabel: "name" }
                ),
              };
            }

            // 評核人員
            let { data: result_rater_data } = result_rater;
            if (result_rater.Response === 1) {
              result_rater_data = result_rater_data.map((item) => ({
                ...item,
                label: `${item.ch_last_name}${item.ch_first_name}`,
              }));
              tempOptions = {
                ...tempOptions,
                ...formatOptions(
                  { rater: result_rater_data },
                  { uniqueKey: "username", showLabel: "label" }
                ),
              };
            }

            // 受評人員
            let { data: result_rateder_data } = result_rateder;
            if (result_rateder.Response === 1) {
              result_rateder_data = tempOptions.department.map((item) => ({
                ...item,
                data: result_rateder_data
                  .filter((rateder) => rateder.departmentid === item.id)
                  .map((rateder) => ({
                    ...rateder,
                    label: `${rateder.ch_last_name}${rateder.ch_first_name}`,
                  })),
              }));
              tempOptions.rateder = result_rateder_data;
            }

            // 明細資料
            let { data: result_detail_data } = result_detail;
            if (result_detail.Response === 1) {
              result_detail_data.category =
                result_detail_data.exam_example_item_setting.map(
                  (item) => item.exam_itemid
                );
              result_detail_data.manager =
                result_detail_data.exam_example_weight.map(
                  (item) => item.rater
                );
              result_detail_data.manager_weights =
                result_detail_data.exam_example_weight.reduce(
                  (pre, cur) => ({ ...pre, [cur.rater]: cur.weight }),
                  {}
                );
              let uniqueRelation = result_detail_data.exam_relation
                .map((item) => ({
                  ch_first_name: item.rateder_ch_first_name,
                  ch_last_name: item.rateder_ch_last_name,
                  departmentid: item.rateder_departmentid,
                  en_first_name: item.rateder_en_first_name,
                  en_last_name: item.rateder_en_last_name,
                  id: item.id,
                  username: item.rateder,
                  departmentid: item.rateder_departmentid,
                  label: `${item.rateder_ch_last_name}${item.rateder_ch_first_name}`,
                }))
                .filter(
                  (value, index, array) =>
                    array
                      .map((item) => item.username)
                      .indexOf(value.username) === index
                );
              result_detail_data.employee = uniqueRelation.map(
                (item) => item.username
              );
              let temp = formatResData(defaultValues, result_detail_data);
              setTimeout(() => {
                setApiData({ raw: result_detail_data, format: temp });
                reset(temp);
              }, 100);

              // 合併已選擇受評人員至未選擇資料
              let departmentEmp = uniqueRelation.reduce((pre, cur) => {
                if (!pre[cur.departmentid]) pre[cur.departmentid] = [];
                pre[cur.departmentid].push(cur);
                return pre;
              }, {});
              tempOptions.rateder = tempOptions.rateder.map((item) => ({
                ...item,
                data: (departmentEmp[item.id] || []).concat(item.data),
              }));
            }

            let isError = formatApiResult({
              data: [
                result_template,
                result_category,
                result_department,
                result_rater,
                result_rateder,
                result_detail,
              ],
              action: (message) => {
                dispatch(
                  setToast({
                    severity: "error",
                    summary: message,
                    detail: "",
                  })
                );
              },
            });
            if (isError) {
              dispatch(setProgress(false));
              return;
            }

            setOptionData(tempOptions);
            setReload((state) => ({ ...state, list: false }));
            dispatch(setProgress(false));
          }
        )
      );
    },
    [reload]
  );

  const onSubmit = () => {
    let score = calculateCategoryScore(),
      weights = calculateTotalWeights();
    if (score < 100 || weights < 100) {
      openPop({
        type: "confirm",
        data: {
          type: "publish",
          score,
          weights,
        },
      });
    } else saveData();
  };

  const saveData = () => {
    dispatch(setProgress(true));
    setIsFormUpdate(false);

    let submitData = getValues(),
      isAdd = route_params.detailid === "add",
      jsonData = {
        ...submitData,
      };

    if (!checkFormUpdate(jsonData, apiData.format)) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      route_navigate("/back/assessment/publish");
      return;
    }

    if (isAdd) {
      jsonData.exam_example_item_setting = jsonData.category.map((item) => ({
        exam_itemid: item,
      }));
      jsonData.exam_example_weight = jsonData.manager.map((item) => ({
        rater: item,
        weight: jsonData.manager_weights[item],
      }));
      jsonData.exam_relation = jsonData.manager
        .map((manager) =>
          jsonData.employee.map((employee) => ({
            rater: manager,
            rateder: employee,
          }))
        )
        .flat();
    } else {
      jsonData.exam_example_item_setting = {
        insert: jsonData.category
          .filter((item) => !apiData.format.category.includes(item))
          .map((item) => ({
            exam_itemid: item,
          })),
        update: [],
        delete: apiData.format.category.filter(
          (item) => !jsonData.category.includes(item)
        ),
      };

      jsonData.exam_example_weight = {
        insert: jsonData.manager
          .filter((item) => !apiData.format.manager.includes(item))
          .map((item) => ({
            rater: item,
            weight: jsonData.manager_weights[item],
          })),
        update: apiData.format.manager
          .filter((item) => jsonData.manager.includes(item))
          .map((item) => ({
            id: apiData.raw.exam_example_weight.find(
              (weight) => weight.rater === item
            )?.id,
            rater: item,
            weight: jsonData.manager_weights[item],
          })),
        delete: apiData.format.manager
          .filter((item) => !jsonData.manager.includes(item))
          .map(
            (item) =>
              apiData.raw.exam_example_weight.find(
                (weight) => weight.rater === item
              )?.id
          ),
      };

      let relation = {
        old: apiData.raw.exam_relation,
        new: jsonData.manager
          .map((manager) =>
            jsonData.employee.map((employee) => ({
              rater: manager,
              rateder: employee,
            }))
          )
          .flat(),
      };
      jsonData.exam_relation = {
        insert: relation.new.filter(
          (newItem) =>
            !relation.old.find(
              (oldItem) =>
                oldItem.rater === newItem.rater &&
                oldItem.rateder === newItem.rateder
            )
        ),
        update: [],
        delete: relation.old
          .filter(
            (oldItem) =>
              !relation.new.find(
                (newItem) =>
                  oldItem.rater === newItem.rater &&
                  oldItem.rateder === newItem.rateder
              )
          )
          .map((item) => item.id),
      };
    }

    delete jsonData.isTemplate;
    delete jsonData.template;
    delete jsonData.category;
    delete jsonData.manager;
    delete jsonData.manager_weights;
    delete jsonData.employee;

    let promise,
      toast = "";
    if (isAdd) {
      // 新增
      promise = AssessmentAPI.setExamExamples({
        data: jsonData,
      });
      toast = "新增";
    } else {
      // 修改
      jsonData.id = Number(route_params.detailid);
      promise = AssessmentAPI.updateExamExamples({
        data: jsonData,
      });
      toast = "修改";
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: `${toast}成功`,
            detail: "",
          })
        );
        route_navigate("/back/assessment/publish");
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `${toast}失敗`,
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 套用模板
  const applyTemplate = () => {
    let templateId = getValues("template");
    if (!templateId) return;

    let matchData = optionData.template?.find((item) => item.id === templateId);
    if (!matchData) return;

    let tempCategory = matchData.tmp_exam_example_item_setting.map(
      (item) => item.exam_itemid
    );

    setValue("category", tempCategory);
    trigger("category");
    setTemplateBtnDisabled(true);
  };

  // 計算評核權重
  const calculateTotalWeights = () => {
    return getValues("manager").reduce(
      (pre, cur) => (pre += getValues("manager_weights")[cur] ?? 0),
      0
    );
  };

  // 計算考核項目總分
  const calculateCategoryScore = () => {
    return (getValues("category") || [])
      .map((categoryid) => {
        let category = optionData.category_obj[categoryid] ?? {};
        let maxScore = Math.max(
          ...(category.content || []).map((item) => item.score)
        );
        return maxScore;
      })
      .reduce((pre, cur) => (pre += cur), 0);
  };

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <div className="border-round-lg bg-white mt-3 p-3">
        <h2 className="mt-2 mb-4 ml-2">考核表管理 - 編輯</h2>

        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid">
          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="name"
              className={classNames(
                { "p-error": errors.name },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>考核名稱
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputtext
                setting={setting}
                data={{
                  name: "name",
                  disabled: disabled,
                }}
              />
            </div>
          </div>

          {route_params.detailid === "add" && (
            <div className="flex flex-column align-items-start sm:flex-row field col-12">
              <label
                htmlFor="title"
                className={classNames(
                  { "p-error": errors.title },
                  "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
                )}
              >
                考核模板
              </label>
              <div className="col-12 sm:col-10 md:col-8 lg:col-5">
                <div className="flex align-items-center">
                  <FormRadio
                    setting={setting}
                    data={{
                      name: "isTemplate",
                      label: "自行輸入",
                      value: 1,
                      disabled: disabled,
                    }}
                  />
                </div>
                <div className="flex align-items-center">
                  <FormRadio
                    setting={setting}
                    data={{
                      name: "isTemplate",
                      label: "",
                      value: 2,
                      disabled: disabled,
                    }}
                  />
                  <FormDropdown
                    className="-ml-2"
                    setting={setting}
                    data={{
                      name: "template",
                      options: optionData.template || [],
                      optionLabel: "name",
                      optionValue: "id",
                      showClear: false,
                      disabled: disabled || getValues("isTemplate") !== 2,
                      changeFun: () => setTemplateBtnDisabled(false),
                    }}
                  />
                  <Button
                    type="button"
                    label="套用模板"
                    className="p-button-sm w-7rem ml-2"
                    onClick={() => applyTemplate()}
                    disabled={
                      disabled ||
                      templateBtnDisabled ||
                      getValues("isTemplate") !== 2
                    }
                  />
                </div>
                <div className="text-sm text-blue-400 ml-5">
                  * 套用模板會影響下方『考核項目』
                </div>
              </div>
            </div>
          )}

          <ModuleTemplateForm
            setting={setting}
            errors={errors}
            getValues={getValues}
            disabled={disabled}
            calculateTotalWeights={calculateTotalWeights}
            calculateCategoryScore={calculateCategoryScore}
            optionData={optionData}
          />

          <div className="field col-12 text-right">
            <Button
              type="button"
              label="取消"
              className="p-button-secondary p-button-outlined ml-auto px-3"
              onClick={() => route_navigate("/back/assessment/publish")}
            />
            {!disabled && (
              <Button type="submit" label="儲存" className="ml-2 px-3" />
            )}
          </div>
        </form>
      </div>

      {renderDialog({
        className: "w-11 lg:w-8 md:w-9 sm:w-10 max-w-30rem",
        children: (
          <>
            {isPop.confirm?.isOpen && (
              <ModulePopTemplateConfirm
                prePopOption={popOption}
                closePrePop={closePop}
                saveData={saveData}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
