import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";

export default function FormInputnumber(props) {
  const {
    className,
    setting: { Controller, control, trigger, isSubmitted, isEdit = true },
    data: {
      name,
      min = null,
      max = null,
      useGrouping = true,
      maxFractionDigits = 20,
      showButtons = false,
      placeholder = "",
      disabled = false,
    },
  } = props;

  const onChange = (type, e, field) => {
    let value = e.value;
    if (type === "blur") {
      value =
        e.target.value === ""
          ? null
          : Number(e.target.value.replaceAll(",", ""));
    }

    field.onChange(value);

    if (isSubmitted) {
      trigger();
    } else {
      trigger(name);
    }
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <div className={classNames("flex align-items-center", className)}>
            <InputNumber
              id={field.name}
              className={classNames(
                { "p-invalid": fieldState.invalid },
                "w-full"
              )}
              {...field}
              mode="decimal"
              min={min}
              max={max}
              useGrouping={useGrouping}
              maxFractionDigits={maxFractionDigits}
              showButtons={showButtons}
              placeholder={placeholder}
              onChange={(e) => onChange("change", e, field)}
              onBlur={(e) => onChange("blur", e, field)}
              disabled={!isEdit || disabled}
            />
          </div>
        )}
      />
    </>
  );
}
