import React, { useState, useEffect } from "react";

import { getPageSetting } from "../../service/common";

import ComCss from "../../styles/components/tab.module.scss";

import { classNames } from "primereact/utils";

const useTab = ({
  isRWD = true,
  moduleType = "seperate",
  className,
  initActiveIndex = null,
  data = [],
  matchTab = [],
  changeAction = () => {},
}) => {
  const [tabData, setTabData] = useState(data);
  const [activeIndex, setActiveIndex] = useState(initActiveIndex);
  const [screen, setScreen] = useState({
    width: null,
  });

  useEffect(() => {
    const resize = (e) => {
      setScreen((state) => ({ ...state, width: e.target.innerWidth }));
    };

    setScreen((state) => ({ ...state, width: window.innerWidth }));
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  useEffect(() => {
    if (tabData.length === 0) return;

    let result = getPageSetting(matchTab);
    if (!result) {
      setActiveIndex(0);
      return;
    }

    let matchIdx = tabData.findIndex((item) => item.type === result.tab);
    if (matchIdx === -1) matchIdx = 0;
    setActiveIndex(matchIdx);
  }, [tabData]);

  const onTabChange = ({ index } = {}) => {
    setActiveIndex(index);
    changeAction(index);
  };

  const renderTab = (
    <div
      className={`${ComCss["tab-wrap"]} ${
        moduleType === "underline" ? ComCss["tab-underline"] : ""
      } ${className}`}
    >
      {tabData.map((item, index) => (
        <React.Fragment key={`tab_${item.key}`}>
          <div
            className={classNames(ComCss["tab-item"], {
              [ComCss["tab-active"]]: activeIndex === index,
              "w-4 sm:w-auto": isRWD,
            })}
            onClick={() => onTabChange({ index: index })}
          >
            {screen.width <= 1280 && item.sm_label ? item.sm_label : item.label}
          </div>
        </React.Fragment>
      ))}
    </div>
  );

  return {
    tabData,
    setTabData,
    activeIndex,
    setActiveIndex,
    onTabChange,
    renderTab,
  };
};

export default useTab;
