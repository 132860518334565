import BackLayoutMain from "../components/backLayout/main";

import BackChangePassword from "../page/back/password";

import BackAssessmentMemberMainList from "../page/back/assessment/member";
import BackAssessmentManagerMainList from "../page/back/assessment/manager";
import BackAssessmentPublishList from "../page/back/assessment/publish";
import BackAssessmentPublishDetail from "../page/back/assessment/publish/detail";
import BackAssessmentTemplateList from "../page/back/assessment/template";
import BackAssessmentTemplateDetail from "../page/back/assessment/template/detail";
import BackAssessmentCategoryList from "../page/back/assessment/category";
import BackAssessmentCategoryDetail from "../page/back/assessment/category/detail";

import BackMemberProfileList from "../page/back/member/profile";
import BackMemberProfileDetail from "../page/back/member/profile/detail";
import BackMemberTreeList from "../page/back/member/tree";

import BackSettingGoodAtToolList from "../page/back/setting/goodAtTool";
import BackSettingLanguageList from "../page/back/setting/language";
import BackSettingDepartmentList from "../page/back/setting/department";
import BackSettingAttendanceTypeList from "../page/back/setting/attendanceType";
import BackSettingLeaveTypeList from "../page/back/setting/leaveType";

import BackRecordAwardList from "../page/back/record/award";
import BackRecordPunishList from "../page/back/record/punish";

import BackUploadAttendanceList from "../page/back/upload/attendance";
import BackUploadLeaveWorkList from "../page/back/upload/leaveWork";

const routes = [
  {
    path: "back",
    element: <BackLayoutMain />,
    children: [
      {
        path: "password",
        element: <BackChangePassword />,
      },

      {
        path: "assessment/member",
        element: <BackAssessmentMemberMainList />,
      },
      {
        path: "assessment/manager",
        element: <BackAssessmentManagerMainList />,
      },
      {
        path: "assessment/publish",
        element: <BackAssessmentPublishList />,
      },
      {
        path: "assessment/publish/:detailid",
        element: <BackAssessmentPublishDetail />,
      },
      {
        path: "assessment/template",
        element: <BackAssessmentTemplateList />,
      },
      {
        path: "assessment/template/:detailid",
        element: <BackAssessmentTemplateDetail />,
      },
      {
        path: "assessment/category",
        element: <BackAssessmentCategoryList />,
      },
      {
        path: "assessment/category/:detailid",
        element: <BackAssessmentCategoryDetail />,
      },

      {
        path: "member/profile",
        element: <BackMemberProfileList />,
      },
      {
        path: "member/profile/:detailid",
        element: <BackMemberProfileDetail />,
      },
      {
        path: "member/tree",
        element: <BackMemberTreeList />,
      },

      {
        path: "setting/goodAtTool",
        element: <BackSettingGoodAtToolList />,
      },
      {
        path: "setting/language",
        element: <BackSettingLanguageList />,
      },
      {
        path: "setting/department",
        element: <BackSettingDepartmentList />,
      },
      {
        path: "setting/attendanceType",
        element: <BackSettingAttendanceTypeList />,
      },
      {
        path: "setting/leaveType",
        element: <BackSettingLeaveTypeList />,
      },

      {
        path: "record/award",
        element: <BackRecordAwardList />,
      },
      {
        path: "record/punish",
        element: <BackRecordPunishList />,
      },

      {
        path: "upload/attendance",
        element: <BackUploadAttendanceList />,
      },
      {
        path: "upload/leaveWork",
        element: <BackUploadLeaveWorkList />,
      },

      {
        path: "*",
        element: <>無畫面</>,
      },
    ],
  },
];

export default {
  routes,
};
