import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { AssessmentAPI } from "../../../../axios/api";
import {
  useAbortedEffect,
  useSearch,
  useTable,
} from "../../../../components/hooks";

import ModuleBreadCrumb from "../../../../components/breadCrumb";
import {
  formatOptions,
  setPageSetting,
  formatNumber,
} from "../../../../service/common";
import { system_dropdown, system_table } from "../../../../service/system";
import { option_enabled } from "../../../../service/option";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

export default function BackAssessmentCategoryList() {
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbItem = [{ key: 1, label: "考核項目管理" }];
  const [reload, setReload] = useState({ list: false });
  const {
    setTbData,
    tbTotalRecords,
    setTbTotalRecords,
    setTbFirstIndex,
    setTbRows,
    setTbColumns,
    renderTable,
    setPageAction,
  } = useTable();
  // 篩選條件
  const defaultFilter = {
      page: 1,
      size: system_table.size,
      enabled: null,
    },
    pagePath = "/assessment/category";
  const {
    filterData,
    setFilterData,
    search,
    setSearch,
    changeFilter,
    renderSearchWrap,
  } = useSearch({
    defaultFilter,
    pagePath: [pagePath],
    tbSetting: { setTbFirstIndex, setTbRows },
    reloadAction: () => setReload((state) => ({ ...state, list: true })),
  });
  const [optionData, setOptionData] = useState(null);

  useEffect(() => {
    const action = ({ startIndex, rows }) => {
      let temp = {
        ...search,
        page: Math.floor(startIndex / rows) + 1,
        size: rows,
      };
      setFilterData(temp);
      setSearch(temp);
    };
    setPageAction(
      () =>
        ({ startIndex, rows }) =>
          action({ startIndex, rows })
    );
  }, [search]);

  useEffect(() => {
    let temp = formatOptions({
      enabled: option_enabled,
    });

    setOptionData((state) => ({
      ...state,
      ...temp,
    }));
  }, []);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list || !optionData) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = {
        ...search,
      };

      AssessmentAPI.getExamItemsList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, total, message } = result;

        if (Response === 1) {
          data = data.map((item) => ({
            ...item,
            content_format: item.content
              .map((detail) => `${detail.content}(${detail.score ?? 0})`)
              .join("\n"),
            enabled_format: optionData.enabled_obj[item.status]?.label,
          }));
          setTbData(data);
          setTbTotalRecords(total);
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload, optionData]
  );

  useEffect(() => {
    if (!optionData) return;

    const renderEnabled = (data) => (
      <div
        className={classNames("white-space-prewrap", {
          "text-red-400": data.status === 0,
          "text-green-400": data.status === 1,
        })}
      >
        {data.enabled_format}
      </div>
    );

    const renderBtnAction = (data) => (
      <Button
        className="p-button-sm"
        type="button"
        label="編輯"
        onClick={() => route_navigate(`/back${pagePath}/${data.id}`)}
      />
    );

    setTbColumns([
      {
        field: "name",
        header: "項目",
        className: "flex-grow-1",
        style: { flexBasis: "100px" },
      },
      {
        field: "content_format",
        header: "內容",
        className: "flex-grow-1 white-space-prewrap",
        style: { flexBasis: "300px" },
      },
      {
        field: "enabled_format",
        header: "狀態",
        className: "flex-grow-1",
        style: { flexBasis: "50px" },
        body: (data) => renderEnabled(data),
      },
      {
        field: "id",
        header: "進階",
        className: "w-5rem froze-right",
        alignFrozen: "right",
        frozen: true,
        body: (data) => renderBtnAction(data),
      },
    ]);
  }, [pagePath, optionData]);

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <h2 className="my-3 ml-1">考核項目管理</h2>

      <div className="border-round-lg bg-white p-3">
        {renderSearchWrap({
          isShow: false,
          template: (
            <>
              <div className="col-12 md:col-4 xl:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                <label
                  htmlFor="username"
                  className="w-4rem white-space-nowrap text-left mb-1"
                >
                  啟用狀態
                </label>
                <Dropdown
                  name="enabled"
                  className="w-full"
                  value={filterData.enabled}
                  onChange={(e) => changeFilter(e)}
                  options={optionData?.enabled ?? []}
                  optionLabel="label"
                  optionValue="value"
                  emptyFilterMessage={system_dropdown.emptyFilter}
                  showClear
                />
              </div>
              <div
                className={classNames(
                  "col-12 md:col-8 xl:col-9 flex align-items-end mt-2 md:mt-0"
                )}
              >
                <Button
                  className="p-button-info p-button-sm w-auto ml-auto md:ml-0 md:mt-0 px-3"
                  type="button"
                  icon="pi pi-search"
                  label="查詢"
                  onClick={() => {
                    setSearch({
                      ...filterData,
                      page: 1,
                    });
                    setTbFirstIndex(0);
                  }}
                />

                <Button
                  className="p-button-warning p-button-sm w-auto md:ml-auto ml-3 px-3"
                  type="button"
                  label="新增"
                  onClick={() => route_navigate(`/back${pagePath}/add`)}
                />
              </div>
            </>
          ),
        })}

        {renderTable({
          className: "mt-2",
          options: { scrollable: true, scrollDirection: "both" },
        })}
      </div>
    </>
  );
}
