import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { usePop, useHookForm, useFilterList } from "../hooks";

import {
  FormInputtext,
  FormDropdown,
  FormInputnumber,
  FormInputtextarea,
} from "../formElement";
import { formatResData, checkFormUpdate } from "../../service/common";
import { option_enabled } from "../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";

/*
  @params keyObj: { id: 資料的唯一值, label: 資料呈現的文字,status: 啟用狀態 }
*/
function useChipForm({ keyObj = {}, chipLabel, action = {} }) {
  const [chipData, setChipData] = useState({
    term: "",
    data: [],
  });
  const { getFilterListData } = useFilterList();
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "form",
      title: (props) => (props?.data?.id ? "編輯" : "新增"),
      isOpen: false,
    },
  ]);

  const listData = useMemo(
    () =>
      getFilterListData({
        data: chipData.data,
        filterKey: keyObj.label.key,
        serachTerm: chipData.term,
      }) || [],
    [chipData]
  );

  const addChip = () => openPop({ type: "form", data: null });

  const renderChipBlock = () => {
    const renderStatusLabel = ({ data }) => (
      <div className="flex align-items-center mx-2">
        <div
          className={classNames("w-1rem h-1rem border-circle", {
            "bg-main": data.value === 1,
            "bg-fifth": data.value === 0,
          })}
        ></div>
        <div className="ml-1">{data.label}</div>
      </div>
    );

    return (
      <>
        {keyObj.status?.key !== undefined && (
          <div className="flex flex-wrap mb-2">
            {option_enabled.map((item) => (
              <React.Fragment key={`status_${item.value}`}>
                {renderStatusLabel({ data: item })}
              </React.Fragment>
            ))}
          </div>
        )}

        <div className="flex flex-wrap align-items-start bg-gray-100 border-1 border-400 min-h-5rem p-2">
          <div className="flex align-items-center mb-1 px-2">
            <InputText
              value={chipData.term}
              placeholder="關鍵字搜尋"
              onChange={(e) =>
                setChipData((state) => ({ ...state, term: e.target.value }))
              }
            />
            <Button
              type="button"
              icon="pi pi-refresh"
              className="p-button-secondary p-button-sm ml-2"
              onClick={() => setChipData((state) => ({ ...state, term: "" }))}
            />
          </div>

          <Divider className="my-1" />

          <div className="px-1">
            {listData.map((item, index) => (
              <div
                key={`chip_${item[keyObj.id.key]}`}
                className={classNames(
                  "inline-flex align-items-center text-lg text-white font-bold border-1 border-400 border-round-3xl py-1 m-1",
                  {
                    "bg-sub":
                      item[keyObj.status?.key] === 1 ||
                      item[keyObj.status?.key] === undefined,
                    "bg-fifth": item[keyObj.status?.key] === 0,
                  }
                )}
              >
                <span className="px-3">
                  {chipLabel ? chipLabel(item) : item[keyObj.label.key]}
                </span>

                <span
                  className={classNames(
                    "flex align-items-center justify-content-center text-white hover:text-white-alpha-50 border-left-1 border-white border-round-right-3xl px-2 py-1 cursor-pointer",
                    {
                      "bg-sub":
                        item[keyObj.status?.key] === 1 ||
                        item[keyObj.status?.key] === undefined,
                      "bg-fifth": item[keyObj.status?.key] === 0,
                    }
                  )}
                  onClick={() => openPop({ type: "form", data: item })}
                >
                  <i className="pi pi-pencil text-sm"></i>
                </span>
              </div>
            ))}

            {listData.length === 0 && (
              <div className="text-red-400 mt-1">無資料</div>
            )}
          </div>
        </div>

        {renderDialog({
          className: "w-11 md:w-10 lg:w-8 max-w-30rem",
          children: (
            <>
              {isPop.form?.isOpen && (
                <PopForm
                  prePopOption={popOption}
                  closePrePop={closePop}
                  keyObj={keyObj}
                  action={action}
                />
              )}
            </>
          ),
        })}
      </>
    );
  };

  return { setChipData, addChip, renderChipBlock };
}

function PopForm({ prePopOption, closePrePop, keyObj, action }) {
  const dispatch = useDispatch();
  // 表單欄位預設值
  const defaultValues = {
    ...Object.values(keyObj).reduce(
      (pre, cur) => ({
        ...pre,
        [cur.key]: cur.defVal
          ? cur.defVal
          : ["number", "select"].includes(cur.ui)
          ? null
          : "",
      }),
      {}
    ),
  };
  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    yup,
    setSchema,
    errors,
    setting,
    setIsFormUpdate,
  } = useHookForm({
    defaultValues,
  });

  useEffect(() => {
    setSchema(
      yup
        .object({
          ...Object.values(keyObj).reduce(
            (pre, cur) => ({
              ...pre,
              [cur.key]: ["number", "select"].includes(cur.ui)
                ? cur.isRequired
                  ? yup.number().required("必填").nullable()
                  : yup.number().nullable()
                : cur.isRequired
                ? yup.string().required("必填")
                : yup.string(),
            }),
            {}
          ),
        })
        .required()
    );
  }, []);

  useEffect(() => {
    if (!prePopOption.data) return;

    reset(prePopOption.data);
  }, [prePopOption]);

  const onSubmit = (submitData) => {
    dispatch(setProgress(true));
    setIsFormUpdate(false);

    let jsonData = formatResData(defaultValues, submitData);

    if (!checkFormUpdate(jsonData, prePopOption.data ?? {})) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      closePrePop({ type: "form" });
      return;
    }

    let promise,
      toast = "";
    if (!prePopOption.data?.[keyObj.id.key]) {
      // 新增
      promise = action.insert(jsonData);
      toast = "新增";
    } else {
      // 修改
      delete jsonData.username;
      jsonData.id = prePopOption.data[keyObj.id.key];
      promise = action.update(jsonData);
      toast = "修改";
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: `${toast}成功`,
            detail: "",
          })
        );
        closePrePop({ type: "form" });
        action.reload();
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `${toast}失敗`,
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      <div className="px-4">
        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid">
          {Object.values(keyObj)
            .filter((item) => item.isForm)
            .map((item) => (
              <div
                key={item.key}
                className="flex flex-column align-items-start sm:flex-row field col-12"
              >
                <label
                  htmlFor={item.key}
                  className={classNames(
                    { "p-error": errors[item.key] },
                    "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0 sm:mt-1"
                  )}
                >
                  {item.isRequired && (
                    <span className="text-red-400 mr-1">*</span>
                  )}
                  {item.label}
                </label>
                <div className="col-12 sm:col-9">
                  {item.ui === "text" ? (
                    <FormInputtext
                      setting={setting}
                      data={{
                        name: item.key,
                      }}
                    />
                  ) : item.ui === "number" ? (
                    <FormInputnumber
                      setting={setting}
                      data={{
                        name: item.key,
                      }}
                    />
                  ) : item.ui === "textarea" ? (
                    <FormInputtextarea
                      setting={setting}
                      data={{
                        name: item.key,
                      }}
                    />
                  ) : item.ui === "select" ? (
                    <FormDropdown
                      setting={setting}
                      data={{
                        name: item.key,
                        options: item.options,
                      }}
                    />
                  ) : null}
                </div>
              </div>
            ))}

          <div className="col-12 text-right">
            <Button
              type="button"
              label="取消"
              className="p-button-secondary p-button-outlined ml-auto px-3"
              onClick={() => closePrePop({ type: "form" })}
            />
            <Button type="submit" label="儲存" className="ml-2 px-3" />
          </div>
        </form>
      </div>
    </>
  );
}

export default useChipForm;
