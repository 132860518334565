import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function ModulePopAttendanceDetail({
  popPreOption,
  closePrePop,
  uploadColumns,
}) {
  return (
    <>
      <div className="mx-3">
        <table className="w-full text-lg line-height-4 border-1 border-400 -mt-2">
          <tbody>
            {uploadColumns.map((item, index) => (
              <tr
                key={`${item.key}`}
                className={classNames({
                  "border-top-1 border-400": index !== 0,
                })}
              >
                <td className="w-8rem text-right text-white bg-main px-2">
                  {item.label}
                </td>
                <td className="white-space-prewrap px-2">
                  {popPreOption.data[item.key]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="text-center mt-3">
          <Button
            className="p-button-secondary px-3"
            type="button"
            label="關閉"
            onClick={() => closePrePop({ type: "detail" })}
          />
        </div>
      </div>
    </>
  );
}
