import instance from "./interceptor";

import { handleEncrypt, handleDecrypt } from "../../service/common";
import { system_api } from "../../service/system";

function request(
  url,
  params,
  options,
  method,
  { requestSecret = true, responseSecret = true } = {}
) {
  return new Promise((resolve, reject) => {
    let data = {};
    if (method === "get") data = { params };
    if (method === "post")
      data = {
        data: requestSecret
          ? {
              pos: handleEncrypt({
                CRYPTO_KEY: process.env.REACT_APP_CRYPTO_KEY,
                text: JSON.stringify(params),
              }),
            }
          : params,
      };

    instance({
      url,
      method,
      ...options,
      ...data,
    })
      .then((result) => {
        setTimeout(() => {
          let data = result.data;
          if (data) {
            if (data.res && responseSecret) {
              data = handleDecrypt({
                CRYPTO_KEY: process.env.REACT_APP_CRYPTO_KEY,
                text: data.res,
              });
              data = JSON.parse(data);
            }

            if (data.Response) {
              if (!data.message) data.message = "資訊異常，請聯繫維修人員";
              data.headers = result.headers;
              resolve(data);
            } else {
              resolve({ Response: -1, data: result, message: "no Response" });
            }
          } else {
            resolve({ Response: -1, message: "無回應" });
          }
        }, system_api.delay);
      })
      .catch((error) => {
        let { code, response } = error;
        setTimeout(() => {
          let Response = response?.status ? response.status : -1,
            message = "",
            statusMsg = {
              401: "超過使用時限，請重新登入",
              403: "無權限使用",
              404: "無此 API",
              500: "資訊異常，請聯繫維修人員",
            },
            codeMsg = {
              ECONNABORTED: "無法連線，請檢查網路是否正常",
              ERR_NETWORK: "無法連線，請檢查網路是否正常",
            };
          if ([401, 403, 404, 500].indexOf(response?.status) !== -1) {
            message = statusMsg[response.status];
          } else if (["ECONNABORTED", "ERR_NETWORK"].indexOf(code) !== -1) {
            message = codeMsg[code];
          } else if (code === "ERR_CANCELED") {
            return;
          } else {
            message = "資訊異常，請聯繫維修人員";
          }

          if (Response === 401) {
            setTimeout(() => {
              localStorage.clear();
              // window.location.href = "/login";
            }, 1500);
          }

          resolve({ Response, message });
        }, system_api.delay);
      })
      .finally(() => {});
  });
}

function get(url, params, options, settings) {
  return request(url, params, options, "get", settings);
}

function post(url, params, options, settings) {
  return request(url, params, options, "post", settings);
}

export default {
  get,
  post,
};
