import request from "../request";
import Url from "../url/common";

const login = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.login + path, data, options);
};
const getNewAccessToken = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.getNewAccessToken + path, data, options);
};
const changePassword = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.changePassword + path, data, options);
};
const getImg = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getImg + path, data, options);
};

export default {
  login,
  getNewAccessToken,
  changePassword,
  getImg,
};
