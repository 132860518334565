import request from "../request";
import Url from "../url/member";

const getMemberDetailsList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getMemberDetailsList + path, data, options);
};
const getMemberDetailsData = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getMemberDetailsData + path, data, options);
};
const setMemberDetailsData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setMemberDetailsData + path, data, options);
};
const updateMemberDetailsData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateMemberDetailsData + path, data, options);
};
const deleteMemberDetailsData = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.deleteMemberDetailsData + path, data, options);
};
const checkUsername = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.checkUsername + path, data, options);
};
const setMemberAccount = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setMemberAccount + path, data, options);
};
const disableAccount = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.disableAccount + path, data, options);
};
const reOpenAccount = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.reOpenAccount + path, data, options);
};

const getMemberTreeList = ({ path = "", data, options = {} } = {}) => {
  return request.get(Url.getMemberTreeList + path, data, options);
};
const setMemberTree = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.setMemberTree + path, data, options);
};
const updateMemberTree = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.updateMemberTree + path, data, options);
};
const othersFileUpload = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.othersFileUpload + path, data, options, {
    requestSecret: false,
  });
};
const othersDeleteFile = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.othersDeleteFile + path, data, options);
};
const humanChangeRecordsFileUpload = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.post(Url.humanChangeRecordsFileUpload + path, data, options, {
    requestSecret: false,
  });
};
const humanChangeRecordsDeleteFile = ({
  path = "",
  data,
  options = {},
} = {}) => {
  return request.post(Url.humanChangeRecordsDeleteFile + path, data, options);
};

export default {
  getMemberDetailsList,
  getMemberDetailsData,
  setMemberDetailsData,
  updateMemberDetailsData,
  deleteMemberDetailsData,
  checkUsername,
  setMemberAccount,
  disableAccount,
  reOpenAccount,
  othersFileUpload,
  othersDeleteFile,
  humanChangeRecordsFileUpload,
  humanChangeRecordsDeleteFile,

  getMemberTreeList,
  setMemberTree,
  updateMemberTree,
};
