import React, { useEffect } from "react";
import { useHookForm, usePop } from "../hooks";
import { useDispatch, useSelector } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { AssessmentAPI } from "../../axios/api";
import moment from "moment";

import ModulePopList from "./popList";
import { FormInputnumber, FormInputtextarea, FormRadio } from "../formElement";
import { option_season } from "../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

/*
  @params examData: 考核資料
  @params answerData: 被考核者資料
  @params categoryData: 考核項目資料
  @params managerData: 考核者資料
  @params footerTemplate: footer 樣板
  @params isPreview: 是否為預覽 (true/false)
  @params isManager: 是否為總經理身分 (true/false)
 */
export default function ModuleAssessmentForm({
  examData = {},
  answerData = {},
  categoryData = [],
  managerData = [],
  footerTemplate = <></>,
  isPreview = true,
  isManager = false,
  ...otherSetting
}) {
  const dispatch = useDispatch();
  const redux_user = useSelector((state) => state.user);
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "leaveWork",
      title: () => "請假記錄",
      isOpen: false,
    },
    {
      key: "attendance",
      title: () => "出缺勤記錄",
      isOpen: false,
    },
  ]);
  const serialIdx = ["一", "二", "三", "四", "五", "六", "七", "八", "九"];
  // 表單欄位預設值
  const defaultValues = { data: {}, remark: "", bonus: null };
  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    yup,
    setSchema,
    errors,
    setting,
    setIsFormUpdate,
  } = useHookForm({
    defaultValues,
  });

  useEffect(() => {
    setSchema(
      yup
        .object({
          bonus: yup
            .number()
            .test(
              "isValidPass",
              "必填",
              (value) => (isManager && (value || value === 0)) || !isManager
            )
            .nullable(),
          data: isManager
            ? null
            : yup.object().shape({
                ...categoryData.reduce(
                  (pre, cur) => ({
                    ...pre,
                    [`category_${cur.exam_itemid}`]: yup
                      .string()
                      .required("必填"),
                  }),
                  {}
                ),
              }),
        })
        .required()
    );
  }, [categoryData]);

  useEffect(() => {
    if (!answerData.data) return;

    if (isManager) {
      reset({
        bonus: answerData.data.bonus,
        remark: answerData.data.remark,
      });
    } else {
      let data = answerData.data.examResult[redux_user.userDetail.username];
      reset({
        data:
          data?.exam_results_details?.reduce(
            (pre, cur) => ({
              ...pre,
              [`category_${cur.exam_itemid}`]: `${cur.exam_contentid}_${cur.score}`,
            }),
            {}
          ) ?? {},
        remark: data?.remark ?? "",
      });
    }
  }, [answerData]);

  const onSubmit = (submitData) => {
    dispatch(setProgress(true));
    setIsFormUpdate(false);

    if (isManager) {
      // 總經理加分
      saveManager({ submitData });
    } else {
      // 特定評委評分
      saveRater({ submitData });
    }
  };

  const saveManager = ({ submitData }) => {
    dispatch(setProgress(true));

    let jsonData = {
      remark: submitData.remark,
      bonus: submitData.bonus,
    };
    if (answerData.data?.id) jsonData.id = answerData.data.id;

    AssessmentAPI.setExamResultBonus({
      data: jsonData,
    }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "變更成功",
            detail: "",
          })
        );
        otherSetting.closePrePop({ type: "assessment" });
        otherSetting.setPreReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: "變更失敗",
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  const saveRater = ({ submitData }) => {
    let oldFormData =
        answerData.data.examResult[redux_user.userDetail.username] ?? {},
      isAdd = !oldFormData.id,
      jsonData = {
        exam_exampleid: examData.id,
        rateder: answerData.member.username,
        remark: submitData.remark,
        total_score: calculateGetScore({
          type: "single",
          manager: formatManager().find(
            (item) => item.rater === redux_user.userDetail.username
          ),
        }),
      },
      answers = Object.entries(submitData.data).map(([key, val]) => ({
        exam_itemid: Number(key.split("category_")[1]),
        exam_contentid: val ? Number(val.split("_")[0]) : null,
        score: val ? Number(val.split("_")[1]) : null,
      }));
    if (isAdd) {
      jsonData.exam_results_details = answers;
    } else {
      jsonData.id = oldFormData.id;
      jsonData.exam_results_details = {
        insert: answers.filter(
          (newItem) =>
            !oldFormData.exam_results_details?.find(
              (oldItem) =>
                oldItem.exam_itemid === newItem.exam_itemid &&
                oldItem.exam_contentid === newItem.exam_contentid
            )
        ),
        update: [],
        delete:
          oldFormData.exam_results_details
            ?.filter(
              (newItem) =>
                !answers.find(
                  (oldItem) =>
                    oldItem.exam_itemid === newItem.exam_itemid &&
                    oldItem.exam_contentid === newItem.exam_contentid
                )
            )
            .map((item) => item.id) ?? [],
      };
    }

    let promise,
      toast = "";
    if (isAdd) {
      // 新增
      promise = AssessmentAPI.setExamResult({
        data: jsonData,
      });
      toast = "新增";
    } else {
      // 修改
      delete jsonData.username;
      jsonData.id = Number(oldFormData.id);
      promise = AssessmentAPI.updateExamResult({
        data: jsonData,
      });
      toast = "修改";
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: `${toast}成功`,
            detail: "",
          })
        );
        otherSetting.closePrePop({ type: "assessment" });
        otherSetting.setPreReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `${toast}失敗`,
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  const formatManager = () => managerData.slice(0, managerData.length) || [];

  // 計算滿分幾分
  const calculateFullScore = () =>
    categoryData.reduce(
      (pre, cur) => (pre += cur.examitem.content[0]?.score ?? 0),
      0
    );

  // 計算相同考核項目小計
  const calculateExamItemScore = ({ category }) => {
    let total = 0;
    managerData.forEach((manager) => {
      total +=
        (answerData.data?.exam_results_details_format?.[manager.rater]
          ?.details?.[category.examitem.id]?.score ?? 0) *
        (manager.weight / 100);
    });
    return Math.round(total * 100) / 100;
  };

  // 計算獲得分數
  const calculateGetScore = ({ type, manager = {} }) => {
    let isSelf =
      answerData.data?.exam_results_details_format?.[manager.rater]?.isSelf;
    let total = categoryData.reduce((pre, cur) => {
      if (type === "single" && isSelf && !isManager) {
        return (pre += Number(
          getValues(`data.category_${cur.examitem.id}`)?.split("_")[1] ?? 0
        ));
      } else if (type === "single") {
        return (pre += Number(
          answerData.data?.exam_results_details_format?.[manager.rater]
            ?.details?.[cur.examitem.id]?.score ?? 0
        ));
      } else if (type === "multi" && isManager) {
        return (pre += calculateExamItemScore({ category: cur }));
      }
    }, 0);
    return Math.round(total * 100) / 100;
  };

  const formatDateRange = () => {
    let nowYear = moment().format("YYYY"),
      nowMonth = Number(
        moment(redux_user.userDetail.server_datetime).format("MM")
      ),
      final = "";

    if (answerData.tab === "now") {
      if (nowMonth <= 3) final = `${nowYear}/01/01 - ${nowYear}/01/31`;
      else if (nowMonth <= 6) final = `${nowYear}/04/01 - ${nowYear}/04/30`;
      else if (nowMonth <= 9) final = `${nowYear}/07/01 - ${nowYear}/07/31`;
      else if (nowMonth <= 12) final = `${nowYear}/10/01 - ${nowYear}/10/31`;
    } else if (answerData.tab === "history") {
      if (answerData.data.season === 1)
        final = `${answerData.data.year}/04/01 - ${answerData.data.year}/04/30`;
      else if (answerData.data.season === 2)
        final = `${answerData.data.year}/07/01 - ${answerData.data.year}/07/31`;
      else if (answerData.data.season === 3)
        final = `${answerData.year}/10/01 - ${answerData.data.year}/10/31`;
      else if (answerData.data.season === 4)
        final = `${answerData.data.year + 1}/01/01 - ${
          answerData.data.year + 1
        }/01/31`;
    }
    return final;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <table className="w-full custom-tb">
          <thead>
            <tr>
              <th
                colSpan={
                  4 + (formatManager().length > 1 ? formatManager().length : 1)
                }
                className="text-3xl text-white bg-tb-title"
              >
                員工考核表
              </th>
              {isManager && (
                <th className="text-xl bg-tb-subtitle">評核等級</th>
              )}
              {isManager && formatManager().length > 1 && (
                <th style={{ padding: 0 }}></th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={2} className="bg-tb-subtitle">
                員工姓名
              </td>
              <td colSpan={2} className="w-50rem text-left">
                {answerData.member?.ch_last_name}
                {answerData.member?.ch_first_name}
              </td>
              {formatManager().map((manager, mIdx) => (
                <td key={`manager_${mIdx}`} className="w-6rem bg-tb-subtitle">
                  評核者
                </td>
              ))}
              {isManager && <td rowSpan={4} className="w-5rem"></td>}
            </tr>
            <tr>
              <td colSpan={2} className="bg-tb-subtitle">
                所屬單位
              </td>
              <td colSpan={2} className="text-left">
                {answerData.member?.department_name}
              </td>
              {formatManager().map((manager, mIdx) => (
                <td
                  key={`manager_${mIdx}`}
                >{`${manager.ch_last_name}${manager.ch_first_name}`}</td>
              ))}
            </tr>
            <tr>
              <td colSpan={2} className="bg-tb-subtitle">
                職稱
              </td>
              <td colSpan={2} className="text-left">
                {answerData.member?.jobtitle_name}
              </td>
              {formatManager().map((manager, mIdx) => (
                <td key={`manager_${mIdx}`} className="bg-tb-subtitle">
                  評核權重
                </td>
              ))}
            </tr>
            <tr>
              <td colSpan={2} className="bg-tb-subtitle">
                評核時區間
              </td>
              <td colSpan={2} className="text-left">
                {formatDateRange()}
              </td>
              {formatManager().map((manager, mIdx) => (
                <td key={`manager_${mIdx}`}>{manager.weight ?? 0}%</td>
              ))}
            </tr>
            <tr>
              <td className="w-2rem bg-tb-thirdtitle">項目</td>
              <td
                colSpan={2}
                className="text-3xl bg-tb-thirdtitle"
                style={{ wordSpacing: "1rem" }}
              >
                {`考　 核　 內　 容`}
              </td>
              <td className="w-3rem bg-tb-thirdtitle">最高分數</td>
              {formatManager().map((manager, mIdx) => (
                <td key={`manager_${mIdx}`} className="bg-tb-thirdtitle">
                  評分({serialIdx[mIdx]})
                </td>
              ))}
              {isManager && <td className="bg-tb-thirdtitle">平均分</td>}
            </tr>

            {categoryData.map((category, cIdx) => (
              <React.Fragment key={`category_${category.id}`}>
                {category.examitem.content.map((details, dIdx) => (
                  <tr
                    key={`category_${category.id}_${dIdx}`}
                    className={classNames({
                      "bg-tb-thirdtitle": cIdx % 2 !== 0,
                      "bg-tb-thirdtitle2": cIdx % 2 === 0,
                    })}
                  >
                    {dIdx === 0 && (
                      <td rowSpan={category.examitem.content.length}>
                        {category.examitem.name}
                      </td>
                    )}
                    <td colSpan={2} className="text-left">
                      {details.content}
                    </td>
                    <td>{details.score}</td>
                    {formatManager().map((manager, mIdx) => (
                      <td
                        key={`category_${category.id}_${dIdx}_${mIdx}`}
                        className={classNames("text-center", {
                          hidden:
                            (isManager ||
                              !answerData.data?.exam_results_details_format?.[
                                manager.rater
                              ]?.isSelf) &&
                            dIdx !== 0,
                        })}
                        rowSpan={
                          isManager ||
                          !answerData.data?.exam_results_details_format?.[
                            manager.rater
                          ]?.isSelf
                            ? category.examitem.content.length
                            : 1
                        }
                      >
                        {!isPreview &&
                          !isManager &&
                          !!answerData.data?.exam_results_details_format?.[
                            manager.rater
                          ]?.isSelf && (
                            <FormRadio
                              className="justify-content-center"
                              setting={setting}
                              data={{
                                name: `data.category_${category.examitem.id}`,
                                label: "",
                                value: `${details.id}_${details.score}`,
                                // disabled: !redux_user.userDetail?.isAssessment,
                              }}
                            />
                          )}
                        {(isManager ||
                          !answerData.data?.exam_results_details_format?.[
                            manager.rater
                          ]?.isSelf) && (
                          <>
                            {answerData.data?.exam_results_details_format?.[
                              manager.rater
                            ]?.details?.[category.examitem.id]?.score ?? 0}
                          </>
                        )}
                      </td>
                    ))}

                    {isManager && (
                      <>
                        {dIdx === 0 && (
                          <td rowSpan={category.examitem.content.length}>
                            {calculateExamItemScore({ category })}
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                ))}
              </React.Fragment>
            ))}

            {!isPreview && (
              <tr className="bg-tb-subtitle">
                <td colSpan={3}>評定總分</td>
                <td>{calculateFullScore()}</td>
                {formatManager().map((manager, mIdx) => (
                  <td key={`manager_${mIdx}`}>
                    {calculateGetScore({ type: "single", manager })}
                  </td>
                ))}

                {isManager && <td>{calculateGetScore({ type: "multi" })}</td>}
              </tr>
            )}
          </tbody>
        </table>

        {
          <div className="w-full my-3">
            <Button
              className="p-button-warning px-4 mr-2"
              type="button"
              label="出缺勤記錄"
              onClick={() =>
                openPop({
                  type: "attendance",
                  data: answerData.data.attendance_record,
                })
              }
            />

            <Button
              className="p-button-warning px-4"
              type="button"
              label="請假記錄"
              onClick={() =>
                openPop({
                  type: "leaveWork",
                  data: answerData.data.leave_work_record,
                })
              }
            />
          </div>
        }

        <div className="text-left mt-3">
          <div className="flex flex-column align-items-start sm:flex-row field mt-2">
            <label
              className={classNames(
                "text-left sm:text-right w-6rem mr-2 mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              其他分數
            </label>
            <div className="flex-auto w-full line-height-3 border-1 border-400 p-2">
              <div className="flex align-items-center">
                獎勵(記功)：
                <span
                  className={classNames("text-xl font-bold", {
                    "text-green-400": answerData.data?.award_score > 0,
                    "text-gray-500": answerData.data?.award_score <= 0,
                  })}
                >
                  {answerData.data?.award_score}
                </span>
              </div>
              <div className="flex align-items-center">
                懲罰(記過)：
                <span
                  className={classNames("text-xl font-bold", {
                    "text-red-400": answerData.data?.punish_score > 0,
                    "text-gray-500": answerData.data?.punish_score <= 0,
                  })}
                >
                  {answerData.data?.punish_score ? "-" : ""}
                  {answerData.data?.punish_score}
                </span>
              </div>
              <div className="flex align-items-center">
                出缺勤/請假：
                <span
                  className={classNames("text-xl font-bold", {
                    "text-red-400": answerData.data?.attendance_score > 0,
                    "text-gray-500": answerData.data?.attendance_score <= 0,
                  })}
                >
                  {answerData.data?.attendance_score ? "-" : ""}
                  {answerData.data?.attendance_score}
                </span>
              </div>
            </div>
          </div>
        </div>

        {!isManager && !isPreview && (
          <div className="flex flex-column align-items-start sm:flex-row field mt-2">
            <label
              htmlFor="remark"
              className={classNames(
                {
                  "p-error": errors.remark,
                },
                "text-left sm:text-right w-6rem mr-2 mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              備註
            </label>
            <div className="flex-auto w-full">
              <FormInputtextarea
                setting={setting}
                data={{
                  name: "remark",
                  // disabled: !redux_user.userDetail?.isAssessment,
                }}
              />
            </div>
          </div>
        )}

        {Object.values(
          answerData.data?.exam_results_details_format ?? {}
        ).filter((item) => !item.isSelf && item.remark) && (
          <>
            {Object.values(answerData.data?.exam_results_details_format ?? {})
              .filter((item) => (isManager || !item.isSelf) && item.remark)
              .map((item) => (
                <div
                  key={item.rater}
                  className="flex flex-column sm:flex-row field mt-2"
                >
                  <label
                    htmlFor="remark"
                    className={classNames(
                      "text-left sm:text-right w-6rem mr-2 mb-1 sm:mb-0 sm:mt-2"
                    )}
                  >
                    {`${
                      managerData.find(
                        (manager) => manager.rater === item.rater
                      )?.ch_last_name
                    }${
                      managerData.find(
                        (manager) => manager.rater === item.rater
                      )?.ch_first_name
                    }`}
                    <br />
                    備註
                  </label>
                  <div className="flex-auto w-full text-left white-space-prewrap bg-gray-200 border-1 border-400 p-2">
                    {item.remark}
                  </div>
                </div>
              ))}
          </>
        )}

        {isManager && (
          <div className="flex flex-column align-items-start sm:flex-row field mt-2">
            <label
              htmlFor="bonus"
              className={classNames(
                "text-left sm:text-right w-6rem mr-2 mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              加/扣分
            </label>
            <div className="flex-auto w-full">
              <FormInputnumber
                setting={setting}
                data={{
                  name: "bonus",
                  placeholder: "ex: +-10",
                  // disabled: !redux_user.userDetail?.isAssessment,
                }}
              />
            </div>
          </div>
        )}

        {footerTemplate}
      </form>

      {renderDialog({
        className: "w-11 lg:w-8 md:w-9 sm:w-10 max-w-40rem",
        children: (
          <>
            {(isPop.attendance?.isOpen || isPop.leaveWork?.isOpen) && (
              <ModulePopList prePopOption={popOption} closePrePop={closePop} />
            )}
          </>
        ),
      })}
    </>
  );
}
