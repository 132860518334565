export default {
  // 擅長工具
  getGoodAtToolsList: "BackEnd/getGoodAtToolsList",
  getGoodAtToolsDetails: "BackEnd/getGoodAtToolsDetails",
  setGoodAtTools: "BackEnd/setGoodAtTools",
  updateGoodAtTools: "BackEnd/updateGoodAtTools",

  // 語言
  getLanguagesList: "BackEnd/getLanguagesList",
  getLanguagesDetails: "BackEnd/getLanguagesDetails",
  setLanguages: "BackEnd/setLanguages",
  updateLanguages: "BackEnd/updateLanguages",

  // 部門
  getDepartmentTreeList: "BackEnd/getDepartmentTreeList",
  getDepartmentList: "BackEnd/getDepartmentList",
  setDepartmentTree: "BackEnd/setDepartmentTree",
  updateDepartmentTree: "BackEnd/updateDepartmentTree",

  // 職稱
  getJobTitleTreeList: "BackEnd/getJobTitleTreeList",
  getJobTitleList: "BackEnd/getJobTitleList",
  setJobTitleTree: "BackEnd/setJobTitleTree",
  updateJobTitleTree: "BackEnd/updateJobTitleTree",

  // 出缺勤/請假
  getAttendanceLeaveScoreType: "BackEnd/getAttendanceLeaveScoreType",
  getAttendanceLeaveScoreTypeDetails:
    "BackEnd/getAttendanceLeaveScoreTypeDetails",
  setAttendanceLeaveScoreType: "BackEnd/setAttendanceLeaveScoreType",
  updateAttendanceLeaveScoreType: "BackEnd/updateAttendanceLeaveScoreType",

  // 加解密測試
  edTest: "FrontEnd/edTest",
  deTest: "FrontEnd/deTest",
};
