import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import * as XLSXSTYLE from "xlsx-js-style";

const readFile = (file) => {
  return new Promise((reslove) => {
    let reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
      reslove(e.target?.result);
    };
  });
};

async function readExcelFile(file, sheetIndex) {
  let parse = await readFile(file);
  let workbook = XLSX.read(parse, { type: "binary" });
  let worksheet = workbook.Sheets[workbook.SheetNames[sheetIndex]];
  let data: any[] = XLSX.utils.sheet_to_json(worksheet, {
    defval: "",
    raw: false,
    cellText: true,
  });
  let keys: string[] = [];

  if (data.length > 0) {
    keys = Object.keys(data[0]);
  }

  return {
    keys: keys,
    data: data,
  };
}

export function exportFile(type: any, data: any[]) {
  let fileName = `template.${type}`;

  switch (type) {
    case "xlsx":
      fileExcel();
      break;
    case "xls":
      fileExcel();
      break;
    case "csv":
      fileExcel();
      break;
    case "txt":
      fileTxt();
      break;
    default:
      break;
  }

  function fileExcel() {
    let workSheet = XLSX.utils.aoa_to_sheet(data); // [[],[],...]
    // let jsonWorkSheet = XLSX.utils.json_to_sheet(data); // [{},{},...]

    let workBook = {
      SheetNames: ["sheet1"],
      Sheets: {
        sheet1: workSheet,
      },
    };

    XLSX.writeFile(workBook, fileName);
  }

  function fileTxt() {
    let arrayWorkSheet = XLSX.utils.aoa_to_sheet(data);
    let txtOutput = XLSX.utils.sheet_to_csv(arrayWorkSheet);

    let textFileAsBlob = new Blob([txtOutput], { type: "text/plain" });
    let href = window.webkitURL.createObjectURL(textFileAsBlob);

    download(href);
  }

  function download(href: any) {
    let downloadLink = document.createElement("a");
    downloadLink.download = fileName;
    downloadLink.innerHTML = "Download File";
    downloadLink.href = href;
    downloadLink.onclick = (e: any) => {
      document.body.removeChild(e.target);
    };
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);

    downloadLink.click();
  }
}

function datenum(v: any, date1904: any) {
  if (date1904) v += 1462;
  let epoch = Date.parse(v);
  return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

function Workbook() {
  if (!(this instanceof Workbook)) return new Workbook();
  this.SheetNames = [];
  this.Sheets = {};
}

function s2ab(s) {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);
  for (let i = 0; i < s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

function saveExcel(setting: any, callback) {
  let { filename, ws } = setting;

  let wb: any = new Workbook();

  /* add worksheet to workbook */
  ws.forEach((item) => {
    wb.SheetNames.push(item.name);
    wb.Sheets[item.name] = item.data;
  });

  let wbout = XLSXSTYLE.write(wb, {
    bookType: "xlsx",
    bookSST: true,
    type: "binary",
  });

  saveAs(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
    filename + ".xlsx"
  );

  setTimeout(() => {
    callback();
  }, 200);
}

function createXlsxColumn({ c, r }) {
  return XLSXSTYLE.utils.encode_cell({
    c: c,
    r: r,
  });
}

function createXlsxCellStyle({
  font_sz = 12,
  font_color = "000000",
  font_bold = false,
  wrapText = true,
  align_h = "left",
  fill_color = "ffffff",
  border_color = "000000",
  other = {},
} = {}) {
  return {
    font: {
      sz: font_sz,
      color: { rgb: font_color },
      bold: font_bold,
    },
    alignment: {
      horizontal: align_h,
      vertical: "center",
      wrapText: wrapText,
    },
    border: {
      top: { style: "thin", color: { rgb: border_color } },
      bottom: { style: "thin", color: { rgb: border_color } },
      left: { style: "thin", color: { rgb: border_color } },
      right: { style: "thin", color: { rgb: border_color } },
    },
    fill: {
      fgColor: {
        rgb: fill_color,
      },
    },
    ...other,
  };
}

// 檢查欄位是否存在
function checkColumnExist(columns, data) {
  let entries = Object.entries(columns),
    valid = true,
    errColumns = [];

  entries.forEach(([key, val]) => {
    if (data[val.title] === undefined) {
      valid = false;
      errColumns.push(val.title);
    }
  });

  return {
    valid: valid,
    errColumns: errColumns,
  };
}

// 檢查欄位是否有值
function checkValExist(columns, data) {
  let entries = Object.entries(columns),
    valid = true;

  entries.forEach(([key, val]) => {
    if (
      val.required &&
      (data[val.title] === undefined ||
        data[val.title] === null ||
        data[val.title] === "")
    ) {
      valid = false;
    }
  });

  return {
    valid: valid,
  };
}

export {
  readExcelFile,
  saveExcel,
  datenum,
  createXlsxColumn,
  createXlsxCellStyle,
  checkColumnExist,
  checkValExist,
};
