export default {
  // 考核項目&內容
  getExamItemsList: "BackEnd/getExamItemsList",
  getExamItemsDetails: "BackEnd/getExamItemsDetails",
  setExamItems: "BackEnd/setExamItems",
  updateExamItems: "BackEnd/updateExamItems",

  // 考核模板
  getTmpExamExamplesList: "BackEnd/getTmpExamExamplesList",
  getTmpExamExamplesDetails: "BackEnd/getTmpExamExamplesDetails",
  setTmpExamExamples: "BackEnd/setTmpExamExamples",
  updateTmpExamExamples: "BackEnd/updateTmpExamExamples",

  // 考核表
  getExamExamplesList: "BackEnd/getExamExamplesList",
  getExamExamplesDetails: "BackEnd/getExamExamplesDetails",
  setExamExamples: "BackEnd/setExamExamples",
  updateExamExamples: "BackEnd/updateExamExamples",

  // 考核的對象
  getMyExamList: "BackEnd/getMyExamList",
  getMyExamDetails: "BackEnd/getMyExamPersonData",
  setExamResult: "BackEnd/setExamResult",
  updateExamResult: "BackEnd/updateExamResult",

  // 歷史考核
  getHistoryExamList: "BackEnd/getHistoryExamData",
  getHistoryExamDetails: "BackEnd/getHistoryExamPersonData",

  // 可被加入被考核的人員
  getRaterList: "BackEnd/getRaterList",
  getRatederList: "BackEnd/getRatederList",

  // 總經理加成
  getAllExamList: "BackEnd/getAllExamList",
  getAllExamDetails: "BackEnd/getAllExamPersonData",
  getAllHistoryList: "BackEnd/getAllHistoryExamData",
  getAllHistoryExamDetails: "BackEnd/getAllHistoryExamPersonData",
  setExamResultBonus: "BackEnd/setExamResultBonus",
};
