import { useState } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { usePop } from "../hooks";

import { readExcelFile } from "../../service/xlsxStyle";

import { Button } from "primereact/button";

function useUploadXlsx({ action = {} } = {}) {
  const dispatch = useDispatch();
  const [uploadKey, setUploadKey] = useState(new Date());
  const [uploadData, setUploadData] = useState([]);
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "confirm",
      title: () => "確認",
      isOpen: false,
    },
  ]);

  const parseFile = (e) => {
    setUploadKey(new Date());

    let file = e.target.files[0];
    if (!file) {
      dispatch(
        setToast({
          severity: "error",
          summary: "未選擇檔案",
          detail: "",
        })
      );
      return;
    }

    readExcelFile(file, 0)
      .then(({ data }) => {
        document.getElementById("uploadFile").value = "";

        let result = action.format(data);
        if (result.isValid) {
          setUploadData(result.data);
          openPop({ type: "confirm" });
        } else {
          dispatch(
            setToast({
              severity: "error",
              sticky: true,
              summary: "資料錯誤",
              detail: result.message,
            })
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const confirmUpload = () => {
    dispatch(setProgress(true));

    action.upload({ data: uploadData }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "匯入成功",
            detail: "",
          })
        );
        closePop({ type: "confirm" });
        action.reload();
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: "匯入失敗",
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  const renderUpload = () => {
    return (
      <>
        <Button
          className="p-button-warning p-button-sm w-auto md:ml-auto ml-3 px-3"
          type="button"
          label="匯入"
          onClick={() => document.getElementById("uploadFile").click()}
        />
        <input
          id="uploadFile"
          type="file"
          name="file"
          className="hidden"
          accept=".xlsx,.xls"
          onChange={(e) => parseFile(e)}
        />

        {renderDialog({
          className: "w-11 md:w-10 lg:w-8 max-w-30rem",
          children: (
            <>
              {isPop.confirm?.isOpen && (
                <ModulePopConfirm
                  closePrePop={closePop}
                  confirmUpload={confirmUpload}
                />
              )}
            </>
          ),
        })}
      </>
    );
  };

  return { parseFile, renderUpload };
}

function ModulePopConfirm({ closePrePop, confirmUpload }) {
  return (
    <div className="text-center px-3 pb-3">
      <div className="inline-flex align-items-center justify-content-center w-5rem h-5rem text-6xl text-white bg-orange-500 border-circle">
        ！
      </div>
      <h1 className="mt-4 mb-3">確定上傳?</h1>
      <div className="w-full">
        <Button
          className="p-button-secondary px-4"
          type="button"
          label="取消"
          onClick={() => closePrePop({ type: "confirm" })}
        />
        <Button
          className="ml-4 px-4"
          type="button"
          label="確定"
          onClick={() => confirmUpload()}
        />
      </div>
    </div>
  );
}

export default useUploadXlsx;
