export default {
  // 獎勵
  getAwardRecordsList: "BackEnd/getAwardRecordsList",
  getAwardRecordsDetails: "BackEnd/getAwardRecordsDetails",
  setAwardRecords: "BackEnd/setAwardRecords",
  updateAwardRecords: "BackEnd/updateAwardRecords",
  deleteAwardRecords: "BackEnd/deleteAwardRecords",
  awardRecordsFileUpload: "BackEnd/awardRecordsFileUpload",
  awardRecordsDeleteFile: "BackEnd/awardRecordsDeleteFile",

  // 懲罰
  getPunishRecordsList: "BackEnd/getPunishRecordsList",
  getPunishRecordsDetails: "BackEnd/getPunishRecordsDetails",
  setPunishRecords: "BackEnd/setPunishRecords",
  updatePunishRecords: "BackEnd/updatePunishRecords",
  deletePunishRecords: "BackEnd/deletePunishRecords",
  punishRecordsFileUpload: "BackEnd/punishRecordsFileUpload",
  punishRecordsDeleteFile: "BackEnd/punishRecordsDeleteFile",
};
