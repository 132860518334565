import { useState, useEffect, useMemo } from "react";
import { useFilterList } from "../../../../../components/hooks";

import { FormCheckbox } from "../../../../../components/formElement";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

export default function ModuleProfileGoodAtTool({
  options: { setting, getValues, disabled, optionData },
}) {
  const { getFilterListData } = useFilterList();
  const [goodAtTool, setGoodAtTool] = useState({
    term: "",
    data: null,
  });

  const listData = useMemo(
    () =>
      getFilterListData({
        data: goodAtTool.data,
        filterKey: "name",
        serachTerm: goodAtTool.term,
      }) || [],
    [goodAtTool]
  );

  useEffect(() => {
    if (!optionData) return;

    setGoodAtTool((state) => ({
      ...state,
      data: optionData.goodAtTool,
    }));
  }, [optionData]);

  return (
    <>
      <div className="flex align-items-center mb-1">
        <InputText
          value={goodAtTool.term}
          onChange={(e) =>
            setGoodAtTool((state) => ({ ...state, term: e.target.value }))
          }
        />
        <Button
          type="button"
          icon="pi pi-refresh"
          className="p-button-secondary p-button-sm ml-2"
          onClick={() => setGoodAtTool((state) => ({ ...state, term: "" }))}
        />
      </div>
      <div className="flex flex-wrap align-items-start border-1 border-400 min-h-5rem p-1">
        {(goodAtTool.data || []).length === 0 && goodAtTool.data !== null && (
          <div className="text-red-400 mt-1">無資料</div>
        )}
        {listData.map((item, index) => (
          <div
            key={`goodAtTool_${item.id}`}
            className="inline-flex align-items-center w-9rem mr-3"
          >
            <FormCheckbox
              className="flex justify-content-start m-0"
              setting={setting}
              data={{
                name: "good_at_tool",
                label: item.name,
                value: item.id,
                disabled: disabled,
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
}
