import { system_dropdown } from "../../service/system";

import { classNames } from "primereact/utils";
import { TreeSelect } from "primereact/treeselect";

export default function FormTreeSelect(props) {
  const {
    className,
    setting: { Controller, control, trigger, isSubmitted, isEdit = true },
    data: {
      name,
      options = [],
      placeholder = "",
      filter = false,
      disabled = false,
    },
  } = props;

  const onChange = (e, field) => {
    field.onChange(e.value === undefined ? null : e.value);
    if (isSubmitted) {
      trigger();
    } else {
      trigger(field.name);
    }
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <TreeSelect
            id={field.name}
            className={classNames(
              { "p-invalid": fieldState.invalid },
              "w-full",
              className
            )}
            {...field}
            options={options}
            onChange={(e) => onChange(e, field)}
            placeholder={placeholder}
            filter={filter}
            disabled={!isEdit || disabled}
          ></TreeSelect>
        )}
      />
    </>
  );
}
