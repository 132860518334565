import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import {
  axios,
  AxiosMulti,
  formatApiResult,
} from "../../../../axios/request/common";
import { AssessmentAPI } from "../../../../axios/api";
import {
  useAbortedEffect,
  useHookForm,
  usePop,
} from "../../../../components/hooks";
import moment from "moment";

import ModuleBreadCrumb from "../../../../components/breadCrumb";
import ModuleTemplateForm from "../../../../components/common/templateForm";
import ModulePopTemplateConfirm from "./popConfirm";
import {
  FormInputtext,
  FormDropdown,
  FormInputtextarea,
} from "../../../../components/formElement/index";
import {
  formatResData,
  checkFormUpdate,
  formatOptions,
} from "../../../../service/common";
import { option_enabled } from "../../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function BackAssessmentTemplateDetail() {
  const route_params = useParams();
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbItem = [
    {
      key: 1,
      label: "考核模板管理",
      command: () => route_navigate("/back/assessment/template"),
    },
    { key: 2, label: "編輯" },
  ];
  const [reload, setReload] = useState({
    list: true,
  });
  const disabled = false;
  // 明細資料
  const [apiData, setApiData] = useState({});
  // 表單欄位預設值
  const defaultValues = {
    name: "",
    remark: "",
    category: [],
  };
  const {
    handleSubmit,
    getValues,
    reset,
    trigger,
    yup,
    setSchema,
    errors,
    setting,
    setIsFormUpdate,
  } = useHookForm({
    defaultValues,
  });
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "confirm",
      title: () => "確認",
      isOpen: false,
    },
  ]);
  const [optionData, setOptionData] = useState({});

  useEffect(() => {
    setSchema(
      yup
        .object({
          name: yup.string().required("必填"),
          remark: yup.string(),
          category: yup
            .array()
            .test("isValidPass", "必填", (value) => value.length > 0),
        })
        .required()
    );
  }, []);

  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      let isAdd = route_params.detailid === "add",
        params = { id: route_params.detailid },
        promise = [
          AssessmentAPI.getExamItemsList({
            data: { page: -1, status: 1 },
            options: { signal },
          }),
        ];
      if (!isAdd)
        promise.push(
          AssessmentAPI.getTmpExamExamplesDetails({
            data: params,
            options: { signal },
          })
        );

      AxiosMulti(promise).then(
        axios.spread((result_category = {}, result_detail = {}) => {
          let tempOptions = {};

          // 考核項目
          let { data: result_category_data } = result_category;
          if (result_category.Response === 1) {
            tempOptions = {
              ...tempOptions,
              ...formatOptions(
                { category: result_category_data },
                { uniqueKey: "id", showLabel: "name" }
              ),
            };
          }

          // 明細資料
          let { data: result_detail_data } = result_detail;
          if (result_detail.Response === 1) {
            result_detail_data.category =
              result_detail_data.tmp_exam_example_item_setting.map(
                (item) => item.exam_itemid
              );
            let temp = formatResData(defaultValues, result_detail_data);
            setApiData({ ...result_detail_data, ...temp });
            reset(temp);
          }

          let isError = formatApiResult({
            data: [result_category, result_detail],
            action: (message) => {
              dispatch(
                setToast({
                  severity: "error",
                  summary: message,
                  detail: "",
                })
              );
            },
          });
          if (isError) {
            dispatch(setProgress(false));
            return;
          }

          setOptionData(tempOptions);
          setReload((state) => ({ ...state, list: false }));
          dispatch(setProgress(false));
        })
      );
    },
    [reload]
  );

  const onSubmit = () => {
    let score = calculateCategoryScore();
    if (score < 100) {
      openPop({
        type: "confirm",
        data: {
          type: "template",
          score,
        },
      });
    } else saveData();
  };

  const saveData = () => {
    dispatch(setProgress(true));
    setIsFormUpdate(false);

    let submitData = getValues(),
      isAdd = route_params.detailid === "add",
      jsonData = {
        ...submitData,
      };

    if (!checkFormUpdate(jsonData, apiData)) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      route_navigate("/back/assessment/template");
      return;
    }

    if (isAdd) {
      jsonData.tmp_exam_example_item_setting = jsonData.category.map(
        (item) => ({
          exam_itemid: item,
        })
      );
    } else {
      jsonData.tmp_exam_example_item_setting = {
        insert: jsonData.category
          .filter((item) => !apiData.category.includes(item))
          .map((item) => ({
            exam_itemid: item,
          })),
        update: [],
        delete: apiData.tmp_exam_example_item_setting
          .filter((item) => !jsonData.category.includes(item.exam_itemid))
          .map((item) => item.id),
      };
    }

    delete jsonData.category;

    let promise,
      toast = "";
    if (isAdd) {
      // 新增
      promise = AssessmentAPI.setTmpExamExamples({
        data: jsonData,
      });
      toast = "新增";
    } else {
      // 修改
      delete jsonData.username;
      jsonData.id = Number(route_params.detailid);
      promise = AssessmentAPI.updateTmpExamExamples({
        data: jsonData,
      });
      toast = "修改";
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: `${toast}成功`,
            detail: "",
          })
        );
        route_navigate("/back/assessment/template");
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `${toast}失敗`,
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 計算考核項目總分
  const calculateCategoryScore = () => {
    return (getValues("category") || [])
      .map((categoryid) => {
        let category = optionData.category_obj[categoryid] ?? {};
        let maxScore = Math.max(
          ...(category.content || []).map((item) => item.score)
        );
        return maxScore;
      })
      .reduce((pre, cur) => (pre += cur), 0);
  };

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <div className="border-round-lg bg-white mt-3 p-3">
        <h2 className="mt-2 mb-4 ml-2">考核模板管理 - 編輯</h2>

        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid">
          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="name"
              className={classNames(
                { "p-error": errors.name },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>模板名稱
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputtext
                setting={setting}
                data={{
                  name: "name",
                  disabled: disabled,
                }}
              />
            </div>
          </div>

          <ModuleTemplateForm
            moduleType="template"
            setting={setting}
            errors={errors}
            getValues={getValues}
            disabled={disabled}
            optionData={optionData}
            calculateCategoryScore={calculateCategoryScore}
          />

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              htmlFor="remark"
              className={classNames(
                { "p-error": errors.remark },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              備註
            </label>
            <div className="col-12 sm:col-10 md:col-8">
              <FormInputtextarea
                setting={setting}
                data={{
                  name: "remark",
                  disabled: disabled,
                }}
              />
            </div>
          </div>

          <div className="field col-12 text-right">
            <Button
              type="button"
              label="取消"
              className="p-button-secondary p-button-outlined ml-auto px-3"
              onClick={() => route_navigate("/back/assessment/template")}
            />
            {!disabled && (
              <Button type="submit" label="儲存" className="ml-2 px-3" />
            )}
          </div>
        </form>
      </div>

      {renderDialog({
        className: "w-11 lg:w-8 md:w-9 sm:w-10 max-w-30rem",
        children: (
          <>
            {isPop.confirm?.isOpen && (
              <ModulePopTemplateConfirm
                prePopOption={popOption}
                closePrePop={closePop}
                saveData={saveData}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
