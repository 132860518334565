import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { AssessmentAPI } from "../../../../axios/api";
import {
  useAbortedEffect,
  useHookForm,
  useMultiRecords,
} from "../../../../components/hooks";
import moment from "moment";

import ModuleBreadCrumb from "../../../../components/breadCrumb";
import {
  FormInputtext,
  FormInputnumber,
  FormDropdown,
} from "../../../../components/formElement/index";
import { formatResData, checkFormUpdate } from "../../../../service/common";
import { option_enabled } from "../../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function BackAssessmentCategoryDetail() {
  const route_params = useParams();
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbItem = [
    {
      key: 1,
      label: "考核項目管理",
      command: () => route_navigate("/back/assessment/category"),
    },
    { key: 2, label: "編輯" },
  ];
  const [reload, setReload] = useState({
    list: true,
  });
  const disabled = false;
  // 明細資料
  const [apiData, setApiData] = useState({});
  // 表單欄位預設值
  const defaultValues = {
    name: "",
    status: 1,
    data: [],
  };
  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    yup,
    setSchema,
    errors,
    setting,
    setIsFormUpdate,
  } = useHookForm({
    defaultValues,
  });
  const {
    addDetailBlock,
    deleteMulti,
    replayMulti,
    formatMultiRecords,
    renderMultiRecordsTb,
  } = useMultiRecords({
    blockData: {
      data: {
        content: "",
        score: null,
      },
    },
    updateForm: ({ name, data }) => {
      setValue(name, data);
      trigger(name);
      setIsFormUpdate(true);
    },
  });

  useEffect(() => {
    setSchema(
      yup
        .object({
          name: yup.string().required("必填"),
          data: yup.array().of(
            yup.object().shape({
              content: yup
                .string()
                .test(
                  "isValidPass",
                  "必填",
                  (value, { from: [{ value: data }] }) =>
                    value || data.flag === "delete"
                ),
              score: yup
                .number()
                .test(
                  "isValidPass",
                  "必填",
                  (value, { from: [{ value: data }] }) =>
                    value || data.flag === "delete"
                )
                .nullable(),
            })
          ),
        })
        .required()
    );
  }, []);

  useAbortedEffect(
    (signal) => {
      if (!reload.list || route_params.detailid === "add") {
        return;
      }

      dispatch(setProgress(true));

      let params = { id: route_params.detailid };

      AssessmentAPI.getExamItemsDetails({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, message } = result;
        if (Response === 1) {
          data.data = data.content;
          let temp = formatResData(defaultValues, data);
          setApiData(temp);
          reset(temp);
          setReload((state) => ({ ...state, list: false }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload]
  );

  const onSubmit = (submitData) => {
    dispatch(setProgress(true));
    setIsFormUpdate(false);

    let isAdd = route_params.detailid === "add",
      jsonData = {
        ...submitData,
        data: formatMultiRecords({
          data: submitData.data,
          isAdd,
        }),
      };

    if (!checkFormUpdate(jsonData, apiData)) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      route_navigate("/back/assessment/category");
      return;
    }

    let promise,
      toast = "";
    if (isAdd) {
      // 新增
      promise = AssessmentAPI.setExamItems({
        data: jsonData,
      });
      toast = "新增";
    } else {
      // 修改
      delete jsonData.username;
      jsonData.id = Number(route_params.detailid);
      promise = AssessmentAPI.updateExamItems({
        data: jsonData,
      });
      toast = "修改";
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: `${toast}成功`,
            detail: "",
          })
        );
        route_navigate("/back/assessment/category");
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `${toast}失敗`,
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <div className="border-round-lg bg-white mt-3 p-3">
        <h2 className="mt-2 mb-4 ml-2">考核項目管理 - 編輯</h2>

        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid">
          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="name"
              className={classNames(
                { "p-error": errors.name },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>項目名稱
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputtext
                setting={setting}
                data={{
                  name: "name",
                  disabled: disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="status"
              className={classNames(
                { "p-error": errors.status },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>啟用狀態
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormDropdown
                setting={setting}
                data={{
                  name: "status",
                  options: option_enabled,
                  optionLabel: "label",
                  optionValue: "value",
                  showClear: false,
                  disabled: disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              htmlFor="data"
              className={classNames(
                { "p-error": errors.data },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              內容
            </label>
            <div className="col-12 sm:col-10">
              {renderMultiRecordsTb({
                columnLength: 3,
                data: getValues("data") || [],
                columnKey: "data",
                headTemplate: (
                  <>
                    <tr className="bg-tb-subtitle">
                      <th>名稱</th>
                      <th className="w-6rem">分數</th>
                      <th className="w-4rem">動作</th>
                    </tr>
                  </>
                ),
                bodyTemplate: ({ data, rowIndex }) => (
                  <>
                    <td>
                      <FormInputtext
                        setting={setting}
                        data={{
                          name: `data[${rowIndex}].content`,
                          disabled: disabled || data.flag === "delete",
                        }}
                      />
                    </td>
                    <td className="text-center">
                      <FormInputnumber
                        setting={setting}
                        data={{
                          name: `data[${rowIndex}].score`,
                          disabled: disabled || data.flag === "delete",
                        }}
                      />
                    </td>
                  </>
                ),
              })}
            </div>
          </div>

          <div className="field col-12 text-right">
            <Button
              type="button"
              label="取消"
              className="p-button-secondary p-button-outlined ml-auto px-3"
              onClick={() => route_navigate("/back/assessment/category")}
            />
            {!disabled && (
              <Button type="submit" label="儲存" className="ml-2 px-3" />
            )}
          </div>
        </form>
      </div>
    </>
  );
}
