import { useMultiRecords } from "../../../../../components/hooks";

import {
  FormInputtext,
  FormDropdown,
} from "../../../../../components/formElement";
import {
  option_language_type,
  option_language_level,
} from "../../../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function ModuleProfileLanguageLevel({
  options: {
    setting,
    getValues,
    setValue,
    trigger,
    setIsFormUpdate,
    disabled,
    optionData,
  },
}) {
  const { addDetailBlock, deleteMulti, replayMulti, renderMultiRecordsTb } =
    useMultiRecords({
      blockData: {
        language_level: {
          languageid: null,
          type: null,
          level: null,
          remark: "",
        },
      },
      updateForm: ({ name, data }) => {
        setValue(name, data);
        trigger(name);
        setIsFormUpdate(true);
      },
    });

  return (
    <>
      {renderMultiRecordsTb({
        columnLength: 2,
        data: getValues("language_level") || [],
        columnKey: "language_level",
        headTemplate: (
          <>
            <tr className="bg-tb-subtitle">
              <th>語言能力</th>
              <th className="w-4rem">動作</th>
            </tr>
          </>
        ),
        bodyTemplate: ({ data, rowIndex }) => (
          <>
            <td>
              <div className="formgrid grid">
                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    語言
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormDropdown
                      setting={setting}
                      data={{
                        name: `language_level[${rowIndex}].languageid`,
                        options: optionData.language,
                        optionLabel: "name",
                        optionValue: "id",
                        showClear: false,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    類型
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormDropdown
                      setting={setting}
                      data={{
                        name: `language_level[${rowIndex}].type`,
                        options: option_language_type,
                        optionLabel: "label",
                        optionValue: "value",
                        showClear: false,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    等級
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormDropdown
                      setting={setting}
                      data={{
                        name: `language_level[${rowIndex}].level`,
                        options: option_language_level,
                        optionLabel: "label",
                        optionValue: "value",
                        showClear: false,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    備註
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormInputtext
                      setting={setting}
                      data={{
                        name: `language_level[${rowIndex}].remark`,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>
              </div>
            </td>
          </>
        ),
      })}
    </>
  );
}
