import { createBrowserRouter, RouterProvider } from "react-router-dom";

import BackLogin from "../page/login";

import BackRoutes from "./back";

export default function CreatRoute() {
  const router = createBrowserRouter([
    {
      path: "",
      element: <BackLogin />,
    },
    {
      path: "/login",
      element: <BackLogin />,
    },
    ...BackRoutes.routes,
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}
