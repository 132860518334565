import { useState, useEffect } from "react";
import { useAbortedEffect, useHookForm } from "../../../../components/hooks";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { MemberAPI, RecordAPI } from "../../../../axios/api";
import moment from "moment";

import ModuleUploadFile from "../../../../components/uploadFile";
import { FormDropdown, FormCalendar } from "../../../../components/formElement";
import { formatResData, checkFormUpdate } from "../../../../service/common";
import {
  axios,
  AxiosMulti,
  formatApiResult,
} from "../../../../axios/request/common";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function ModulePopRecordPunishForm({
  prePopOption,
  closePrePop,
  setReload,
  optionData,
}) {
  const dispatch = useDispatch();
  // 表單欄位預設值
  const defaultValues = {
    username: null,
    type: null,
    date: null,
    files: "",
    fileList: [],
    file_path: "",
  };
  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    yup,
    setSchema,
    errors,
    setting,
    setIsFormUpdate,
  } = useHookForm({
    defaultValues,
  });
  const [memberData, setMemberData] = useState([]);
  const [uploadData, setUploadData] = useState([]);

  useEffect(() => {
    setSchema(
      yup
        .object({
          username: yup.string().required("必填").nullable(),
          type: yup.number().required("必填").nullable(),
          date: yup.string().required("必填").nullable(),
        })
        .required()
    );
  }, []);

  useAbortedEffect(
    (signal) => {
      dispatch(setProgress(true));

      let params = {
          list: { page: -1 },
          detail: { id: prePopOption.data?.id, detail: 1 },
        },
        promise = [
          MemberAPI.getMemberDetailsList({
            data: params.list,
            options: { signal },
          }),
        ];

      if (prePopOption.data?.id) {
        promise.push(
          RecordAPI.getPunishRecordsDetails({
            data: params.detail,
            options: { signal },
          })
        );
      }

      AxiosMulti(promise).then(
        axios.spread((result_memberlist = {}, result_detail = {}) => {
          let tempOptions = {};

          // 員工清單
          let { data: result_memberlist_data } = result_memberlist;
          if (result_memberlist.Response === 1) {
            result_memberlist_data = result_memberlist_data.map((item) => ({
              ...item,
              name_format: `${item.ch_last_name ?? ""}${
                item.ch_first_name ?? ""
              }(${item.username})`,
            }));
            setMemberData(result_memberlist_data);
          }

          // 明細資料
          let { data: result_detail_data } = result_detail;
          if (result_detail.Response === 1) {
            let temp = formatResData(defaultValues, result_detail_data);
            reset(temp);
          }

          let isError = formatApiResult({
            data: [result_memberlist, result_detail],
            action: (message) => {
              dispatch(
                setToast({
                  severity: "error",
                  summary: message,
                  detail: "",
                })
              );
            },
          });
          if (isError) {
            dispatch(setProgress(false));
            return;
          }

          setReload((state) => ({ ...state, list: false }));
          dispatch(setProgress(false));
        })
      );
    },
    [prePopOption]
  );

  const onSubmit = (submitData) => {
    dispatch(setProgress(true));
    setIsFormUpdate(false);

    let isAdd = !prePopOption.data,
      jsonData = formatResData(defaultValues, submitData);
    jsonData.date = moment(jsonData.date).format("YYYY-MM-DD");

    if (
      !checkFormUpdate(jsonData, prePopOption.data ?? {}) &&
      uploadData.length === 0
    ) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      closePrePop({ type: "form" });
      return;
    }

    let promise,
      toast = "";
    if (isAdd) {
      // 新增
      promise = RecordAPI.setPunishRecords({
        data: jsonData,
      });
      toast = "新增";
    } else {
      // 修改
      jsonData.id = prePopOption.data.id;
      promise = RecordAPI.updatePunishRecords({
        data: jsonData,
      });
      toast = "修改";
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        if (uploadData.length > 0) {
          saveFile({ toast });
        } else {
          successAction({ toast });
        }
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `${toast}失敗`,
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  const saveFile = ({ toast }) => {
    let isAdd = false,
      addCount = 0,
      isDelete = false,
      formData = new FormData(),
      delete_path_List = [];
    uploadData.forEach((item, index) => {
      if (!item.isDelete && item.file) {
        isAdd = true;
        formData.append(`punish_file${addCount}`, item.file);
        addCount++;
      } else if (item.isDelete && !item.file) {
        isDelete = true;
        delete_path_List.push(item.raw);
      }
    });

    if (!isAdd && !isDelete) {
      successAction({ toast });
      return;
    }

    let promise = [];
    if (isAdd) {
      formData.append("id", Number(prePopOption.data.id));
      formData.append("typename", "punish_file");
      promise.push(
        RecordAPI.punishRecordsFileUpload({
          data: formData,
        })
      );
    }
    if (isDelete) {
      promise.push(
        RecordAPI.punishRecordsDeleteFile({
          data: {
            delete_path_List,
          },
        })
      );
    }

    AxiosMulti(promise).then((result1 = {}, result2 = {}) => {
      let { Response1 = 1 } = result1;
      let { Response2 = 1 } = result2;

      if (Response1 === 1 && Response2 === 1) {
        successAction({ toast });
        return;
      }

      dispatch(
        setToast({
          severity: "error",
          summary: "附檔儲存失敗",
          detail: "",
        })
      );
      dispatch(setProgress(false));
    });
  };

  const successAction = ({ toast }) => {
    dispatch(
      setToast({
        severity: "success",
        summary: `${toast}成功`,
        detail: "",
      })
    );
    closePrePop({ type: "form" });
    setReload((state) => ({ ...state, list: true }));
  };

  return (
    <>
      <div className="px-4">
        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid">
          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="username"
              className={classNames(
                { "p-error": errors.username },
                "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              員工名稱
            </label>
            <div className="col-12 sm:col-9">
              <FormDropdown
                setting={setting}
                data={{
                  name: "username",
                  options: memberData || [],
                  optionLabel: "name_format",
                  optionValue: "username",
                  filter: true,
                  filterBy: "name_format",
                  showClear: false,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="type"
              className={classNames(
                { "p-error": errors.type },
                "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              懲罰類型
            </label>
            <div className="col-12 sm:col-9">
              <FormDropdown
                setting={setting}
                data={{
                  name: "type",
                  options: optionData.punish || [],
                  showClear: false,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="date"
              className={classNames(
                { "p-error": errors.date },
                "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              懲罰日期
            </label>
            <div className="col-12 sm:col-9">
              <FormCalendar
                setting={setting}
                data={{
                  name: "date",
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              className={classNames(
                "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              附檔
            </label>
            <div className="col-12 sm:col-9">
              <ModuleUploadFile
                iptId="pop"
                isMulti={false}
                zipName={getValues("name")}
                pathSecret={getValues("file_path")}
                pathPrefix={getValues("files")}
                data={getValues("fileList") ?? []}
                setData={setUploadData}
              />
            </div>
          </div>

          <div className="col-12 text-right">
            <Button
              type="button"
              label="取消"
              className="p-button-secondary p-button-outlined ml-auto px-3"
              onClick={() => closePrePop({ type: "form" })}
            />
            <Button type="submit" label="儲存" className="ml-2 px-3" />
          </div>
        </form>
      </div>
    </>
  );
}
