import { useEffect, useState } from "react";
import { useLeavePageConfirm } from "../hooks";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const useHookForm = ({ defaultValues = {} } = {}) => {
  // 表單欄位驗證條件
  const [schema, setSchema] = useState(yup.object({}).required());
  const {
    control, // 將元件註冊至react-hook-form的方法
    // setFocus,
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
    watch,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });
  // 使否變更
  const [isFormUpdate, setIsFormUpdate] = useState(false);

  useLeavePageConfirm({ isFormUpdate, setIsFormUpdate });

  // useEffect(() => {
  //   try {
  //     const firstError = Object.keys(errors).reduce((field, a) => {
  //       return !!errors[field] ? field : a;
  //     }, null);

  //     console.log(firstError);
  //     if (firstError) {
  //       setFocus(firstError);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, [errors, setFocus]);

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  return {
    Controller,
    isSubmitted,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    trigger,
    watch,
    yup,
    setSchema,
    errors,
    setting,
    isFormUpdate,
    setIsFormUpdate,
  };
};

export default useHookForm;
