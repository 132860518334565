import { useState, useEffect } from "react";

import { ConfirmPopup } from "primereact/confirmpopup";

const useConfirm = (setting = {}) => {
  // 確認資訊
  const [confirmOption, setConfirmOption] = useState({
    setting: {},
    reOpen: false,
  });

  useEffect(() => {
    if (confirmOption.reOpen) {
      setConfirmOption((state) => ({
        setting: {
          ...state.setting,
          visible: true,
        },
        reOpen: false,
      }));
    }
  }, [confirmOption]);

  const renderConfirm = (
    <ConfirmPopup
      {...confirmOption.setting}
      onHide={() =>
        setConfirmOption((state) => ({
          setting: {
            ...state.setting,
            visible: false,
          },
          reOpen: false,
        }))
      }
    />
  );

  return {
    confirmOption,
    setConfirmOption,
    renderConfirm,
  };
};

export default useConfirm;
