import { usePop } from "../../../../components/hooks";
import moment from "moment";

import ModulePopSign from "./popSign";
import { ModulePdfView } from "../../../../components/exportPdf";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function ModuleSignOnline({
  prePopOption,
  closePrePop,
  closeMainPop,
  optionData,
  setPreReload,
}) {
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "sign",
      title: () => "簽署",
      isOpen: false,
    },
  ]);

  return (
    <>
      <div>
        {/* <ModulePdfView optionData={optionData} memberData={prePopOption.data} /> */}
        <ModuleContract
          optionData={optionData}
          memberData={prePopOption.data}
        />

        <div className="text-center mt-3 pb-3">
          <Button
            className="p-button-main px-4 mr-4"
            type="button"
            label="簽署"
            onClick={() =>
              openPop({
                type: "sign",
                data: {
                  ...optionData,
                  form: prePopOption.data,
                },
              })
            }
          />
        </div>
      </div>

      {renderDialog({
        className: "w-40rem",
        children: (
          <>
            {isPop.sign?.isOpen && (
              <div className="h-full px-3">
                <ModulePopSign
                  prePopOption={popOption}
                  closePrePop={closePop}
                  closeSubPop={closePrePop}
                  closeMainPop={closeMainPop}
                  setPreReload={setPreReload}
                />
              </div>
            )}
          </>
        ),
      })}
    </>
  );
}

function ModuleContract({ optionData, memberData }) {
  const square = ({ isFill = false, isOpacity = false } = {}) => (
    <div
      className={classNames("flex-shrink-0 w-1rem h-1rem border-1", {
        "bg-gray-900": isFill,
        "opacity-0": isOpacity,
      })}
    ></div>
  );

  const createTitle = ({ text }) => (
    <div className="font-bold ml-3">{text}</div>
  );

  const createContent = ({ text }) => <div className="ml-5">{text}</div>;

  const createHead = () => (
    <>
      <div className="text-2xl text-center font-bold">勞　動　契　約</div>
      <div className="flex flex-wrap align-items-center word-break">
        <div>立契約書人：</div>
        <div>
          <div>倍昌股份有限公司（以下簡稱甲方）</div>
          <div>
            <span className="text-blue-400 font-bold">
              {memberData.ch_last_name + memberData.ch_first_name}
            </span>
            （以下簡稱乙方）
          </div>
        </div>
        <div>，</div>
        <div>為使權利義務明確，雙方同意訂立本契約，共同遵守下列條款：</div>
      </div>
    </>
  );

  const createFoot = () => (
    <>
      <div className="ml-3 mt-6">
        <div>立契約人</div>
        <div>甲方：倍昌股份有限公司（統一編號：44992464）</div>
        <div>代表人：黃金波</div>
        <div>地址：桃園市平鎮區工業十一路一號</div>

        <div className="text-blue-400 font-bold mt-3">
          <div>乙方：{memberData.ch_last_name + memberData.ch_first_name}</div>
          <div>身分證字號：{memberData.id_number}</div>
          <div>地址：{memberData.contact_address}</div>
        </div>

        <div className="text-center text-blue-400 font-bold mt-3">{`中華民國 ${
          Number(moment().format("YYYY")) - 1911
        } 年 ${moment().format("MM")} 月 ${moment().format("DD")} 日`}</div>

        <div className="text-center mt-4">工作規則，閱後同意書</div>
        <div>{`　　本人已詳細閱讀由公司所提供業經桃園市政府核備通過之工作規則，瞭解雙方應有之權益與應負之義務，並願遵守該工作規則內所規定之各項行為規範與作業流程。`}</div>
        <div>{`　　此 致`}</div>
        <div>倍昌股份有限公司</div>
      </div>
    </>
  );

  const createFirstRule = () => (
    <>
      <div className="ml-3">
        <div className="flex align-items-center">
          {square({ isOpacity: false })}
          <div className="ml-1">新進全職員工</div>
        </div>
        <div className="flex align-items-center">
          {square({ isOpacity: true })}
          <div className="ml-1">
            試用期間自簽訂日起至民國___年___月___日止。工作時間詳如第二條規定。
          </div>
        </div>

        <div className="flex align-items-center">
          {square({ isOpacity: false })}
          <div className="ml-1">新進部分工時員工（兼職時薪員工）</div>
        </div>
        <div className="flex align-items-center">
          {square({ isOpacity: true })}
          <div className="ml-1">
            乙方為時薪部分工時人員，試用期間自簽訂日起至民國___年___月___日止。工作時間詳如第二條規定為時薪部分工時人員，試用期間自簽訂日起至民國___年___月___日止。
          </div>
        </div>

        <div className="flex align-items-center">
          {square({ isOpacity: false })}
          <div className="ml-1">新進定期契約員工</div>
        </div>
        <div className="flex align-items-center">
          {square({ isOpacity: true })}
          <div className="ml-1">
            甲方員工於產假、兵役或育嬰留職停薪期間等等之臨時替代定期人力，試用期間自簽訂日起至民國___年___月___日止，如經試用及格者，得繼續受僱至甲方員工復職後之___個工作日以辦理業務交接，勞動契約即終止。工作時間詳如第二條規定。
          </div>
        </div>
        <div className="flex align-items-center">
          {square({ isOpacity: true })}
          <div className="ml-1">
            乙方如係年齡滿65歲以上勞工，試用期間自簽訂日起至民國___年___月___日止或某項特定工作結束，勞動契約即歸終止。不受勞動基準法第9條第1項及第2項規定之限制。
          </div>
        </div>

        <div className="flex align-items-center">
          {square({ isFill: true, isOpacity: false })}
          <div className="ml-1">
            {`在職全職員工(補簽勞動契約)甲方自`}
            <span className="text-blue-400 font-bold">{`民國 ${
              Number(moment(memberData.entry_date).format("YYYY")) - 1911
            } 年 ${moment(memberData.entry_date).format("MM")} 月 ${moment(
              memberData.entry_date
            ).format("DD")} 日`}</span>
            {`起雇用乙方為不定期契約。`}
          </div>
        </div>

        <div className="flex align-items-center">
          {square({ isOpacity: false })}
          <div className="ml-1">回聘員工</div>
        </div>
        <div className="flex align-items-center">
          {square({ isOpacity: true })}
          <div className="ml-1">
            乙方已於民國___年___月___日起契約終止（▢自行離職、▢退休、▢資遣、▢其他）在案，並自民國___年___月___日起成立另一新的勞動契約，其前後工作年資不予合併計算；惟甲方若有提供優於法令之特別休假給假日數，就優於法令規定之給假日數，倘屆請休期限，其未休之日數同意歸零。工作時間詳如第二條規定。
          </div>
        </div>

        <div>乙方試用期間之約定如下：</div>
        <div className="flex align-items-start">
          <div>一、</div>
          <div className="ml-1">
            方於報到之日，向甲方繳交必要證明文件影本，並閱讀工作規則（含人事相關規章）及簽署本契約後，始成立勞動契約，並於同日加入勞健保及提繳勞退。
          </div>
        </div>
        <div className="flex align-items-start">
          <div>二、</div>
          <div className="ml-1">
            試用期間內，當事人雙方均保留契約終止權，於未濫用權利情形下，得隨時終止勞動契約，試用期未屆至，亦得一方逕行終止契約。
          </div>
        </div>
        <div>
          為保障乙方之工作權益，乙方所屬部門主管無權逕自為終止勞動契約之意思表示，概以甲方人資主管簽報總經理（或負責人）核准並以書面通知乙方後，始生勞動契約終止之效力。
        </div>
      </div>
    </>
  );

  const createTwentyFiveRule = () => (
    <>
      <div className="ml-5">
        <div>乙方於受僱期間應履行下列忠誠勤勉義務：</div>
        <div className="flex align-items-start">
          <div>一、</div>
          <div className="ml-1">
            乙方受僱於甲方期間，應遵守甲方主管之業務上指揮監督、甲方訂定之工作規則及所有內部規章，並致力於甲方所指派之工作與職務。
          </div>
        </div>

        <div className="flex align-items-start">
          <div>二、</div>
          <div className="ml-1">
            乙方於受僱期間，不得直接或間接收受或企圖獲取任何與甲方有業務往來之廠商或欲與甲方有業務往來之廠商所提供之任何形式報酬（包括佣金、禮物、招待或其他形式之利益）。
          </div>
        </div>

        <div className="flex align-items-start">
          <div>三、</div>
          <div className="ml-1">
            未經甲方事前書面同意，乙方不得直接或間接與甲方有業務往來或欲與甲方有業務往來之廠商為財務利益衝突之行為。
          </div>
        </div>

        <div className="flex align-items-start">
          <div>四、</div>
          <div className="ml-1">
            於傳染病發生或有發生之虞時（即防疫期間），乙方如有出國必要之規劃者，同意事前申請並經報備在案。返國後，同意以特別休假或事假進行自主健康管理14天。如造成甲方經濟利益損失，除按勞動基準法第12條第4款規定終止契約外，並循民事求償程序，請求損害賠償。
          </div>
        </div>
      </div>
    </>
  );

  const createTwentySixRule = () => (
    <>
      <div className="ml-5">
        <div>乙方應履行下列保密義務：</div>
        <div className="flex align-items-start">
          <div>一、</div>
          <div className="ml-1">
            乙方應盡善良管理人之注意義務，保守其於受僱期間內所知悉或持有之甲方或甲方客戶之營業秘密。除職務之正常使用外，非經甲方事前書面同意，乙方不得於受僱期間或離職後，以
            <span className="text-red-400 font-bold">任何方式</span>
            使用甲方或甲方客戶之營業秘密或洩漏於任何第三人。
          </div>
        </div>

        <div className="flex align-items-start">
          <div>二、</div>
          <div className="ml-1">
            本契約之「營業秘密」，係指與甲方業務有關之所有技術性及非技術性之資料（包括方法、技術、製程、配方、程式、設計、客戶資料、產品底價、銷售策略、財務會計資料、或其他可用於生產銷售或經營之資訊）。
          </div>
        </div>

        <div className="flex align-items-start">
          <div>三、</div>
          <div className="ml-1">
            乙方同意因其為可接觸甲方或甲方客戶特殊製程或配方之人員，因此其應與甲方就特定之技術製程或配方另行簽訂保密及競業禁止契約，負較高之注意義務。
          </div>
        </div>

        <div className="flex align-items-start">
          <div>四、</div>
          <div className="text-red-400 font-bold ml-1">
            乙方因特定工作可能接觸到相關營業秘密，乙方同意不攜帶手機進入工作區或因工作或業務上有使用手機之需求經由填具切結書後攜入手機，並遵守相關規定。
          </div>
        </div>
      </div>
    </>
  );

  const createThirtyOneRule = () => (
    <>
      <div className="ml-5">
        <div>甲乙雙方另行約定其它事項如下：</div>
        <div className="flex align-items-start">
          <div>一、</div>
          <div className="ml-1">智慧財產權條款</div>
        </div>

        <div className="ml-3">
          <div className="flex align-items-start">
            <div>(一)</div>
            <div className="ml-1">智慧財產權條款</div>
          </div>

          <div className="flex align-items-start">
            <div>(二)</div>
            <div className="ml-1">
              乙方因執行工作所完成或創作之構想、概念、發現、發明、改良、公式、程序、製造技術、著作或營業秘密等，無論有無取得著作權、專利權、商標權等權利，其一切相關權利與利益（包括但不限於所有權、申請權）均無償歸屬甲方所有，並同意不行使著作人格權。
            </div>
          </div>

          <div className="flex align-items-start">
            <div>(三)</div>
            <div className="ml-1">
              未經甲方同意，乙方不得為任何形式之複製或主張，或申請登記為自己或第三人之智慧財產權；甲方如有登記或申請智慧財產權之需要，乙方同意提供必要之協助。
            </div>
          </div>
        </div>

        <div className="flex align-items-start">
          <div>二、</div>
          <div className="ml-1">個人資料保護法之告知</div>
        </div>

        <div className="ml-3">
          <div className="flex align-items-start">
            <div>(一)</div>
            <div className="ml-1">
              甲方為蒐集、處理並利用乙方姓名、出生年月日、國民身分證統一編號、護照號碼、教育、職業、聯絡方式、財務情況、健康檢查及其他得以直接或間接方式識別該個人之資料，依法告知以下事項：
            </div>
          </div>

          <div className="ml-5">
            <div className="flex align-items-start">
              <div>1. </div>
              <div className="ml-1">
                蒐集目的：用於甲方內部人事建檔、員工管理、工資福利、緊急連絡、稅務申報、勞健保險及人力資源相關業務運用等一切人事管理、勞工行政事項。
              </div>
            </div>

            <div className="flex align-items-start">
              <div>2. </div>
              <div className="ml-1">
                期間：個人資料蒐集之特定目的存續期間、依相關法令或契約約定資料之保存所訂之保存期限。
              </div>
            </div>

            <div className="flex align-items-start">
              <div>3. </div>
              <div className="ml-1">地區：本國傳輸個人資料之接收者所在地。</div>
            </div>

            <div className="flex align-items-start">
              <div>4. </div>
              <div className="ml-1">對象：甲方。</div>
            </div>

            <div className="flex align-items-start">
              <div>5. </div>
              <div className="ml-1">
                方式：甲方以自動化機器或其他非自動化之利用方式。
              </div>
            </div>

            <div className="flex align-items-start">
              <div>6. </div>
              <div className="ml-1">
                用途：甲方得以電腦及文書處理方式運用乙方資料進行人事方面之研究分析及業務相關之行政程序。
              </div>
            </div>
          </div>

          <div className="flex align-items-start">
            <div>(二)</div>
            <div className="ml-1">乙方就甲方保有其個人資料得行使下列權利：</div>
          </div>

          <div className="ml-5">
            <div className="flex align-items-start">
              <div>1. </div>
              <div className="ml-1">
                得向甲方查詢、請求閱覽或請求製給複製本，而甲方得斟酌收必要成本費用。
              </div>
            </div>

            <div className="flex align-items-start">
              <div>2. </div>
              <div className="ml-1">
                得向甲方請求補充或更正，惟依法乙方應為適當之釋明。
              </div>
            </div>

            <div className="flex align-items-start">
              <div>3. </div>
              <div className="ml-1">
                得向甲方請求停止蒐集、處理或利用及請求刪除，惟甲方依法令或約定要求者，得不依乙方請求為之。
              </div>
            </div>
          </div>

          <div className="flex align-items-start">
            <div>(三)</div>
            <div className="ml-1">
              乙方得自由選擇是否提供相關個人資料，惟乙方若拒絕提供相關個人資料致甲方無法符合法令強制規定或受有損失者，甲方得終止甲乙雙方間勞動契約，並請求因此而受之一切損害。
            </div>
          </div>

          <div className="flex align-items-start">
            <div>(四)</div>
            <div className="ml-1">
              乙方同意，於甲乙雙方勞動契約存續期間，因職務關係接觸或知悉往來客戶、廠商及甲方其他員工之個人資料時，應負有保密義務。乙方不得向特定或不特定之第三人以散播、揭露或其他方式為洩漏前述個人資料或違反個人資料保護法之行為，若違反者，甲方得終止甲乙雙方間勞動契約，並請求因此而受之一切損害。
            </div>
          </div>

          <div className="flex align-items-start">
            <div>(五)</div>
            <div className="ml-1">
              本契約終止後，乙方得請求停止利用、處理或刪除其個人資料，但乙方同意甲方依勞動基準法第7條規定所為人力資源相關業務，或依職業安全衛生法所為之安全衛生管理相關業務，得繼續利用乙方提供之個人資料。
            </div>
          </div>
        </div>

        <div className="flex align-items-start">
          <div>三、</div>
          <div className="ml-1">
            為確保職業倫理、營業秘密、重要資訊及營業安全，乙方同意遵守甲方之網路、通訊器材及監視器使用規定，不得有任何破壞、阻礙或私用之行為，並接受甲方行使管理上必要之監視、監看及監聽。
          </div>
        </div>

        <div className="flex align-items-start">
          <div>四、</div>
          <div className="ml-1">
            甲方如有對乙方實施離職後競業禁止條款，於甲方支付平均工資半額後，乙方同意接受。
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="text-lg line-height-4">
        {createHead()}
        {createTitle({ text: "第 一 條 （僱用期間、試用期）" })}
        <div className="ml-3">{createFirstRule()}</div>
        {createTitle({ text: "第 二 條	（工作時間）" })}
        {createContent({
          text: "乙方正常工作時間，每七日中應有二日之休息，其中一日為例假，一日為休息日。按排班表出勤提供勞務，每日以8小時，每週以40小時為原則，並同意採用變形工時，按甲方排定班表準時出勤。",
        })}
        {createContent({
          text: "本契約所稱工作時間，以受指揮監督之時間為限，乙方未事先申請核准之提早到班時間或延後下班時間，均非屬提供勞務之工作時間。刷卡記錄時間係形式記載乙方進入及離開工作場所之時間，僅做為門禁安全管制需求，乙方之工作時間向以受指揮監督之排班表時間及加班申請合意（含事後補登申請）者為限。",
        })}
        {createContent({
          text: "乙方於正常工時依法請假後，又應雇主要求於前開時段外工作，乙方同意甲方予以調整為正常工作時間，則乙方無庸辦理請假（或由甲方逕行取消請假單），甲方無庸發給加班費。",
        })}
        {createTitle({ text: "第 三 條	（加班申請程序）" })}
        {createContent({
          text: "乙方同意遵守甲方工作規則第23條規定，加班申請程序：一、事前提出申請；二、事後補登申請；三、出差及場所外之加班申請。乙方提早到勤或延後退勤時數，未依規定申請並經核准者，與甲方並無延長工作時間之合意，事後不得以此做為加班費之申請。",
        })}
        {createTitle({ text: "第 四 條	（工作地點）" })}
        {createContent({
          text: "乙方勞務提供地點暫定以桃園市平鎮區工業十一路1號為主，惟亦兼及甲方關係企業所在地與臨時指派之支援地點，乙方不得拒絕。",
        })}
        {createContent({
          text: "甲方如因經營規劃或培訓人才之需求，得將乙方以借調勞資關係模式調動至關係企業或他企業提供勞務，如調動後之工資未做不利益變更，且調動後之工作為乙方所能勝任時，乙方同意接受甲方之調動；乙方拒絕調動時，甲方得不經預告終止勞動契約。",
        })}
        {createTitle({ text: "第 五 條	（工作項目）" })}
        <div className="ml-5">
          <span>乙方接受甲方之監督指揮，主要工作項目為</span>
          <span className="text-blue-400 font-bold">{` ${
            optionData.department_obj[memberData.departmentid]?.showLabel
          } `}</span>
          <span>部門</span>
          <span className="text-blue-400 font-bold">{` ${
            optionData.jobtitle_obj[memberData.jobtitleid]?.showLabel
          } `}</span>
          <span>
            之職掌項目，及其他簡便輕易及臨時性短期性交辦事項或偶發事件之處理。
          </span>
        </div>
        {createContent({
          text: "當今企業經營競爭激烈，員工具備各項專長技能亦為趨勢所在，甲方基於企業經營發展、內部管理需要、靈活運用人力並為防杜弊端發生，及提升乙方之各項職務歷練，甲方得對乙方進行跨部門工作職務調整，乙方同意接受。",
        })}
        {createTitle({ text: "第 六 條（工資之議定及配合加班）" })}
        <div className="ml-5">
          <span>乙方工資為每月新台幣</span>
          <span className="text-blue-400 font-bold">{` ${memberData.human_change_records?.[0].salary} 元`}</span>
          <span>（薪資項目為</span>
          <span className="text-blue-400 font-bold">{`${memberData.human_change_records?.[0].salary_info
            .map(
              (item) =>
                `${optionData.salary_type_obj[item.type]?.showLabel} ${
                  item.salary
                }元`
            )
            .join("、")}`}</span>
          <span>）。</span>
        </div>
        {createContent({
          text: "全勤獎金發放計算：請特休假、公假、生理假、喪假除外，其餘假別皆需扣除全勤。",
        })}
        {createContent({
          text: "交通津貼發放計算：依照出勤天數給予。",
        })}
        {createContent({
          text: "職務/技術發放計算：依照此人職務提供給予/依照出勤天數提供其專業技術發放。",
        })}
        {createContent({
          text: "工資以每月30日計薪為主，但當月份有31日者則加給一日工資。",
        })}
        {createContent({
          text: "乙方全月未請事病假、遲到、早退之情事時，其領有全勤獎金應全數列入平日每小時工資額，據以計算加班費。反之，全勤獎金為零，亦不列入平日每小時工資額。",
        })}
        {createTitle({ text: "第 七 條（勉勵恩惠性質之給與）" })}
        {createContent({
          text: "乙方同意遵守工作規則第20條規定，甲方得視經營狀況良窳，按勞動基準法第29條及同法施行細則第10條規定，提供非經常性之恩惠性、獎勵性及補助性之給與，並以核發時在職且有實際從事工作提供勞務者為限。",
        })}
        {createTitle({ text: "第 八 條	（在職期間之工資發放）" })}
        {createContent({
          text: "乙方之每月工資於次月5日一次發放為原則，由甲方匯入甲方所指定金融機構之乙方帳戶，或以現金方式發放。發放日期如遇例假、國定假日或休息日，甲方得提前一個銀行營業日發給。",
        })}
        {createTitle({ text: "第 九 條	（契約終止時之工資發放）" })}
        {createContent({
          text: "本契約終止時，乙方如未踐行預告或未辦妥離職交接手續時，其未支領之工資及其他給與，乙方同意於原發薪日親自至甲方主辦單位辦理請領薪資作業手續。",
        })}
        {createTitle({ text: "第 十 條	（工資保密義務）" })}
        {createContent({
          text: "乙方之薪資（含獎金紅利分配），除與直屬部門主管及甲方人事主管外，不得與他人討論，亦不得探詢他人工資，如有違反，甲方得視情節輕重，予以處分。",
        })}
        {createTitle({ text: "第 十一 條	（無薪休假及免除主管加給）" })}
        {createContent({
          text: "甲方若受景氣因素影響致停工或減產時，乙方同意甲方可暫時縮減工作時間及依比例減少工資。",
        })}{" "}
        {createContent({
          text: "甲方因經營政策變更、組織縮小或合併而免除乙方之兼任主管職務時，原有主管加給得予停止發放。乙方個人職務表現致調整為非主管職務時，亦同。",
        })}
        {createTitle({ text: "第 十二 條	（工資及非經常性給與扣款約定）" })}
        {createContent({
          text: "甲方成立之福利委員會縱未依法辦理登記，乙方亦同意甲方代為扣繳福利金。",
        })}
        {createContent({
          text: "甲方先行墊付原領工資全額之職業災害薪資補償或工資半額之普通傷病薪資，乙方於領取勞工保險給付或商業保險給付後，就抵充金額返還甲方，或同意從最近一次薪資中逕行扣除。",
        })}
        {createTitle({ text: "第 十三 條	（例假及休息日）" })}
        {createContent({
          text: "乙方每七日中應有二日之休息，其中一日為休息日，一日為例假。休息日、例假未必是固定於星期六、星期日，週休二日也未必連續放假。甲方如有排定變形工時（如2週、8週或4週變形）班表，乙方應依甲方排班表準時出勤，未經甲方同意前，乙方不得私自調動班表或找他人代班。",
        })}
        {createTitle({ text: "第 十四 條	（國定假日）" })}
        {createContent({
          text: "為因應企業彈性需求，國定假日乙方同意由甲方逕自調移為正常工作日，另行擇期休假。或由甲方平均分配安排於每月最後一個工作日，或於12月（或10月）底，或於各該國定假日所屬月份，如有未休之國定假日者，甲方應即結算工資。",
        })}
        {createTitle({ text: "第 十五 條	（特別休假）" })}
        {createContent({
          text: "特別休假期日，由乙方排定之。惟甲方基於企業經營上之急迫需求或乙方因個人因素，亦得與他方協商調整。乙方行使特別休假排定時，應遵守民法第148條規定之權利濫用禁止及誠信原則條款。",
        })}
        {createContent({
          text: "乙方於自行離職或契約終止時，依實際年資結算特別休假日數，如有已申請休假日數多於法定日數情形，乙方同意於最近一個月工資中追溯扣回，或同意改以其他假別登記。",
        })}
        {createContent({
          text: "員工之特別休假，於年度終結未休之日數，經勞資會議同意於當年度結清，不另行遞延，",
        })}
        {createContent({
          text: "乙方同意該結算工資不列入平均工資；但如屬契約終止前特別休假當天出勤加班費，則應列入平均工資。",
        })}
        {createTitle({ text: "第 十六 條	（請假）" })}
        {createContent({
          text: "乙方因故必須請假者，應事先向指定之職務代理人交待代理事項，並填寫請假單經核准後，方可離開工作崗位或不出勤；如遇急病或臨時重大事故，不及事先請假時，應於缺勤當日一小時內，以電話、通訊軟體如line及簡訊等途徑，報告單位主管；惟乙方應於提供勞務後之一個工作日內，補述理由並提供必要證明文件，辦理書面請假手續，其工作單位主管按權責核定之。",
        })}
        {createContent({
          text: "於傳染病發生或有發生之虞時（即防疫期間），乙方如有出現感冒症狀或發燒到37.5度以上的情況，同意以普通傷病假或特別休假辦理，暫時停止提供勞務。",
        })}
        {createContent({
          text: "乙方申請育嬰留職停薪時，應於十日前事先以書面提出申請，未於十日前提出申請者，其留職停薪生效日遞延至十日屆滿之翌日。但員工有少於六個月之需求者，得以不低於三十日之期間，向雇主提出申請，並以二次為限。",
        })}
        {createTitle({
          text: "第 十七 條	（工作日與非工作日逢天然災害之出勤管理）",
        })}
        {createContent({
          text: "各縣市政府於颱風過境或地震發生致宣布停止上班上課時，乙方如無法出勤工作時，甲方以不發給當日工資為原則。",
        })}
        {createContent({
          text: "甲方為因應天然災害之預防及搶修準備時，乙方如為緊急應變小組或搶修小組成員時，乙方不得無故拒絕出勤。",
        })}
        {createTitle({ text: "第 十八 條	（不經預告解僱事由）" })}
        {createContent({
          text: "勞動基準法第12條第4款規定違反勞動契約或工作規則情節重者，同意按工作規則第11條規定辦理。同條項第6款規定一個月內曠工達六日者，同意以連續三十日為一個月。",
        })}
        {createTitle({ text: "第 十九 條	（嘉獎、記功、記大功）" })}
        {createContent({
          text: "乙方工作表現之獎勵，按工作規則第43條規定辦理。",
        })}
        {createTitle({ text: "第 二十 條	（警告、記過、記大過）" })}
        {createContent({
          text: "甲方為維護紀律，確保工作秩序，乙方同意按工作規則第43條規定辦理。",
        })}
        {createTitle({ text: "第 二十一 條	（女性夜間工作之禁止及例外）" })}
        {createContent({
          text: "乙方有就妊娠或哺乳情形通知甲方之義務，乙方違反此項通知義務，致甲方遭受處分者，甲方因遭受處分所受之損害，乙方應負損害賠償責任。",
        })}
        {createTitle({ text: "第 二十二 條	（職業災害定義）" })}
        {createContent({
          text: "本契約所稱職業災害，依職業安全衛生法第2條第5款規定辦理，以符合「業務遂行性」與「業務起因性」為判斷基準。",
        })}
        {createContent({
          text: "乙方上下班通勤若經主管機關依「勞工保險被保險人因執行職務而致傷病審查準則」認定成立，僅得作為申請勞保給付之參考。惟是否屬於勞動基準法或職業安全衛生法所定之職業災害，雙方同意依第一項規定辦理。",
        })}
        {createTitle({
          text: "第 二十三 條	（因職業原因致不能適應原有工作之處理）",
        })}
        {createContent({
          text: "乙方因職業原因或因乙方個人健康因素致不能適應原勞動契約工作時，除予醫療外，乙方同意接受甲方變更作業場所，更換工作，縮短工作時間及為其他適當措施。",
        })}
        {createTitle({ text: "第 二十四 條	（公傷病假已逾越合理期限之處理）" })}
        {createContent({
          text: "乙方申請公傷假之認定，同意依照職業安全衛生法第2條第5款有關職業災害之定義，而認定是否為公傷。",
        })}
        {createContent({
          text: "乙方發生職業災害（含復健休養）申請公傷病假時，如已逾勞保局核定職業災害給付日數，同意改以普通傷病假日數處理。",
        })}
        {createTitle({ text: "第 二十五 條	（忠誠勤勉義務）" })}
        {createTwentyFiveRule()}
        {createTitle({ text: "第 二十六 條	（保密義務）" })}
        {createTwentySixRule()}
        {createTitle({ text: "第 二十七 條	（禁止兼職）" })}
        {createContent({
          text: "乙方若為按月計酬之全職人員，於本契約期間內，為保障乙方生活健康及甲方之經營利益，非經甲方書面同意，不得在外兼職。",
        })}
        {createTitle({ text: "第 二十八 條	（終止契約未預告、未交接之處罰）" })}
        {createContent({
          text: "雙方預告終止契約須依勞動基準法第16條規定之預告期間，如預告期間日數不足者，每不足一日應賠償對方一日工資。",
        })}
        {createContent({
          text: "乙方於法定預告期間，同意以業務交接為首要，暫不行使特別休假；惟如有未休畢特別休假之日數，甲方應結算特別休假工資。",
        })}
        {createContent({
          text: "乙方申請留職停薪時，為順利將執行業務交付替代人力，亦同意辦妥業務交接手續。",
        })}
        {createTitle({ text: "第 二十九 條	（挖角禁止）" })}
        {createContent({
          text: "乙方離職後，不得引誘、慫恿、幫助或以其他不當方式使甲方現職員工終止與甲方之僱傭關係，亦不得誘使甲方之往來廠商客戶與甲方終止或解除商業交易。",
        })}
        {createContent({
          text: "乙方違反前項約定，除以最後在職月薪資1個月作為懲罰性違約金外，如造成甲方損害，乙方應負損害賠償責任。",
        })}
        {createTitle({ text: "第 三十 條	（送達地址之約定）" })}
        {createContent({
          text: "乙方之通訊地址有變更而未通知甲方，致甲方之各項文件通知無法送達時，視為甲方已為合法通知乙方；乙方住所未變更，但甲方付郵送達而乙方拒絕收受或無法投遞時，亦同。",
        })}
        {createTitle({ text: "第 三十一 條	（其它約定事項）" })}
        {createThirtyOneRule()}
        {createTitle({ text: "第 三十二 條	（契約之執行）" })}
        {createContent({
          text: "本契約所引用或涉及之法令如有變更，應以修正後之法令為準。",
        })}
        {createFoot()}
      </div>
    </>
  );
}
