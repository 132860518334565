import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { useHookForm } from "../../../../components/hooks";
import { UploadAPI } from "../../../../axios/api";

import { FormDropdown } from "../../../../components/formElement";
import { formatResData, checkFormUpdate } from "../../../../service/common";
import { option_leave_work } from "../../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function ModulePopLeaveWorkDetail({
  popPreOption,
  closePrePop,
  uploadColumns,
  setPreReload,
  leaveType,
}) {
  const dispatch = useDispatch();
  // 表單欄位預設值
  const defaultValues = {
    type_name: "",
  };
  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    yup,
    setSchema,
    errors,
    setting,
  } = useHookForm({
    defaultValues,
  });

  useEffect(() => {
    setSchema(
      yup
        .object({
          // name: yup.string().required("必填"),
        })
        .required()
    );
  }, []);

  useEffect(() => {
    reset(formatResData(defaultValues, popPreOption.data));
  }, [popPreOption.data]);

  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    let jsonData = {
      ...submitData,
      id: popPreOption.data.id,
    };

    if (!checkFormUpdate(jsonData, popPreOption.data)) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      closePrePop({ type: "detail" });
      return;
    }

    UploadAPI.updateLeaveWorksRecordData({
      data: jsonData,
    }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: `修改成功`,
            detail: "",
          })
        );
        closePrePop({ type: "detail" });
        setPreReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `修改失敗`,
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      <div className="mx-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <table className="w-full text-lg line-height-4 border-1 border-400 -mt-2">
            <tbody>
              {uploadColumns.map((item, index) => (
                <tr
                  key={`${item.key}`}
                  className={classNames({
                    "border-top-1 border-400": index !== 0,
                  })}
                >
                  <td className="w-8rem h-3rem text-right text-white bg-main px-2">
                    {item.label}
                  </td>
                  <td
                    className={classNames("white-space-prewrap", {
                      "px-2": item.key !== "type_name",
                    })}
                  >
                    {item.key === "type_name" ? (
                      <FormDropdown
                        setting={setting}
                        data={{
                          name: "type_name",
                          options: leaveType,
                          optionLabel: "name",
                          optionValue: "name",
                        }}
                      />
                    ) : (
                      popPreOption.data[item.key]
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="text-right mt-3">
            <Button
              className="p-button-secondary p-button-outlined px-3"
              type="button"
              label="取消"
              onClick={() => closePrePop({ type: "detail" })}
            />
            <Button className="ml-2 px-3" type="submit" label="儲存" />
          </div>
        </form>
      </div>
    </>
  );
}
