import { useState } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import {
  axios,
  AxiosMulti,
  formatApiResult,
} from "../../../../axios/request/common";
import { AssessmentAPI } from "../../../../axios/api";
import { useAbortedEffect } from "../../../../components/hooks";

import ModuleAssessmentForm from "../../../../components/common/assessmentForm";

import { Button } from "primereact/button";

export default function ModulePopTemplatePreview({
  prePopOption,
  closePrePop,
}) {
  const dispatch = useDispatch();
  const [apiData, setApiData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [managerData, setManagerData] = useState([]);

  useAbortedEffect(
    (signal) => {
      dispatch(setProgress(true));

      AxiosMulti([
        AssessmentAPI.getExamExamplesDetails({
          data: { id: prePopOption.data.id },
          options: { signal },
        }),
      ]).then(
        axios.spread((result_detail = {}) => {
          // 明細資料
          let { data: result_detail_data } = result_detail;
          if (result_detail.Response === 1) {
            setCategoryData(result_detail_data.exam_example_item_setting);
            setManagerData(result_detail_data.exam_example_weight);
            setApiData(result_detail_data);
          }

          let isError = formatApiResult({
            data: [result_detail],
            action: (message) => {
              dispatch(
                setToast({
                  severity: "error",
                  summary: message,
                  detail: "",
                })
              );
            },
          });
          if (isError) {
            dispatch(setProgress(false));
            return;
          }

          dispatch(setProgress(false));
        })
      );
    },
    [prePopOption]
  );

  return (
    <>
      <div className="text-center px-3">
        <ModuleAssessmentForm
          categoryData={categoryData}
          managerData={managerData}
          footerTemplate={
            <>
              <div className="w-full mt-3">
                <Button
                  className="p-button-secondary px-4"
                  type="button"
                  label="關閉"
                  onClick={() => closePrePop({ type: "preview" })}
                />
              </div>
            </>
          }
        />
      </div>
    </>
  );
}
