import { useEffect, useState } from "react";
import { useParams, useNavigate, json } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import {
  useAbortedEffect,
  useHookForm,
  useMultiRecords,
  usePop,
} from "../../../../components/hooks";
import {
  axios,
  AxiosMulti,
  formatApiResult,
} from "../../../../axios/request/common";
import { MemberAPI, SettingAPI } from "../../../../axios/api";

import ModuleBreadCrumb from "../../../../components/breadCrumb";
import ModuleUploadFile from "../../../../components/uploadFile";
import ModulePopSalaryChart from "./popSalaryChart";
import {
  ModuleProfileDriverLicenseType,
  ModuleProfileEducationLevel,
  ModuleProfileGoodAtTool,
  ModuleProfileHumanChangeRecords,
  ModuleProfileLanguageLevel,
  ModuleProfileWorkExperience,
  ModuleProfileImmediateFamily,
  ModuleProfileCertificates,
} from "./form";
import {
  FormInputtext,
  FormDropdown,
  FormTreeSelect,
  FormCalendar,
  FormInputtextarea,
  FormInputnumber,
} from "../../../../components/formElement/index";
import {
  formatResData,
  checkFormUpdate,
  formatOptions,
  idCardCheck,
  formatTreeData,
} from "../../../../service/common";
import {
  option_yesno,
  option_member_type,
  option_marriage,
  option_military_service,
  option_blood_type,
} from "../../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function BackMemberProfileDetail() {
  const route_params = useParams();
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMultiRecords } = useMultiRecords();
  const crumbItem = [
    {
      key: 1,
      label: "員工資料",
      command: () => route_navigate("/back/member/profile"),
    },
    { key: 2, label: "編輯" },
  ];
  const [reload, setReload] = useState({
    list: true,
  });
  const disabled = false;
  // 明細資料
  const [apiData, setApiData] = useState({});
  const [optionData, setOptionData] = useState(null);
  // 表單欄位預設值
  const defaultValues = {
    username: "",
    type: null,
    departmentid: null,
    jobtitleid: null,
    ch_last_name: "",
    ch_first_name: "",
    en_last_name: "",
    en_first_name: "",
    birthday: null,
    id_number: "",
    contact_address: "",
    residence_address: "",
    interview_date: null,
    interview_score: null,
    exam_date: null,
    exam_score: null,
    expected_start_date: null,
    entry_date: null,
    expected_salary: null,
    introducer_mdid: null,
    introducer_department: null,
    introducer_jobtitle: null,
    introducer_relationship: "",
    marriage: null,
    height: null,
    weight: null,
    blood_type: null,
    military_service: null,
    isstay: null,
    isfood: null,
    health_condition: "",
    driver_license_type: "",
    good_at_tool: "",
    emergency_contact_cel: "",
    emergency_contact_name: "",
    emergency_contact_address: "",
    remark: "",
    education_level: [],
    language_level: [],
    work_experience: [],
    immediate_family: [],
    certificates: [],
    human_change_records: [],
    good_at_tool: [],
    others_file: "",
    others_fileList: [],
    others_file_path: "",
  };
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
    watch,
    yup,
    setSchema,
    errors,
    setting,
    setIsFormUpdate,
  } = useHookForm({
    defaultValues,
  });
  // 監聽欄位
  const watchColumn = {
    introducer_mdid: watch("introducer_mdid"),
    departmentid: watch("departmentid"),
  };
  const [uploadData, setUploadData] = useState([]);
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "salary_chart",
      title: () => "薪資比較",
      isOpen: false,
    },
  ]);

  useEffect(() => {
    setSchema(
      yup
        .object({
          username: yup.string().required("必填"),
          type: yup.number().required("必填").nullable(),
          departmentid: yup.number().required("必填").nullable(),
          jobtitleid: yup.number().required("必填").nullable(),
          ch_last_name: yup.string().required("必填"),
          ch_first_name: yup.string().required("必填"),
          id_number: yup
            .string()
            .test("isValidPass", "身分證字號格式錯誤", (value) => {
              return idCardCheck(value);
            })
            .required("必填"),
        })
        .required()
    );
  }, []);

  // 動態判斷欄位
  useEffect(() => {
    if (!optionData) return;
    let match =
      optionData?.memberList?.find(
        (item) => item.id === watchColumn.introducer_mdid
      ) ?? {};
    setValue("introducer_department", match.departmentid ?? null);
    setValue("introducer_jobtitle", match.jobtitleid ?? null);
  }, [watchColumn.introducer_mdid]);

  useEffect(() => {
    if (!optionData) return;

    let submitData = getValues();
    let match =
      optionData?.jobtitle?.find((item) => item.id === submitData.jobtitleid) ??
      {};

    setValue("jobtitleid", match.id ?? null);
  }, [watchColumn.departmentid]);

  useAbortedEffect(
    (signal) => {
      if (!reload.list) return;

      dispatch(setProgress(true));

      let params = {
          status: {
            status: 1,
          },
          list: {
            page: -1,
          },
          form: {
            id: route_params.detailid,
            detail: 1,
          },
        },
        promise = [
          SettingAPI.getLanguagesList({
            data: params.status,
            options: { signal },
          }),
          SettingAPI.getGoodAtToolsList({
            data: params.status,
            options: { signal },
          }),
          SettingAPI.getDepartmentTreeList({
            data: params.list,
            options: { signal },
          }),
          SettingAPI.getJobTitleList({
            data: { ...params.list },
            options: { signal },
          }),
          MemberAPI.getMemberDetailsList({
            data: params.list,
            options: { signal },
          }),
        ];

      if (route_params.detailid !== "add") {
        promise.push(
          MemberAPI.getMemberDetailsData({
            data: params.form,
            options: { signal },
          })
        );
      }

      AxiosMulti(promise).then(
        axios.spread(
          (
            result_language = {},
            result_goodAtTool = {},
            result_department = {},
            result_jobtitle = {},
            result_memberList = {},
            result_form = {}
          ) => {
            let tempOptions = {};

            // 語言
            let { data: result_language_data } = result_language;
            if (result_language.Response === 1) {
              tempOptions = {
                ...tempOptions,
                language: result_language_data,
              };
            }

            // 擅長工作
            let { data: result_goodAtTool_data } = result_goodAtTool;
            if (result_goodAtTool.Response === 1) {
              tempOptions = {
                ...tempOptions,
                goodAtTool: result_goodAtTool_data,
              };
            }

            // 部門
            let { data: result_department_data } = result_department;
            if (result_department.Response === 1) {
              result_department_data = formatTreeData({
                module: "select",
                key: "id",
                label: "name",
                childs: result_department_data,
              });
              tempOptions = {
                ...tempOptions,
                ...formatOptions(
                  { department: result_department_data },
                  { uniqueKey: "key", showLabel: "label", childKey: "children" }
                ),
              };
            }

            // 職稱
            let { data: result_jobtitle_data } = result_jobtitle;
            if (result_jobtitle.Response === 1) {
              tempOptions = {
                ...tempOptions,
                ...formatOptions(
                  { jobtitle: result_jobtitle_data },
                  { uniqueKey: "id", showLabel: "name" }
                ),
              };
            }

            // 員工清單
            let { data: result_memberList_data } = result_memberList;
            if (result_memberList.Response === 1) {
              result_memberList_data = result_memberList_data.map((item) => ({
                ...item,
                name_format: `${item.ch_last_name ?? ""}${
                  item.ch_first_name ?? ""
                }`,
              }));
              tempOptions = {
                ...tempOptions,
                memberList: result_memberList_data,
              };
            }

            // 表單資料
            let { data: result_form_data } = result_form;
            if (result_form.Response === 1) {
              result_form_data = {
                ...result_form_data,
                good_at_tool: result_form_data.good_at_tool
                  .split(",")
                  .map((item) => Number(item)),
              };
              let temp = formatResData(defaultValues, result_form_data);
              temp.human_change_records = temp.human_change_records.map(
                (item) => ({
                  ...item,
                  is_str:
                    !tempOptions.department_obj[item.department]?.showLabel,
                })
              );

              setApiData(temp);
              reset(temp);
              setReload((state) => ({ ...state, list: false }));
            }

            let isError = formatApiResult({
              data: [
                result_language,
                result_goodAtTool,
                result_department,
                result_jobtitle,
                result_memberList,
                result_form,
              ],
              action: (message) => {
                dispatch(
                  setToast({
                    severity: "error",
                    summary: message,
                    detail: "",
                  })
                );
              },
            });
            if (isError) {
              dispatch(setProgress(false));
              return;
            }

            setOptionData(tempOptions);

            dispatch(setProgress(false));
          }
        )
      );
    },
    [reload]
  );

  const onSubmit = async (submitData) => {
    dispatch(setProgress(true));
    setIsFormUpdate(false);

    let isAdd = route_params.detailid === "add",
      jsonData = {
        ...submitData,
        good_at_tool: submitData.good_at_tool.join(","),
        education_level: formatMultiRecords({
          data: submitData.education_level,
          isAdd,
        }),
        language_level: formatMultiRecords({
          data: submitData.language_level,
          isAdd,
        }),
        work_experience: formatMultiRecords({
          data: submitData.work_experience,
          isAdd,
        }),
        immediate_family: formatMultiRecords({
          data: submitData.immediate_family,
          isAdd,
        }),
        certificates: formatMultiRecords({
          data: submitData.certificates,
          isAdd,
        }),
      };

    submitData.human_change_records = submitData.human_change_records.map(
      (item, index) => ({
        ...item,
        orders: index,
        department:
          optionData.department_obj[item.department]?.showLabel ??
          item.department,
        job_title:
          optionData.jobtitle_obj[item.job_title]?.showLabel ?? item.job_title,
      })
    );

    let human_change_records = formatMultiRecords({
      data: submitData.human_change_records,
      isAdd,
    });
    if (isAdd) {
      human_change_records = human_change_records.map(
        ({
          salary_file,
          salary_fileList,
          salary_file_path,
          uploadData,
          createdatetime,
          updatedatetime,
          ...main
        }) => ({
          ...main,
          salary_info: main.salary_info.map(({ id, flag, ...rest }) => ({
            ...rest,
          })),
        })
      );
    } else {
      human_change_records.insert = human_change_records.insert.map(
        ({
          salary_file,
          salary_fileList,
          salary_file_path,
          uploadData,
          createdatetime,
          updatedatetime,
          ...main
        }) => ({
          ...main,
          salary_info: main.salary_info.map(({ id, flag, ...rest }) => ({
            ...rest,
          })),
        })
      );
      human_change_records.update = human_change_records.update.map(
        ({
          salary_file,
          salary_fileList,
          salary_file_path,
          uploadData,
          createdatetime,
          updatedatetime,
          ...main
        }) => ({
          ...main,
          salary_info: formatMultiRecords({
            data: main.salary_info,
            isAdd: false,
          }),
        })
      );
    }
    jsonData.human_change_records = human_change_records;

    delete jsonData.introducer_department;
    delete jsonData.introducer_jobtitle;

    if (!checkFormUpdate(jsonData, apiData)) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      route_navigate("/back/member/profile");
      return;
    }

    if (isAdd || (!isAdd && apiData.username !== submitData.username)) {
      let result = await MemberAPI.checkUsername({
        data: {
          username: submitData.username,
        },
      });
      if (result.Response === 2) {
        dispatch(
          setToast({
            severity: "warn",
            summary: result.message,
            detail: "",
          })
        );
        dispatch(setProgress(false));
        return;
      }
    }

    let promise,
      toast = "";
    if (isAdd) {
      // 新增
      promise = MemberAPI.setMemberDetailsData({
        data: jsonData,
      });
      toast = "新增";
    } else {
      // 修改
      delete jsonData.username;
      jsonData.id = Number(route_params.detailid);
      promise = MemberAPI.updateMemberDetailsData({
        data: jsonData,
      });
      toast = "修改";
    }

    promise.then((result) => {
      let { Response, humanChangeRecords, message } = result;

      if (Response === 1) {
        let detail_format = {};
        humanChangeRecords.forEach(
          (item) => (detail_format[item.orders] = item)
        );

        let subFileData = [];
        submitData.human_change_records.forEach((item, index) => {
          if (item.uploadData && item.uploadData.length > 0) {
            subFileData.push({
              id:
                item.flag !== "insert"
                  ? item.id
                  : detail_format[item.orders].id,
              uploadData: item.uploadData,
            });
          }
        });

        if (subFileData.length > 0) {
          saveSubFile({
            toast,
            subFileData,
            cIdx: 0,
            count: subFileData.length,
          });
        } else if (uploadData.length > 0) {
          saveFile({ toast });
        } else {
          successAction({ toast });
        }
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `${toast}失敗`,
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  const saveSubFile = ({ toast, subFileData, cIdx, count }) => {
    let isAdd = false,
      addCount = 0,
      isDelete = false,
      formData = new FormData(),
      delete_path_List = [];
    subFileData[cIdx].uploadData.forEach((item, index) => {
      if (!item.isDelete && item.file) {
        isAdd = true;
        formData.append(`salary_file${addCount}`, item.file);
        addCount++;
      } else if (item.isDelete && !item.file) {
        isDelete = true;
        delete_path_List.push(item.raw);
      }
    });

    if (!isAdd && !isDelete) {
      cIdx++;
      if (cIdx < count) {
        saveSubFile({ toast, subFileData, cIdx, count });
      } else if (uploadData.length > 0) {
        saveFile({ toast });
      } else {
        successAction({ toast });
      }
      return;
    }

    let promise = [];
    if (isAdd) {
      formData.append("id", Number(subFileData[cIdx].id));
      formData.append("typename", "humchangerecords_salary_file");
      promise.push(
        MemberAPI.humanChangeRecordsFileUpload({
          data: formData,
        })
      );
    }
    if (isDelete) {
      promise.push(
        MemberAPI.humanChangeRecordsDeleteFile({
          data: {
            delete_path_List,
          },
        })
      );
    }

    AxiosMulti(promise).then((result1 = {}, result2 = {}) => {
      let { Response1 = 1 } = result1;
      let { Response2 = 1 } = result2;

      if (Response1 === 1 && Response2 === 1) {
        cIdx++;
        if (cIdx < count) {
          saveSubFile({ toast, subFileData, cIdx, count });
        } else if (uploadData.length > 0) {
          saveFile({ toast });
        } else {
          successAction({ toast });
        }

        return;
      }

      dispatch(
        setToast({
          severity: "error",
          summary: "附檔儲存失敗",
          detail: "",
        })
      );
      dispatch(setProgress(false));
    });
  };

  const saveFile = ({ toast }) => {
    let isAdd = false,
      addCount = 0,
      isDelete = false,
      formData = new FormData(),
      delete_path_List = [];
    uploadData.forEach((item, index) => {
      if (!item.isDelete && item.file) {
        isAdd = true;
        formData.append(`others_file${addCount}`, item.file);
        addCount++;
      } else if (item.isDelete && !item.file) {
        isDelete = true;
        delete_path_List.push(item.raw);
      }
    });

    if (!isAdd && !isDelete) {
      successAction({ toast });
      return;
    }

    let promise = [];
    if (isAdd) {
      formData.append("id", Number(route_params.detailid));
      formData.append("typename", "member_others");
      promise.push(
        MemberAPI.othersFileUpload({
          data: formData,
        })
      );
    }
    if (isDelete) {
      promise.push(
        MemberAPI.othersDeleteFile({
          data: {
            delete_path_List,
          },
        })
      );
    }

    AxiosMulti(promise).then((result1 = {}, result2 = {}) => {
      let { Response1 = 1 } = result1;
      let { Response2 = 1 } = result2;

      if (Response1 === 1 && Response2 === 1) {
        successAction({ toast });
        return;
      }

      dispatch(
        setToast({
          severity: "error",
          summary: "附檔儲存失敗",
          detail: "",
        })
      );
      dispatch(setProgress(false));
    });
  };

  const successAction = ({ toast }) => {
    dispatch(
      setToast({
        severity: "success",
        summary: `${toast}成功`,
        detail: "",
      })
    );
    route_navigate("/back/member/profile");
    dispatch(setProgress(false));
  };

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <div className="border-round-lg bg-white mt-3 p-3">
        <h2 className="mt-2 mb-4 ml-2">員工資料 - 編輯</h2>

        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid">
          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="type"
              className={classNames(
                { "p-error": errors.type },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>員工編號
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputtext
                className="w-full lg:w-6"
                setting={setting}
                data={{
                  name: "username",
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="departmentid"
              className={classNames(
                { "p-error": errors.departmentid || errors.jobtitleid },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>部門/職稱
            </label>
            <div className="flex col-12 sm:col-10 md:col-8 lg:col-5">
              <FormTreeSelect
                className="w-6"
                setting={setting}
                data={{
                  name: "departmentid",
                  placeholder: "部門",
                  options: optionData?.department ?? [],
                  filter: true,
                  disabled,
                }}
              />
              <FormDropdown
                className="w-6 ml-2"
                setting={setting}
                data={{
                  name: "jobtitleid",
                  placeholder: "職稱",
                  options:
                    (optionData?.jobtitle || []).filter(
                      (item) => item.departmentid === getValues("departmentid")
                    ) ?? [],
                  optionLabel: "name",
                  optionValue: "id",
                  showClear: false,
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="type"
              className={classNames(
                { "p-error": errors.type },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>人事狀態
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormDropdown
                className="w-full lg:w-6"
                setting={setting}
                data={{
                  name: "type",
                  options: option_member_type,
                  optionLabel: "label",
                  optionValue: "value",
                  showClear: false,
                  filter: true,
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="ch_last_name"
              className={classNames(
                { "p-error": errors.ch_last_name || errors.ch_first_name },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>中文姓名
            </label>
            <div className="flex col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputtext
                className="w-5 lg:w-4"
                setting={setting}
                data={{
                  name: "ch_last_name",
                  placeholder: "姓",
                  disabled,
                }}
              />
              <FormInputtext
                className="w-7 lg:w-6 ml-2"
                setting={setting}
                data={{
                  name: "ch_first_name",
                  placeholder: "名",
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="en_last_name"
              className={classNames(
                { "p-error": errors.en_last_name || errors.en_first_name },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              英文姓名
            </label>
            <div className="flex col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputtext
                className="w-5 lg:w-4"
                setting={setting}
                data={{
                  name: "en_last_name",
                  placeholder: "姓",
                  disabled,
                }}
              />
              <FormInputtext
                className="w-7 lg:w-6 ml-2"
                setting={setting}
                data={{
                  name: "en_first_name",
                  placeholder: "名",
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="birthday"
              className={classNames(
                { "p-error": errors.birthday },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              出生日期
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormCalendar
                className="w-full lg:w-6"
                setting={setting}
                data={{
                  name: "birthday",
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="id_number"
              className={classNames(
                { "p-error": errors.id_number },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>身分證字號
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputtext
                className="w-full lg:w-6"
                setting={setting}
                data={{
                  name: "id_number",
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="contact_address"
              className={classNames(
                { "p-error": errors.contact_address },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              通訊地址
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputtext
                setting={setting}
                data={{
                  name: "contact_address",
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="residence_address"
              className={classNames(
                { "p-error": errors.residence_address },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              戶籍地址
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputtext
                setting={setting}
                data={{
                  name: "residence_address",
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="interview_date"
              className={classNames(
                { "p-error": errors.interview_date || errors.interview_score },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              面試日期/分數
            </label>
            <div className="flex align-items-center col-12 sm:col-10 md:col-8 lg:col-5">
              <FormCalendar
                className="w-full lg:w-6"
                setting={setting}
                data={{
                  name: "interview_date",
                  placeholder: "面試日期",
                  disabled,
                }}
              />
              <FormInputnumber
                className="w-8rem ml-2"
                setting={setting}
                data={{
                  name: "interview_score",
                  placeholder: "分數",
                  disabled,
                }}
              />
              <span className="ml-1">分</span>
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="exam_date"
              className={classNames(
                { "p-error": errors.exam_date || errors.exam_score },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              考試日期/分數
            </label>
            <div className="flex align-items-center col-12 sm:col-10 md:col-8 lg:col-5">
              <FormCalendar
                className="w-full lg:w-6"
                setting={setting}
                data={{
                  name: "exam_date",
                  placeholder: "考試日期",
                  disabled,
                }}
              />
              <FormInputnumber
                className="w-8rem ml-2"
                setting={setting}
                data={{
                  name: "exam_score",
                  placeholder: "分數",
                  disabled,
                }}
              />
              <span className="ml-1">分</span>
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="expected_start_date"
              className={classNames(
                { "p-error": errors.expected_start_date },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              預計到職日期
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormCalendar
                className="w-full lg:w-6"
                setting={setting}
                data={{
                  name: "expected_start_date",
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="entry_date"
              className={classNames(
                { "p-error": errors.entry_date },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              入職日期
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormCalendar
                className="w-full lg:w-6"
                setting={setting}
                data={{
                  name: "entry_date",
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="expected_salary"
              className={classNames(
                { "p-error": errors.expected_salary },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              面試期望薪資
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputnumber
                className="w-full lg:w-6"
                setting={setting}
                data={{
                  name: "expected_salary",
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              htmlFor="introducer_mdid"
              className={classNames(
                {
                  "p-error":
                    errors.introducer_mdid || errors.introducer_relationship,
                },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              介紹人
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <div className="flex align-items-center">
                <FormDropdown
                  className="w-6"
                  setting={setting}
                  data={{
                    name: "introducer_mdid",
                    placeholder: "姓名",
                    options: optionData?.memberList ?? [],
                    optionLabel: "name_format",
                    optionValue: "id",
                    disabled,
                  }}
                />
                <FormTreeSelect
                  className="w-6 ml-2"
                  setting={setting}
                  data={{
                    name: "introducer_department",
                    placeholder: "部門",
                    options: optionData?.department ?? [],
                    filter: true,
                    disabled: true,
                  }}
                />
              </div>
              <div className="flex align-items-center mt-2">
                <FormDropdown
                  className="w-6"
                  setting={setting}
                  data={{
                    name: "introducer_jobtitle",
                    placeholder: "職稱",
                    options: optionData?.jobtitle ?? [],
                    optionLabel: "name",
                    optionValue: "id",
                    showClear: false,
                    disabled: true,
                  }}
                />
                <FormInputtext
                  className="w-6 ml-2"
                  setting={setting}
                  data={{
                    name: "introducer_relationship",
                    placeholder: "關係",
                    disabled,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="marriage"
              className={classNames(
                { "p-error": errors.marriage },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              婚姻狀況
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormDropdown
                className="w-full lg:w-6"
                setting={setting}
                data={{
                  name: "marriage",
                  options: option_marriage,
                  optionLabel: "label",
                  optionValue: "value",
                  showClear: false,
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              htmlFor="height"
              className={classNames(
                { "p-error": errors.height || errors.weight },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              身形
            </label>
            <div className="flex align-items-center col-12 sm:col-10 md:col-8 lg:col-5">
              <FormInputnumber
                className="w-6"
                setting={setting}
                data={{
                  name: "height",
                  placeholder: "身高",
                  useGrouping: false,
                  disabled,
                }}
              />
              <span className="white-space-nowrap ml-1">公分</span>
              <FormInputnumber
                className="w-6 ml-2"
                setting={setting}
                data={{
                  name: "weight",
                  placeholder: "體重",
                  useGrouping: false,
                  disabled,
                }}
              />
              <span className="white-space-nowrap ml-1">公斤</span>
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="blood_type"
              className={classNames(
                { "p-error": errors.blood_type },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              血型
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormDropdown
                className="w-full lg:w-6"
                setting={setting}
                data={{
                  name: "blood_type",
                  options: option_blood_type,
                  optionLabel: "label",
                  optionValue: "value",
                  showClear: false,
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="military_service"
              className={classNames(
                { "p-error": errors.military_service },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              兵役狀況
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormDropdown
                className="w-full lg:w-6"
                setting={setting}
                data={{
                  name: "military_service",
                  options: option_military_service,
                  optionLabel: "label",
                  optionValue: "value",
                  showClear: false,
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="isstay"
              className={classNames(
                { "p-error": errors.isstay },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              是否住宿
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormDropdown
                className="w-full lg:w-6"
                setting={setting}
                data={{
                  name: "isstay",
                  options: option_yesno,
                  optionLabel: "label",
                  optionValue: "value",
                  showClear: false,
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="isfood"
              className={classNames(
                { "p-error": errors.isfood },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              是否參加伙食
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <FormDropdown
                className="w-full lg:w-6"
                setting={setting}
                data={{
                  name: "isfood",
                  options: option_yesno,
                  optionLabel: "label",
                  optionValue: "value",
                  showClear: false,
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="health_condition"
              className={classNames(
                { "p-error": errors.health_condition },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              健康情形
            </label>
            <div className="col-12 sm:col-10">
              <FormInputtext
                setting={setting}
                data={{
                  name: "health_condition",
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              htmlFor="education_level"
              className={classNames(
                { "p-error": errors.education_level },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              教育程度
            </label>
            <div className="col-12 sm:col-10">
              <ModuleProfileEducationLevel
                options={{
                  setting,
                  getValues,
                  setValue,
                  trigger,
                  setIsFormUpdate,
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              htmlFor="language_level"
              className={classNames(
                { "p-error": errors.language_level },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              語言能力
            </label>
            <div className="col-12 sm:col-10">
              <ModuleProfileLanguageLevel
                options={{
                  setting,
                  getValues,
                  setValue,
                  trigger,
                  setIsFormUpdate,
                  disabled,
                  optionData,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              htmlFor="work_experience"
              className={classNames(
                { "p-error": errors.work_experience },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              重要經歷
            </label>
            <div className="col-12 sm:col-10">
              <ModuleProfileWorkExperience
                options={{
                  setting,
                  getValues,
                  setValue,
                  trigger,
                  setIsFormUpdate,
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              htmlFor="good_at_tool"
              className={classNames(
                { "p-error": errors.good_at_tool },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              擅長工具
            </label>
            <div className="col-12 sm:col-10">
              <ModuleProfileGoodAtTool
                options={{
                  setting,
                  getValues,
                  setValue,
                  trigger,
                  setIsFormUpdate,
                  disabled,
                  optionData,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="driver_license_type"
              className={classNames(
                { "p-error": errors.driver_license_type },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              駕照種類
            </label>
            <div className="col-12 sm:col-10">
              <FormInputtext
                setting={setting}
                data={{
                  name: "driver_license_type",
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              htmlFor="certificates"
              className={classNames(
                { "p-error": errors.certificates },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              相關證照
            </label>
            <div className="col-12 sm:col-10">
              <ModuleProfileCertificates
                options={{
                  setting,
                  getValues,
                  setValue,
                  trigger,
                  setIsFormUpdate,
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              htmlFor="immediate_family"
              className={classNames(
                { "p-error": errors.immediate_family },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              直系親屬
            </label>
            <div className="col-12 sm:col-10">
              <ModuleProfileImmediateFamily
                options={{
                  setting,
                  getValues,
                  setValue,
                  trigger,
                  setIsFormUpdate,
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              htmlFor="emergency_contact_name"
              className={classNames(
                {
                  "p-error":
                    errors.emergency_contact_name ||
                    errors.emergency_contact_cel ||
                    errors.emergency_contact_address,
                },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              緊急聯絡人
            </label>
            <div className="col-12 sm:col-10 md:col-8 lg:col-5">
              <div className="flex align-items-center">
                <FormInputtext
                  className="w-5"
                  setting={setting}
                  data={{
                    name: "emergency_contact_name",
                    placeholder: "姓名",
                    disabled,
                  }}
                />
                <FormInputtext
                  className="w-7 ml-2"
                  setting={setting}
                  data={{
                    name: "emergency_contact_cel",
                    placeholder: "電話",
                    disabled,
                  }}
                />
              </div>
              <div className="mt-2">
                <FormInputtext
                  className="w-full"
                  setting={setting}
                  data={{
                    name: "emergency_contact_address",
                    placeholder: "地址",
                    disabled,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              htmlFor="human_change_records"
              className={classNames(
                { "p-error": errors.human_change_records },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              人事動態記錄
            </label>
            <div className="col-12 sm:col-10">
              <div>
                <Button
                  type="button"
                  label="薪資比較圖"
                  className="p-button-info ml-auto px-3 py-1 mb-1"
                  onClick={() =>
                    openPop({
                      type: "salary_chart",
                      data: getValues("human_change_records"),
                    })
                  }
                />
              </div>
              <ModuleProfileHumanChangeRecords
                options={{
                  setting,
                  getValues,
                  setValue,
                  trigger,
                  watch,
                  setIsFormUpdate,
                  disabled,
                  optionData,
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              className={classNames(
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              附檔
            </label>
            <div className="col-12 sm:col-10">
              <ModuleUploadFile
                iptId="pop"
                zipName={getValues("name")}
                pathSecret={getValues("others_file_path")}
                pathPrefix={getValues("others_file")}
                data={getValues("others_fileList") ?? []}
                setData={setUploadData}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              htmlFor="driver_license_type"
              className={classNames(
                { "p-error": errors.driver_license_type },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              備註
            </label>
            <div className="col-12 sm:col-10">
              <FormInputtextarea
                setting={setting}
                data={{
                  name: "remark",
                  disabled,
                }}
              />
            </div>
          </div>

          <div className="field col-12 text-right">
            <Button
              type="button"
              label="取消"
              className="p-button-secondary p-button-outlined ml-auto px-3"
              onClick={() => route_navigate("/back/member/profile")}
            />
            {!disabled && (
              <Button type="submit" label="儲存" className="ml-2 px-3" />
            )}
          </div>
        </form>
      </div>

      {renderDialog({
        className: "w-11 lg:w-8 md:w-9 sm:w-10",
        children: (
          <>
            {isPop.salary_chart?.isOpen && (
              <ModulePopSalaryChart
                prePopOption={popOption}
                closePrePop={closePop}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
