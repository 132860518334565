import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { setUserControl, setUser } from "../../redux/slice/user";
import { CommonAPI, ControlcenterAPI } from "../../axios/api";
import moment from "moment";

import { useClearLocalStorage } from "../hooks";

import { Button } from "primereact/button";

export default function ModulePopCheckToken(props) {
  const { closePrePop } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redux_user = useSelector((state) => state.user);
  const clearLocalStorage = useClearLocalStorage();

  const logout = () => {
    closePrePop({ type: "refreshToken" });

    clearLocalStorage();
    navigate("/login");

    setTimeout(() => {
      window.location.href = "/login";
    }, 100);
  };

  const refreshToken = () => {
    dispatch(setProgress(true));

    let jsonData = {
      refresh_token:
        redux_user.userControl?.refresh_token ??
        redux_user.userDetail?.refresh_token,
    };

    CommonAPI.getNewAccessToken({
      data: jsonData,
    }).then((result) => {
      let { Response, access_token } = result;

      // 依照不同的回傳狀態，顯示提示訊息
      if (Response === 1) {
        let temp = { ...redux_user.userDetail };
        temp.now = moment().format("YYYY/MM/DD HH:mm:ss");
        temp.access_token = access_token;

        dispatch(setUser(temp));

        closePrePop({ type: "refreshToken" });
        dispatch(setProgress(false));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: "延長失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      <div className="px-3">
        <div className="w-full">
          <div className="text-center text-xl text-blue-400 mb-4">
            使用時間即將到期，請確認是否繼續使用
          </div>

          <div className="text-center">
            <Button
              className="p-button-danger px-4"
              type="button"
              label="否"
              onClick={() => logout()}
            />

            <Button
              className="p-button-success px-4 ml-6"
              type="button"
              label="是"
              onClick={() => refreshToken()}
            />
          </div>
        </div>
      </div>
    </>
  );
}
