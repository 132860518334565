import { useMultiRecords } from "../../../../../components/hooks";

import {
  FormInputtext,
  FormInputnumber,
  FormCalendar,
} from "../../../../../components/formElement";
import { option_isgraduation } from "../../../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function ModuleProfileWorkExperience({
  options: { setting, getValues, setValue, trigger, setIsFormUpdate, disabled },
}) {
  const { addDetailBlock, deleteMulti, replayMulti, renderMultiRecordsTb } =
    useMultiRecords({
      blockData: {
        work_experience: {
          name: "",
          title: "",
          content: "",
          start_date: null,
          end_date: null,
          resign_reason: "",
          salary: null,
          remark: "",
        },
      },
      updateForm: ({ name, data }) => {
        setValue(name, data);
        trigger(name);
        setIsFormUpdate(true);
      },
    });

  return (
    <>
      {renderMultiRecordsTb({
        columnLength: 2,
        data: getValues("work_experience") || [],
        columnKey: "work_experience",
        headTemplate: (
          <>
            <tr className="bg-tb-subtitle">
              <th>重要經歷</th>
              <th className="w-4rem">動作</th>
            </tr>
          </>
        ),
        bodyTemplate: ({ data, rowIndex }) => (
          <>
            <td>
              <div className="formgrid grid">
                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    服務公司名稱
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormInputtext
                      setting={setting}
                      data={{
                        name: `work_experience[${rowIndex}].name`,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    職位
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormInputtext
                      setting={setting}
                      data={{
                        name: `work_experience[${rowIndex}].title`,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    工作內容
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormInputtext
                      setting={setting}
                      data={{
                        name: `work_experience[${rowIndex}].content`,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    任職起訖日期
                  </label>
                  <div className="col-12 sm:col-8">
                    <div className="flex align-items-center">
                      <FormCalendar
                        className="w-full"
                        setting={setting}
                        data={{
                          name: `work_experience[${rowIndex}].start_date`,
                          dateFormat: "yy/mm",
                          view: "month",
                          showIcon: false,
                          disabled: disabled || data.flag === "delete",
                        }}
                      />
                      <span className="mx-1">-</span>
                      <FormCalendar
                        className="w-full"
                        setting={setting}
                        data={{
                          name: `work_experience[${rowIndex}].end_date`,
                          dateFormat: "yy/mm",
                          view: "month",
                          showIcon: false,
                          disabled: disabled || data.flag === "delete",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    離職原因
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormInputtext
                      setting={setting}
                      data={{
                        name: `work_experience[${rowIndex}].resign_reason`,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    薪給
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormInputnumber
                      setting={setting}
                      data={{
                        name: `work_experience[${rowIndex}].salary`,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    備註
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormInputtext
                      setting={setting}
                      data={{
                        name: `work_experience[${rowIndex}].remark`,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>
              </div>
            </td>
          </>
        ),
      })}
    </>
  );
}
