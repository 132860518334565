import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { ContractAPI } from "../../../../axios/api";
import SignaturePad from "react-signature-canvas";
import moment from "moment";

import { ModulePdfDownloadLink } from "../../../../components/exportPdf";
import { blobToFile, dataURLtoFile } from "../../../../service/common";

import { Button } from "primereact/button";

export default function ModulePopSign({
  prePopOption,
  closePrePop,
  closeSubPop,
  closeMainPop,
  setPreReload,
}) {
  const dispatch = useDispatch();
  const sigCanvas = useRef({});
  const [signImg, setSignImg] = useState(null);
  const [blobFile, setBlobFile] = useState(null);

  const clearSign = () => sigCanvas.current.clear();

  const saveSign = () => {
    dispatch(setProgress(true));

    let dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
      file = dataURLtoFile(dataURL, "onlineSign.png");
    setSignImg(dataURL);
  };

  useEffect(() => {
    if (!blobFile) return;

    let formData = new FormData();
    formData.append("id", Number(prePopOption.data.form.id));
    formData.append("typename", "member_sign_file");
    formData.append(
      "sign_file0",
      blobToFile({
        blob: blobFile,
        fileName: `${
          prePopOption.data.form.ch_last_name +
          prePopOption.data.form.ch_first_name
        }.pdf`,
      })
    );

    ContractAPI.signFileUpload({
      data: formData,
    }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "上傳成功",
            detail: "",
          })
        );

        setTimeout(() => {
          closeMainPop({ type: "contract" });
          closeSubPop({ type: "sign" });
          closePrePop({ type: "sign" });
          setPreReload((state) => ({ ...state, list: true }));
        }, 500);
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  }, [blobFile]);

  return (
    <div className="text-center px-3 pb-3">
      <div>
        <div className="text-xl text-blue-400 mb-2">
          請於框線內簽署『姓名』以及『當日日期』
        </div>
        <div>
          <SignaturePad
            ref={sigCanvas}
            backgroundColor="rgb(255, 255, 255)"
            canvasProps={{
              width: 450,
              height: 200,
              className: "sigCanvas border-1",
            }}
          />
        </div>

        <div className="flex align-items-center justify-content-center z-0">
          <div className="mr-1">範例：</div>
          <div>
            <div className="text-xl">王大明</div>
            <div>{moment().format("YYYY/MM/DD")}</div>
          </div>
        </div>
      </div>

      <div className="opacity-0 h-1rem max-h-1rem overflow-hidden">
        {signImg && (
          <ModulePdfDownloadLink
            optionData={prePopOption.data}
            memberData={prePopOption.data.form}
            signImg={signImg}
            setBlobFile={setBlobFile}
          />
        )}
      </div>

      <div className="w-full mt-4">
        <Button
          className="p-button-secondary px-4"
          type="button"
          label="取消"
          onClick={() => closePrePop({ type: "sign" })}
        />
        <Button
          className="p-button-warning px-4 ml-4"
          type="button"
          label="重置"
          onClick={() => clearSign()}
        />
        <Button
          className="px-4 ml-4"
          type="button"
          label="確定"
          onClick={() => saveSign()}
        />
      </div>
    </div>
  );
}
