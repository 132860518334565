import { useState, useEffect } from "react";
import { useMultiRecords, usePop } from "../../../../../components/hooks";

import ModulePopHumanChangeRecordsSalary from "./popHumanChangeRecordsSalary";
import ModuleUploadFile from "../../../../../components/uploadFile";
import {
  FormInputtext,
  FormDropdown,
  FormTreeSelect,
  FormInputnumber,
} from "../../../../../components/formElement";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function ModuleProfileHumanChangeRecords({
  options: {
    setting,
    getValues,
    setValue,
    trigger,
    watch,
    setIsFormUpdate,
    disabled,
    optionData,
    isLimitOne = false,
  },
}) {
  const { addDetailBlock, deleteMulti, replayMulti, renderMultiRecordsTb } =
    useMultiRecords({
      blockData: {
        human_change_records: {
          content: "",
          department: null,
          job_title: null,
          job_content: "",
          salary: null,
          remark: "",
          salary_info: [],
          salary_file: "",
          salary_fileList: [],
          salary_file_path: "",
        },
      },
      updateForm: ({ name, data }) => {
        setValue(name, data);
        trigger(name);
        if (setIsFormUpdate) setIsFormUpdate(true);
      },
    });
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "salary",
      title: () => "薪資結構",
      isOpen: false,
    },
  ]);
  // 監聽欄位
  let watchColumn = (getValues("human_change_records") || []).reduce(
    (pre, cur, index) => ({
      ...pre,
      [`departmentid_${index}`]: watch(
        `human_change_records[${index}].department`
      ),
    }),
    {}
  );
  const [uploadData, setUploadData] = useState({});

  useEffect(() => {
    if (!optionData) return;

    let human_change_records = getValues("human_change_records");
    human_change_records.forEach((records, rIdx) => {
      if (records.is_str) return;
      let match =
        optionData?.jobtitle?.find(
          (item) =>
            item.id === records.job_title || item.name === records.job_title
        ) ?? {};
      setValue(
        `human_change_records[${rIdx}].job_title`,
        match.id ?? records.job_title ?? null
      );
    });
  }, [watchColumn]);

  const updateSalary = ({ submitData, parentIdx }) => {
    let human_change_records = getValues("human_change_records");
    human_change_records[parentIdx].salary = submitData.salary_info.reduce(
      (pre, cur) => (pre += cur.flag !== "delete" ? cur.salary : 0),
      0
    );
    human_change_records[parentIdx].salary_info = submitData.salary_info;
    setValue(
      `human_change_records[${parentIdx}].salary`,
      human_change_records[parentIdx].salary
    );
    setValue("human_change_records", human_change_records);
    trigger([
      "human_change_records",
      `human_change_records[${parentIdx}].salary`,
    ]);
  };

  useEffect(() => {
    if (Object.keys(uploadData).length === 0) return;

    let human_change_records = getValues("human_change_records");
    Object.entries(uploadData).forEach(([index, value]) => {
      human_change_records[index].uploadData = value;
    });
    setValue("human_change_records", human_change_records);
    trigger(["human_change_records"]);
  }, [uploadData]);

  return (
    <>
      {renderMultiRecordsTb({
        isLimitOne,
        columnLength: 2,
        data: getValues("human_change_records") || [],
        columnKey: "human_change_records",
        headTemplate: (
          <>
            <tr className="bg-tb-subtitle">
              <th>人事動態記錄</th>
              {!isLimitOne && <th className="w-4rem">動作</th>}
            </tr>
          </>
        ),
        bodyTemplate: ({ data, rowIndex }) => {
          const file_pathSecret = getValues(
              `human_change_records[${rowIndex}].salary_file_path`
            ),
            file_pathPrefix = getValues(
              `human_change_records[${rowIndex}].salary_file`
            ),
            file_data =
              getValues(`human_change_records[${rowIndex}].salary_fileList`) ??
              [];

          return (
            <>
              <td>
                <div className="formgrid grid">
                  <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                    <label
                      htmlFor="type"
                      className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                    >
                      紀錄說明
                    </label>
                    <div className="col-12 sm:col-8">
                      <FormInputtext
                        setting={setting}
                        data={{
                          name: `human_change_records[${rowIndex}].content`,
                          disabled: disabled || data.flag === "delete",
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                    <label
                      htmlFor="type"
                      className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                    >
                      部門
                    </label>
                    <div className="col-12 sm:col-8">
                      {data.is_str ? (
                        <FormInputtext
                          setting={setting}
                          data={{
                            name: `human_change_records[${rowIndex}].department`,
                            disabled: disabled || data.flag === "delete",
                          }}
                        />
                      ) : (
                        <FormTreeSelect
                          setting={setting}
                          data={{
                            name: `human_change_records[${rowIndex}].department`,
                            placeholder: "部門",
                            options: optionData?.department ?? [],
                            filter: true,
                            disabled: disabled || data.flag === "delete",
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                    <label
                      htmlFor="type"
                      className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                    >
                      職稱
                    </label>
                    <div className="col-12 sm:col-8">
                      {data.is_str ? (
                        <FormInputtext
                          setting={setting}
                          data={{
                            name: `human_change_records[${rowIndex}].job_title`,
                            disabled: disabled || data.flag === "delete",
                          }}
                        />
                      ) : (
                        <FormDropdown
                          setting={setting}
                          data={{
                            name: `human_change_records[${rowIndex}].job_title`,
                            placeholder: "職稱",
                            options:
                              (optionData?.jobtitle || []).filter(
                                (item) =>
                                  item.departmentid ===
                                  getValues(
                                    `human_change_records[${rowIndex}].department`
                                  )
                              ) ?? [],
                            optionLabel: "name",
                            optionValue: "id",
                            showClear: false,
                            disabled: disabled || data.flag === "delete",
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                    <label
                      htmlFor="type"
                      className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                    >
                      業務內容
                    </label>
                    <div className="col-12 sm:col-8">
                      <FormInputtext
                        setting={setting}
                        data={{
                          name: `human_change_records[${rowIndex}].job_content`,
                          disabled: disabled || data.flag === "delete",
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                    <label
                      htmlFor="type"
                      className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                    >
                      {isLimitOne && (
                        <span className="text-red-400 mr-1">*</span>
                      )}
                      薪資
                    </label>
                    <div className="flex align-items-center col-12 sm:col-8">
                      <FormInputnumber
                        className="w-full"
                        setting={setting}
                        data={{
                          name: `human_change_records[${rowIndex}].salary`,
                          disabled: true,
                        }}
                      />
                      <Button
                        type="button"
                        label="結構"
                        className="p-button-warning p-button-sm w-5rem ml-2"
                        onClick={() =>
                          openPop({
                            type: "salary",
                            data: {
                              parentIdx: rowIndex,
                              data:
                                getValues("human_change_records")?.[rowIndex]
                                  .salary_info ?? [],
                            },
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                    <label
                      htmlFor="type"
                      className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                    >
                      備註
                    </label>
                    <div className="col-12 sm:col-8">
                      <FormInputtext
                        setting={setting}
                        data={{
                          name: `human_change_records[${rowIndex}].remark`,
                          disabled: disabled,
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-column align-items-start sm:flex-row field col-12 md:col-6 my-1">
                    <label
                      htmlFor="type"
                      className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
                    >
                      附檔
                    </label>
                    <div className="col-12 sm:col-8">
                      <ModuleUploadFile
                        iptId={`pop_human_${rowIndex}`}
                        isMulti={false}
                        zipName={getValues("name")}
                        pathSecret={file_pathSecret}
                        pathPrefix={file_pathPrefix}
                        data={file_data}
                        setData={(e) =>
                          setUploadData((state) => ({
                            ...state,
                            [rowIndex]: e,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </td>
            </>
          );
        },
      })}

      {renderDialog({
        className: "w-11 lg:w-6 md:w-8 sm:w-10",
        children: (
          <>
            {isPop.salary?.isOpen && (
              <>
                <ModulePopHumanChangeRecordsSalary
                  prePopOption={popOption}
                  closePrePop={closePop}
                  options={{ updateSalary, disabled }}
                />
              </>
            )}
          </>
        ),
      })}
    </>
  );
}
