import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { useHookForm } from "../../../../components/hooks";
import { MemberAPI } from "../../../../axios/api";

import { FormDropdown } from "../../../../components/formElement";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function ModulePopMemberAccount({
  prePopOption,
  closePrePop,
  setPreReload,
}) {
  const dispatch = useDispatch();
  // 表單欄位預設值
  const defaultValues = {
    type: 2,
  };
  const { handleSubmit, yup, setSchema, errors, setting } = useHookForm({
    defaultValues,
  });

  useEffect(() => {
    setSchema(yup.object({}).required());
  }, []);

  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    let promise,
      jsonData = {
        id: prePopOption.data.id,
      };

    if (prePopOption.data.isClosed) {
      promise = MemberAPI.disableAccount({ data: jsonData });
    } else if (prePopOption.data.account_is_enabled === false) {
      jsonData.type = submitData.type;
      promise = MemberAPI.reOpenAccount({ data: jsonData });
    } else {
      jsonData.type = submitData.type;
      promise = MemberAPI.setMemberAccount({ data: jsonData });
    }

    promise.then((result) => {
      let { Response, message } = result;

      closePrePop({ type: "account" });

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: `帳號${prePopOption.data.isClosed ? "關閉" : "開通"}成功`,
            detail: "",
          })
        );
        setPreReload((state) => ({ ...state, list: true }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `帳號${prePopOption.data.isClosed ? "關閉" : "開通"}失敗`,
            detail: message,
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      <div className="text-center px-3 pb-3">
        <div
          className={classNames(
            "inline-flex align-items-center justify-content-center w-5rem h-5rem text-6xl text-white border-circle",
            {
              "bg-red-500": prePopOption.data.account_is_create,
              "bg-orange-500": !prePopOption.data.account_is_create,
            }
          )}
        >
          ！
        </div>
        <h1 className="mt-4 mb-3">
          確定{prePopOption.data.account_is_create ? "關閉" : "開通"}帳號?
        </h1>
        <div className="text-lg mb-3">
          {prePopOption.data.ch_last_name}
          {prePopOption.data.ch_first_name}
          <span className="mx-2">-</span>
          {prePopOption.data.username}
        </div>

        {!prePopOption.data.isClosed && (
          <div className="text-orange-400 mb-5">* 預設密碼為『員工編號』</div>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          {!prePopOption.data.isClosed && (
            <div className="flex align-items-center justify-content-center">
              <label
                htmlFor="type"
                className={classNames(
                  { "p-error": errors.type },
                  "white-space-nowrap mr-2"
                )}
              >
                <span className="text-red-400 mr-1">*</span>身分
              </label>
              <div className="w-10rem">
                <FormDropdown
                  setting={setting}
                  data={{
                    name: "type",
                    options: [
                      {
                        label: "一般人員",
                        value: 1,
                      },
                      {
                        label: "管理人員",
                        value: 2,
                      },
                      {
                        label: "總經理",
                        value: 4,
                      },
                    ],
                    optionLabel: "label",
                    optionValue: "value",
                    showClear: false,
                  }}
                />
              </div>
            </div>
          )}

          <div className="w-full mt-4">
            <Button
              className="p-button-secondary px-4"
              type="button"
              label="取消"
              onClick={() => closePrePop({ type: "account" })}
            />
            <Button className="ml-4 px-4" type="submit" label="確定" />
          </div>
        </form>
      </div>
    </>
  );
}
