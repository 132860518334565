import { useNavigate } from "react-router-dom";

import ComCss from "../../styles/components/breadCrumb.module.scss";

import { classNames } from "primereact/utils";

export default function ModuleBreadCrumb(props) {
  const { className, crumbItem = [] } = props;
  const route_navigate = useNavigate();

  return (
    <>
      <div className={classNames(ComCss["crumb-wrap"], className)}>
        {crumbItem.map((item, index) => (
          <div
            key={`crumb_${item.key}`}
            className={classNames(ComCss["crumb-item"])}
          >
            {index !== 0 && <span>{">"}</span>}
            <a
              className={classNames({
                [ComCss["crumb-label"]]: !item.command && !item.href,
                [ComCss["crumb-link"]]: item.command || item.href,
              })}
              // href="#"
              onClick={() =>
                item.command ? item.command() : route_navigate(item.href)
              }
            >
              {index === 0 && <i className="pi pi-home mr-1"></i>}
              {item.label}
            </a>
          </div>
        ))}
      </div>
    </>
  );
}
