import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { setUserControl, setUser } from "../../redux/slice/user";

import { Button } from "primereact/button";

export default function ModulePopCheckPassword({ closePrePop }) {
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const redux_user = useSelector((state) => state.user);

  return (
    <>
      <div className="px-3">
        <div className="w-full">
          <div className="text-center text-xl text-blue-400 line-height-4 mb-4">
            為了防止不法登入
            <br />
            請「務必」定期更改登入密碼
          </div>

          <div className="text-center">
            <Button
              className="p-button-danger px-4"
              type="button"
              label="暫時不要"
              onClick={() => {
                dispatch(
                  setUser({
                    ...redux_user.userDetail,
                    is_need_change_password: false,
                  })
                );
                closePrePop({ type: "checkPassword" });
              }}
            />

            <Button
              className="p-button-success px-4 ml-6"
              type="button"
              label="前往變更"
              onClick={() => {
                route_navigate("/back/password");
                closePrePop({ type: "checkPassword" });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
