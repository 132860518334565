import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { ContractAPI } from "../../../../axios/api";

import { Button } from "primereact/button";

export default function ModulePopUploadConfirm({
  prePopOption,
  closePrePop,
  closeMainPop,
  setPreReload,
  memberId,
}) {
  const dispatch = useDispatch();
  const [isPdf, setIsPdf] = useState(null);

  useEffect(() => {
    let isValid = prePopOption.data.file.name.split(".").pop() === "pdf";
    setIsPdf(isValid);
  }, [prePopOption.data]);

  const saveData = () => {
    if (!isPdf) return;

    dispatch(setProgress(true));

    let formData = new FormData();
    formData.append("id", memberId);
    formData.append("typename", "member_labor_contract");
    formData.append("labor_contract_file0", prePopOption.data.file);

    ContractAPI.laborContractFileUpload({
      data: formData,
    }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "上傳成功",
            detail: "",
          })
        );
        setPreReload((state) => ({ ...state, list: true }));
        closePrePop({ type: "confirm" });
        closeMainPop({ type: "contract" });
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }

      dispatch(setProgress(false));
    });
  };

  return (
    <>
      <div className="text-center px-3 pb-3">
        <div className="inline-flex align-items-center justify-content-center w-5rem h-5rem text-6xl text-white border-circle bg-red-500">
          ！
        </div>
        {isPdf && <h1 className="mt-4 mb-6">確定要上傳?</h1>}
        {isPdf === false && (
          <h1 className="mt-4 mb-6">上傳檔案僅限 PDF 格式</h1>
        )}

        <div className="w-full">
          <Button
            className="p-button-secondary px-4"
            type="button"
            label={isPdf === false ? "關閉" : "取消"}
            onClick={() => closePrePop({ type: "confirm" })}
          />
          {isPdf && (
            <Button
              className="ml-4 px-4"
              type="button"
              label="確定"
              onClick={() => saveData()}
            />
          )}
        </div>
      </div>
    </>
  );
}
