import ReactDOM from "react-dom/client";

// redux
import { Provider } from "react-redux";
import store from "./redux/store";

import Wrap from "./wrap";
import App from "./App";
import CreatRoute from "./routes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Wrap>
      <App />
      <CreatRoute />
    </Wrap>
  </Provider>
);
