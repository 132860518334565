import axios from "axios";
import { system_api } from "../../service/system";

const instance = axios.create({
  // 路徑前綴
  baseURL: process.env.REACT_APP_ROOT_URL,
  headers: {
    "Content-Type": "text/plain;charset=UTF-8",
  },
  // 超時設定
  timeout: system_api.timeout,
});

// axios 發送 request 以前
instance.interceptors.request.use((config) => {
  // config.headers.token = 'token';
  // console.log(config);
  try {
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      config.headers["X-Auth-Token"] = user.access_token;
    }
  } catch (e) {}

  return config;
});

// axios 回傳 response 以前
instance.interceptors.response.use(
  (result) => {
    /* 
      status: http status
      data: 回傳資料
    */
    const { status } = result;

    if (status >= 200 && status <= 299) {
      return result;
    } else {
      // api 有回應，但未成功取得資料
      return Promise.reject(result);
    }
  },
  (err) => {
    // api 呼叫錯誤
    return Promise.reject(err);
  }
);

export default instance;
