import moment from "moment";

import {
  FormInputtext,
  FormInputnumber,
  FormCalendar,
  FormDropdown,
  FormCheckbox,
} from "../components/formElement";
import { system_table } from "./system";
import { parseExcelDateToDate } from "./common";

import { Dropdown } from "primereact/dropdown";

// 產生列表欄位
export function createColumns({
  pageSize = system_table.size, // 每頁幾筆資料
  type = "", // 類型 (list: 列表、import: 匯入)
  saveType = "no", // 儲存類型 (no: 無、single: 單筆儲存、multi: 多筆儲存)
  saveFun = () => {}, // 儲存方法
  activeTemplate = [], // 生效的模組資料 (匯入匯出)
  defaultTemplate = {}, // 預設的模組欄位設定
  formSetting = {}, // 表單設定 (react-hook-form)
  frozenSetting = { frozen: false, align: "left", column: [] }, // 凍結設定
  optionData = {}, // 選單資料
  disabled = false, // 是否禁止編輯
} = {}) {
  let temp = [];
  activeTemplate.forEach((item, index) => {
    let columnSetting = defaultTemplate[item.apiKey] ?? {
        checked: true,
        isShow: true,
        uiType: "string",
        width: 150,
        listUI: false,
        importUI: true,
      }, // 預設的欄位設定
      isDisabled = columnSetting[`${type}Disabled`] || disabled,
      isUI = columnSetting[`${type}UI`];

    if (!item.checked || !columnSetting.isShow) return;

    let uiSetting = {
      field: item.apiKey,
      header: item.name,
      className: columnSetting.width
        ? `flex-grow-1 flex-basis-${columnSetting.width / 10} p-1 ${
            !isUI ? "flex align-items-center px-2" : ""
          }`
        : "",
      style: {
        width: columnSetting.width ?? "auto",
        minWidth:
          columnSetting.minWidth ??
          (columnSetting.width ? columnSetting.width - 20 : 100),
      },
    };

    // 凍結欄位設定
    if (
      frozenSetting.frozen &&
      frozenSetting.column.indexOf(item.apiKey) !== -1
    ) {
      uiSetting.frozen = frozenSetting.frozen;
      uiSetting.alignFrozen = frozenSetting.align;

      // 凍結分割陰影
      if (
        (frozenSetting.align === "left" &&
          frozenSetting.column.indexOf(item.apiKey) ===
            frozenSetting.column.length - 1) ||
        (frozenSetting.align === "right" &&
          frozenSetting.column.indexOf(item.apiKey) === 0)
      ) {
        uiSetting.style.boxShadow = `${
          frozenSetting.align === "left" ? "" : "-"
        }3px 1px 5px rgb(0 0 0 / 5%)`;
      }
    }
    let tempColumns = {};
    if (!isUI) {
      tempColumns = {
        ...uiSetting,
        body: (data) => (
          <>
            {columnSetting.uiType === "select" ? (
              <>
                {
                  optionData?.[`${columnSetting.options}_obj`]?.[
                    data[item.apiKey]
                  ]?.showLabel
                }
              </>
            ) : columnSetting.uiType === "date" &&
              columnSetting.dateFormatLabel ? (
              <>
                {data[item.apiKey]
                  ? moment(data[item.apiKey]).format(
                      columnSetting.dateFormatLabel
                    )
                  : null}
              </>
            ) : columnSetting.uiType === "number" ? (
              <>{data[item.apiKey]}</>
            ) : (
              <>
                {!data[item.apiKey]
                  ? ""
                  : typeof data[item.apiKey] !== "object"
                  ? data[item.apiKey]
                  : JSON.stringify(data[item.apiKey])}
              </>
            )}
          </>
        ),
      };
    } else if (columnSetting.uiType === "string") {
      tempColumns = {
        ...uiSetting,
        body: (data, { rowIndex }) => (
          <>
            <FormInputtext
              className="p-sm"
              setting={formSetting}
              data={{
                name: `data[${rowIndex % pageSize}].${item.apiKey}`,
                disabled: isDisabled || data.flag === "delete",
                saveFun: () =>
                  saveFun({
                    rowIndex: rowIndex % pageSize,
                    apiKey: item.apiKey,
                  }),
              }}
            />
          </>
        ),
      };
    } else if (columnSetting.uiType === "number") {
      tempColumns = {
        ...uiSetting,
        body: (data, { rowIndex }) => (
          <>
            <FormInputnumber
              className="p-sm"
              setting={formSetting}
              data={{
                name: `data[${rowIndex % pageSize}].${item.apiKey}`,
                disabled: isDisabled || data.flag === "delete",
                saveFun: () =>
                  saveFun({
                    rowIndex: rowIndex % pageSize,
                    apiKey: item.apiKey,
                  }),
              }}
            />
          </>
        ),
      };
    } else if (columnSetting.uiType === "date") {
      tempColumns = {
        ...uiSetting,
        body: (data, { rowIndex }) => (
          <>
            <FormCalendar
              className="p-sm"
              setting={formSetting}
              data={{
                name: `data[${rowIndex % pageSize}].${item.apiKey}`,
                dateFormat: columnSetting.dateFormatUI,
                disabled: isDisabled || data.flag === "delete",
                showTime: !!columnSetting.showTime,
                showSeconds: !!columnSetting.showSeconds,
                saveFun: () =>
                  saveFun({
                    rowIndex: rowIndex % pageSize,
                    apiKey: item.apiKey,
                  }),
              }}
            />
          </>
        ),
      };
    } else if (columnSetting.uiType === "select") {
      tempColumns = {
        ...uiSetting,
        body: (data, { rowIndex }) => (
          <>
            <FormDropdown
              className="p-sm w-full"
              panelClassName="p-sm"
              setting={formSetting}
              data={{
                name: `data[${rowIndex % pageSize}].${item.apiKey}`,
                options: optionData[columnSetting.options],
                optionLabel: "showLabel",
                optionValue: "showValue",
                disabled: isDisabled || data.flag === "delete",
                saveFun: () =>
                  saveFun({
                    rowIndex: rowIndex % pageSize,
                    apiKey: item.apiKey,
                  }),
                // showClear: false,
              }}
            />
          </>
        ),
      };
    } else if (columnSetting.uiType === "check") {
      tempColumns = {
        ...uiSetting,
        body: (data, { rowIndex }) => (
          <>
            <FormCheckbox
              className="m-0"
              setting={formSetting}
              data={{
                name: `data[${rowIndex % pageSize}].${item.apiKey}`,
                label: "",
                type: "checked",
                value: true,
                disabled: isDisabled || data.flag === "delete",
                saveFun: () =>
                  saveFun({
                    rowIndex: rowIndex % pageSize,
                    apiKey: item.apiKey,
                  }),
              }}
            />
          </>
        ),
      };
    } else if (columnSetting.uiType === "pallet") {
      tempColumns = {
        ...uiSetting,
        body: (data, { rowIndex }) => <>pallet</>,
      };
    }

    temp.push(tempColumns);
  });
  return temp;
}

// 產生列表驗證設定
export function createSchema({
  type = "", // 類型(list: 列表、import: 匯入)
  activeTemplate = [], // 生效的模組資料(匯入匯出)
  defaultTemplate = {}, // 預設的模組欄位設定
  yup = null, // 表單驗證設定(react-hook-form)
} = {}) {
  let temp = {};
  activeTemplate.forEach((item, index) => {
    let columnSetting = defaultTemplate[item.apiKey] ?? {
        checked: true,
        isShow: true,
        uiType: "string",
        [`${type}UI`]: false,
      }, // 預設的欄位設定
      isUI = columnSetting[`${type}UI`];

    if (!item.checked || !columnSetting.isShow) return;

    // 有使用表單 UI 元件 且 必填 才需產生驗證設定
    if (!isUI || !columnSetting.required) return;

    if (columnSetting.uiType === "string") {
      temp[item.apiKey] = yup.string().required("必填");
    } else if (columnSetting.uiType === "number") {
      let tempSchema = yup.number().required("必填").nullable();
      if (columnSetting.min >= 0)
        tempSchema = tempSchema.moreThan(
          columnSetting.min,
          `需大於${columnSetting.min}`
        );
      temp[item.apiKey] = tempSchema;
    } else if (columnSetting.uiType === "date") {
      temp[item.apiKey] = yup.string().required("必填").nullable();
    } else if (columnSetting.uiType === "select") {
      temp[item.apiKey] = yup.string().required("必填").nullable();
    } else if (columnSetting.uiType === "check") {
      temp[item.apiKey] = yup.string().required("必填");
    }
  });

  return temp;
}

// 設定儲存資料 & 型態
export function formatTemplateColumns({
  type = "import", // import: 匯入、export: 匯出
  template = {}, // 模組資料
  data = [], // 儲存的資料
} = {}) {
  let tempData = [],
    tempColumns = Object.values(template);

  data.forEach((item) => {
    let row = {};
    tempColumns.forEach((column) => {
      let val = item[column[type === "import" ? "value" : "apiKey"]];

      if (val !== "" && val !== undefined && val !== null) {
        if (
          column.uiType === "number" ||
          (column.uiType === "select" && column.optionType === "number")
        ) {
          val = Number(val);
        } else if (column.uiType === "date" && column.dateFormatLabel) {
          if (typeof val === "number") val = parseExcelDateToDate(val);
          val = moment(val).format(column.dateFormatLabel);
        } else if (column.uiType === "check") {
          val = Boolean(val);
        } else {
          val = String(val);
        }

        if (type === "import" || (type === "export" && column.listUI)) {
          row[column.apiKey] = val;
        }
      }
    });
    tempData.push(row);
  });

  return tempData;
}

export const ModuleTablePaginator = {
  layout:
    "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
  RowsPerPageDropdown: (options) => {
    const dropdownOptions = [
      { label: 10, value: 10 },
      { label: 25, value: 25 },
      { label: 50, value: 50 },
      // { label: "All", value: options.totalRecords },
    ];

    return (
      <Dropdown
        value={options.value}
        options={dropdownOptions}
        onChange={options.onChange}
      />
    );
  },
};
