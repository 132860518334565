import { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { useFilterList, useConfirm, usePop } from "../hooks";

import { system_table } from "../../service/system";

import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

/*
  @params keyObj: { id: 資料的唯一值, label: 資料呈現的文字, node: 節點名稱 }
*/
function useTreeList({
  keyObj = {},
  action = {},
  ModuleEditForm = <></>,
  formOptions,
}) {
  const dispatch = useDispatch();
  const [treeData, setTreeData] = useState({
    term: "",
    data: [],
  });
  const [treeColumns, setTreeColumns] = useState([]);
  const { getFilterTreeData } = useFilterList();
  const { confirmOption, setConfirmOption, renderConfirm } = useConfirm();
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "form",
      title: (props) => `${props.data?.data?.id ? "編輯" : "新增"}`,
      isOpen: false,
    },
  ]);

  const listData = useMemo(
    () =>
      getFilterTreeData({
        data: treeData.data,
        filterKey: "name",
        serachTerm: treeData.term,
      }) || [],
    [treeData]
  );

  const deleteAction = ({ data }) => {
    dispatch(setProgress(true));

    const getIds = ({ childs }) => {
      let reVal = [];
      childs.forEach((item) => {
        if (item.children.length > 0)
          reVal = [...reVal, ...getIds({ childs: item.children })];
        reVal = [...reVal, item.data.id];
      });
      return reVal;
    };
    let ids = getIds({ childs: [data] });

    action.delete({ ids }).then((result) => {
      let { Response } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "成功刪除",
            detail: "",
          })
        );
        action.reload();
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: "刪除失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  const addRoot = () => openPop({ type: "form", data: { parentcode: "root" } });

  const renderList = () => {
    return (
      <>
        {renderConfirm}

        <div className="flex align-items-center mb-2">
          <div className="flex align-items-center mb-1 px-2">
            <InputText
              value={treeData.term}
              placeholder="關鍵字搜尋"
              onChange={(e) =>
                setTreeData((state) => ({ ...state, term: e.target.value }))
              }
            />
            <Button
              type="button"
              icon="pi pi-refresh"
              className="p-button-secondary p-button-sm ml-2"
              onClick={() => setTreeData((state) => ({ ...state, term: "" }))}
            />
          </div>
          <div className="text-red-400 text-right ml-auto">
            * 『根結點』以及『使用中的{keyObj.node.label}』不能刪除
          </div>
        </div>
        <TreeTable
          size="small"
          value={listData}
          emptyMessage={system_table.empty}
        >
          {treeColumns?.map((col) => (
            <Column key={`${col.field}_${col.header}`} {...col} />
          ))}
          <Column
            field="id"
            header="進階"
            className="w-14rem sm:w-20rem p-2"
            body={(data) => (
              <>
                <div className="flex flex-wrap">
                  <Button
                    className="p-button-sm w-6rem"
                    type="button"
                    label={`編輯${keyObj.node.label}`}
                    onClick={() =>
                      openPop({
                        type: "form",
                        data: { ...data, parentcode: "self" },
                      })
                    }
                  />
                  <Button
                    className="p-button-warning p-button-sm w-6rem ml-1"
                    type="button"
                    label={`新增子${keyObj.node.label}`}
                    onClick={() =>
                      openPop({
                        type: "form",
                        data: { parentcode: data.data.code },
                      })
                    }
                  />
                  {!data.isRoot && (
                    <Button
                      id={`button_delete_${data.data.code}`}
                      className="p-button-danger p-button-sm w-6rem mt-1 sm:mt-0 sm:ml-1"
                      type="button"
                      label={`刪除${keyObj.node.label}`}
                      onClick={() =>
                        setConfirmOption({
                          setting: {
                            target: document.getElementById(
                              `button_delete_${data.data.code}`
                            ),
                            message: "確認是否刪除?",
                            icon: "pi pi-exclamation-triangle",
                            visible: !confirmOption.setting.visible,
                            acceptLabel: "是",
                            rejectLabel: "否",
                            accept: () => deleteAction({ data }),
                            reject: () => null,
                          },
                          reOpen: confirmOption.setting.visible,
                        })
                      }
                      disabled={data.data.is_use === 1}
                    />
                  )}
                </div>
              </>
            )}
          ></Column>
        </TreeTable>

        {renderDialog({
          className: "w-11 md:w-10 lg:w-8 max-w-30rem",
          children: (
            <>
              {isPop.form?.isOpen && (
                <ModuleEditForm
                  closePrePop={closePop}
                  prePopOption={popOption}
                  formOptions={formOptions}
                  reloadAction={action.reload}
                />
              )}
            </>
          ),
        })}
      </>
    );
  };

  return { treeData, setTreeData, setTreeColumns, renderList, addRoot };
}

export default useTreeList;
