export default {
  // 人員個人資料
  getMemberDetailsList: "BackEnd/getMemberDetailsList",
  getMemberDetailsData: "BackEnd/getMemberDetailsData",
  setMemberDetailsData: "BackEnd/setMemberDetailsData",
  updateMemberDetailsData: "BackEnd/updateMemberDetailsData",
  deleteMemberDetailsData: "BackEnd/deleteMemberDetailsData",
  checkUsername: "BackEnd/checkUsername",
  setMemberAccount: "BackEnd/setMemberAccount",
  disableAccount: "BackEnd/disableAccount",
  reOpenAccount: "BackEnd/reOpenAccount",
  othersFileUpload: "BackEnd/othersFileUpload",
  othersDeleteFile: "BackEnd/othersDeleteFile",
  humanChangeRecordsFileUpload: "BackEnd/humanChangeRecordsFileUpload",
  humanChangeRecordsDeleteFile: "BackEnd/humanChangeRecordsDeleteFile",

  // 人員樹狀圖
  getMemberTreeList: "BackEnd/getMemberTreeList",
  setMemberTree: "BackEnd/setMemberTree",
  updateMemberTree: "BackEnd/updateMemberTree",
};
