import { useMultiRecords } from "../../../../../components/hooks";

import {
  FormInputtext,
  FormCalendar,
} from "../../../../../components/formElement";
import { option_isgraduation } from "../../../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function ModuleProfileImmediateFamily({
  options: { setting, getValues, setValue, trigger, setIsFormUpdate, disabled },
}) {
  const { addDetailBlock, deleteMulti, replayMulti, renderMultiRecordsTb } =
    useMultiRecords({
      blockData: {
        immediate_family: {
          name: "",
          title: "",
          job: "",
          birthday: "",
          remark: "",
        },
      },
      updateForm: ({ name, data }) => {
        setValue(name, data);
        trigger(name);
        setIsFormUpdate(true);
      },
    });

  return (
    <>
      {renderMultiRecordsTb({
        columnLength: 2,
        data: getValues("immediate_family") || [],
        columnKey: "immediate_family",
        headTemplate: (
          <>
            <tr className="bg-tb-subtitle">
              <th>直系親屬</th>
              <th className="w-4rem">動作</th>
            </tr>
          </>
        ),
        bodyTemplate: ({ data, rowIndex }) => (
          <>
            <td>
              <div className="formgrid grid">
                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    名稱
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormInputtext
                      setting={setting}
                      data={{
                        name: `immediate_family[${rowIndex}].name`,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    稱謂
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormInputtext
                      setting={setting}
                      data={{
                        name: `immediate_family[${rowIndex}].title`,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    職業
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormInputtext
                      setting={setting}
                      data={{
                        name: `immediate_family[${rowIndex}].job`,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    出生日期
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormCalendar
                      className="w-full"
                      setting={setting}
                      data={{
                        name: `immediate_family[${rowIndex}].birthday`,
                        showIcon: false,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 md:col-6 my-1">
                  <label
                    htmlFor="type"
                    className="col-12 sm:col-4 text-left sm:text-right mb-1 sm:mb-0"
                  >
                    備註
                  </label>
                  <div className="col-12 sm:col-8">
                    <FormInputtext
                      setting={setting}
                      data={{
                        name: `immediate_family[${rowIndex}].remark`,
                        disabled: disabled || data.flag === "delete",
                      }}
                    />
                  </div>
                </div>
              </div>
            </td>
          </>
        ),
      })}
    </>
  );
}
