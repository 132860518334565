import { StyleSheet, Font } from "@react-pdf/renderer";

import font_300 from "../../../fonts/NotoSansTC_300.ttf";
import font_600 from "../../../fonts/NotoSansTC_600.ttf";
Font.register({
  family: "customerFont_300",
  src: font_300,
});
Font.register({
  family: "customerFont_600",
  src: font_600,
});

Font.registerHyphenationCallback((word) => [word]);

export const styles = StyleSheet.create({
  page: {
    margin: "25px 45px",
    fontFamily: "customerFont_300",
    backgroundColor: "#FFFFFF",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
  },
  flexWrap: {
    flexWrap: "wrap",
  },
  flexAuto: {
    flex: "1 1 auto",
  },
  aiStart: {
    alignItems: "flex-start",
  },
  aiCenter: {
    alignItems: "center",
  },
  aiEnd: {
    alignItems: "flex-end",
  },
  jcCenter: {
    justifyContent: "center",
  },
  jcEnd: {
    justifyContent: "flex-end",
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  wordBreak: {
    wordBreak: "break-all",
  },
  wFull: {
    width: "100%",
  },
  w_95: {
    width: "95%",
  },
  w_9: {
    width: "90%",
  },
  w_85: {
    width: "85%",
  },
  w_8: {
    width: "80%",
  },
  w_75: {
    width: "75%",
  },
  w_7: {
    width: "70%",
  },
  w_6: {
    width: "60%",
  },
  w_55: {
    width: "55%",
  },
  w_5: {
    width: "50%",
  },
  w_45: {
    width: "45%",
  },
  w_4: {
    width: "40%",
  },
  w_33: {
    width: "33.33%",
  },
  w_3: {
    width: "30%",
  },
  w_25: {
    width: "25%",
  },
  w_2: {
    width: "20%",
  },
  w_1: {
    width: "10%",
  },
  w_500px: {
    width: "500px",
  },
  w_450px: {
    width: "450px",
  },
  w_400px: {
    width: "400px",
  },
  w_330px: {
    width: "330px",
  },
  w_275px: {
    width: "275px",
  },
  w_250px: {
    width: "250px",
  },
  w_225px: {
    width: "225px",
  },
  w_200px: {
    width: "200px",
  },
  w_150px: {
    width: "150px",
  },
  w_120px: {
    width: "120px",
  },
  w_100px: {
    width: "100px",
  },
  w_98px: {
    width: "98px",
  },
  w_90px: {
    width: "90px",
  },
  w_65px: {
    width: "65px",
  },
  w_60px: {
    width: "60px",
  },
  w_55px: {
    width: "55px",
  },
  w_50px: {
    width: "50px",
  },
  w_45px: {
    width: "45px",
  },
  w_40px: {
    width: "40px",
  },
  w_30px: {
    width: "30px",
  },
  ml_05: {
    marginLeft: "2.5px",
  },
  ml_1: {
    marginLeft: "5px",
  },
  ml_15: {
    marginLeft: "7.5px",
  },
  ml_2: {
    marginLeft: "10px",
  },
  ml_3: {
    marginLeft: "15px",
  },
  ml_35: {
    marginLeft: "17.5px",
  },
  ml_4: {
    marginLeft: "20px",
  },
  ml_6: {
    marginLeft: "30px",
  },
  ml_7: {
    marginLeft: "35px",
  },
  ml_85: {
    marginLeft: "42.5px",
  },
  mr_05: {
    marginRight: "2.5px",
  },
  mr_1: {
    marginRight: "5px",
  },
  mt_02: {
    marginTop: "1px",
  },
  mt_03: {
    marginTop: "1.5px",
  },
  mt_05: {
    marginTop: "2.5px",
  },
  mt_06: {
    marginTop: "3px",
  },
  mt_08: {
    marginTop: "4px",
  },
  mt_1: {
    marginTop: "5px",
  },
  mt_2: {
    marginTop: "10px",
  },
  mt_3: {
    marginTop: "15px",
  },
  mt_5: {
    marginTop: "25px",
  },
  mt_8: {
    marginTop: "40px",
  },
  mb_2: {
    marginBottom: "10px",
  },
  mb_3: {
    marginBottom: "15px",
  },
  mb_4: {
    marginBottom: "20px",
  },
  my_1: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  pl_1: {
    paddingLeft: "5px",
  },
  pt_1: {
    paddingTop: "5px",
  },
  pb_1: {
    paddingBottom: "5px",
  },
  pb_2: {
    paddingBottom: "10px",
  },
  px_05: {
    paddingLeft: "2.5px",
    paddingRight: "2.5px",
  },
  p_05: {
    padding: "0.5px 2.5px",
  },
  p_1: {
    padding: "5px",
  },
  line_height_1: {
    lineHeight: "1.38px",
  },
  font_bold: {
    fontFamily: "customerFont_600",
  },
  font_size_24: {
    fontSize: "24px",
  },
  font_size_20: {
    fontSize: "20px",
  },
  font_size_18: {
    fontSize: "18px",
  },
  font_size_16: {
    fontSize: "16px",
  },
  font_size_14: {
    fontSize: "14px",
  },
  font_size_12: {
    fontSize: "12px",
  },
  font_size_11: {
    fontSize: "11px",
  },
  font_size_10: {
    fontSize: "10px",
  },
  underline: {
    textDecoration: "underline",
  },
  border: {
    border: "1px solid #000000",
  },
  border_3: {
    border: "3px solid #000000",
  },
  borderBottom: {
    borderBottom: "1px solid #000000",
  },
  bg_black: {
    backgroundColor: "#000000",
  },
  checkbox: {
    marginRight: "5px",
    width: "18px",
    height: "18px",
    border: "1px solid #000000",
  },
  checkbox_sm: {
    marginRight: "2px",
    width: "12px",
    height: "12px",
    border: "1px solid #000000",
  },
  circle_lg: {
    width: "10px",
    height: "10px",
    border: "1px solid #000000",
    borderRadius: "50%",
  },
  circle_sm: {
    width: "6px",
    height: "6px",
    border: "1px solid #000000",
    borderRadius: "50%",
  },
  dot: {
    margin: "0px 2px",
    width: "6px",
    height: "6px",
    backgroundColor: "#000000",
    borderRadius: "50%",
  },
  tbWrap: {
    borderTop: "1px solid #000000",
    borderLeft: "1px solid #000000",
  },
  tbCell: {
    paddingLeft: "2.5px",
    paddingRight: "2.5px",
    borderBottom: "1px solid #000000",
    borderRight: "1px solid #000000",
  },
});
