import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { SettingAPI } from "../../../../axios/api";
import {
  useAbortedEffect,
  useSearch,
  useTreeList,
  usePop,
} from "../../../../components/hooks";

import ModuleBreadCrumb from "../../../../components/breadCrumb";
import ModulePopDepartmentTreeForm from "./popForm";
import ModulePopSettingJobtitleList from "./popJobtitle";
import {
  formatOptions,
  setPageSetting,
  formatTreeData,
} from "../../../../service/common";
import { system_dropdown } from "../../../../service/system";
import { option_enabled } from "../../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

export default function BackSettingDepartmentList() {
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbItem = [{ key: 1, label: "部門/職稱管理" }];
  const [reload, setReload] = useState({ list: false });
  // 篩選條件
  const defaultFilter = {
      page: -1,
      status: null,
    },
    pagePath = "/setting/department";
  const {
    filterData,
    setFilterData,
    search,
    setSearch,
    changeFilter,
    renderSearchWrap,
  } = useSearch({
    defaultFilter,
    pagePath: [pagePath],
    reloadAction: () => setReload((state) => ({ ...state, list: true })),
  });
  const { treeData, setTreeData, setTreeColumns, renderList, addRoot } =
    useTreeList({
      keyObj: {
        node: {
          key: "",
          label: "部門",
        },
      },
      action: {
        delete: (data) =>
          SettingAPI.updateDepartmentTree({
            data: {
              data: { insert: [], update: [], delete: data.ids },
            },
          }),
        reload: () => setReload((state) => ({ ...state, list: true })),
      },
      ModuleEditForm: ModulePopDepartmentTreeForm,
    });
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "jobtitle",
      title: () => "職稱管理",
      isOpen: false,
    },
  ]);
  const [optionData, setOptionData] = useState(null);

  useEffect(() => {
    let temp = formatOptions({
      enabled: option_enabled,
    });

    setOptionData((state) => ({
      ...state,
      ...temp,
    }));
  }, []);

  useEffect(() => {
    const renderBtnAction = (data) => (
      <div className="flex flex-wrap">
        <Button
          className="p-button-sm w-6rem"
          type="button"
          label="編輯職稱"
          onClick={() => openPop({ type: "jobtitle", data })}
        />
      </div>
    );

    setTreeColumns([
      {
        field: "name",
        header: "部門名稱",
        className: "p-2",
        expander: true,
      },
      {
        field: "department_code",
        header: "部門編號",
        className: "w-8rem lg:w-auto p-2",
      },
      {
        field: "jobtitle_list",
        header: "職稱",
        className: "w-7rem p-2",
        body: (data) => renderBtnAction(data),
      },
    ]);
  }, []);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = {
        ...search,
      };

      SettingAPI.getDepartmentTreeList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, message } = result;

        if (Response === 1) {
          let formatData = formatTreeData({ module: "table", childs: data });
          if (formatData[0]) formatData[0].isRoot = true;
          setTreeData((state) => ({ ...state, data: formatData }));
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload]
  );

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <h2 className="my-3 ml-1">部門/職稱管理</h2>

      <div className="border-round-lg bg-white p-3">
        {treeData.data.length === 0 &&
          renderSearchWrap({
            isShow: false,
            template: (
              <>
                {/* <div className="col-12 md:col-4 xl:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                <label
                  htmlFor="username"
                  className="w-4rem white-space-nowrap text-left mb-1"
                >
                  啟用狀態
                </label>
                <Dropdown
                  name="status"
                  className="w-full"
                  value={filterData.status}
                  onChange={(e) => changeFilter(e)}
                  options={optionData?.enabled ?? []}
                  optionLabel="label"
                  optionValue="value"
                  emptyFilterMessage={system_dropdown.emptyFilter}
                  showClear
                />
              </div> */}
                <div
                  className={classNames(
                    "col-12 flex align-items-end mt-2 md:mt-0"
                  )}
                >
                  {/* <Button
                  className="p-button-info p-button-sm w-auto ml-auto md:ml-0 md:mt-0 px-3"
                  type="button"
                  icon="pi pi-search"
                  label="查詢"
                  onClick={() =>
                    setSearch({
                      ...filterData,
                    })
                  }
                /> */}

                  <Button
                    className="p-button-warning p-button-sm w-auto md:ml-auto ml-3 px-3"
                    type="button"
                    label="新增根節點"
                    onClick={() => addRoot()}
                  />
                </div>
              </>
            ),
          })}

        <div className="mt-2">{renderList()}</div>
      </div>

      {renderDialog({
        className: "w-11 md:w-10 lg:w-8",
        children: (
          <>
            {isPop.jobtitle?.isOpen && (
              <ModulePopSettingJobtitleList
                prePopOption={popOption}
                closePrePop={closePop}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
