import { useState, useCallback } from "react";

import { Dialog } from "primereact/dialog";

const usePop = (setting = []) => {
  // 是否顯示彈出視窗
  const [isPop, setIsPop] = useState(
    setting.reduce((pre, cur) => ({ ...pre, [cur.key]: cur }), {})
  );
  // 彈出視窗資料
  const [popOption, setPopOption] = useState({
    type: "", // 開啟的pop
    data: {}, // 資料
  });

  // 開啟 彈出視窗
  const openPop = useCallback(
    (options) => {
      setPopOption(options);
      setIsPop({
        ...Object.values(isPop).reduce(
          (pre, cur) => ({
            ...pre,
            [cur.key]: {
              ...cur,
              isOpen: false,
            },
          }),
          {}
        ),
        [options.type]: {
          ...isPop[options.type],
          isOpen: true,
        },
      });
    },
    [isPop]
  );

  // 關閉 彈出視窗
  const closePop = useCallback(
    (options) => {
      let type = options.type ?? popOption.type;
      setIsPop((state) => ({
        ...state,
        [type]: {
          ...isPop[type],
          isOpen: false,
        },
      }));
    },
    [isPop]
  );

  const renderDialog = ({
    headerClassName = "border-bottom-none",
    contentClassName = "px-0",
    className = "",
    onHide = () => closePop({ type: popOption.type }),
    children = <></>,
    ...rest
  } = {}) => (
    <Dialog
      {...rest}
      header={
        <h2 className="text-center">
          {isPop[popOption.type]?.title(popOption) ?? ""}
        </h2>
      }
      headerClassName={headerClassName}
      contentClassName={contentClassName}
      visible={isPop[popOption.type]?.isOpen}
      className={className}
      onHide={() => onHide()}
      focusOnShow={false}
    >
      {children}
    </Dialog>
  );

  return {
    isPop,
    setIsPop,
    popOption,
    setPopOption,
    openPop,
    closePop,
    renderDialog,
  };
};

export default usePop;
