import html2canvas from "html2canvas";
import CryptoJS from "crypto-js";

// 取得 cookie 值
export function getCookie(name) {
  const cookieVal = `; ${document.cookie}`;
  const parts = cookieVal.split(`; ${name}=`);
  if (parts.length >= 2) return parts.pop().split(";").shift();
  return "";
}

// 取得 url search
export function getUrlSearch(name, search) {
  const parts = search.split(`${name}=`);
  if (parts.length >= 2) {
    return parts.pop().split("&")[0];
  }
  return "";
}

// 排除 JSON 資料
export function excludeJSON(data, exclude) {
  let temp = { ...data };
  exclude.forEach((item) => {
    delete temp[item];
  });

  return temp;
}

// 設定 formData 資料
export function formatFormData(data) {
  let formData = new FormData();
  Object.entries(data).forEach(([name, value]) => {
    formData.append(name, value);
  });

  return formData;
}

// 設定回傳資料
export function formatResData(oldData, newData) {
  let temp = JSON.parse(JSON.stringify(oldData));
  Object.entries(temp).forEach(([key, val]) => {
    if (
      newData[key] !== null &&
      newData[key] !== undefined &&
      newData[key] !== ""
    ) {
      temp[key] = newData[key];
    }
  });

  return temp;
}

// 排除obj內無資料的項目
export function cleanObj(data) {
  let temp = {};
  Object.entries(data).forEach(([key, value]) => {
    if (value !== null && value !== undefined && value !== "") {
      temp[key] = value;
    }
  });

  return temp;
}

// 轉換options資料
export function formatOptions(
  rawData,
  { uniqueKey = "value", showLabel = "label", childKey = "child" } = {}
) {
  let tempObj = {};

  function formatOption(data, items) {
    items.forEach((item) => {
      item["showLabel"] = item[showLabel];
      item["showValue"] = item[uniqueKey];
      data[item[uniqueKey]] = item;

      if (item[childKey]) {
        formatOption(data, item[childKey]);
      }
    });
  }

  Object.entries(rawData).forEach(([name, value]) => {
    tempObj[`${name}_obj`] = {};
    formatOption(tempObj[`${name}_obj`], value);
  });

  return { ...rawData, ...tempObj };
}

// 確認表單是否更新
export function checkFormUpdate(newData, rawData) {
  return Object.entries(newData).some(([key, val]) => {
    if (
      (rawData[key] === null ||
        rawData[key] === undefined ||
        rawData[key] === "") &&
      (val === null || val === undefined || val === "")
    ) {
      return false;
    }

    return rawData[key] != val;
  });
}

// 下載
export function exportFile(url, filename) {
  let ext = url.split(".");
  ext = ext[ext.length - 1];

  let link = document.createElement("a");
  document.body.appendChild(link);
  link.href = url;
  link.download = `${filename}.${ext}`;
  link.target = "_blank";
  link.click();
  document.body.removeChild(link);
}

// 列表 page 參數設定
export function setPageSetting(data) {
  sessionStorage.setItem("pageSetting", JSON.stringify(data));
}

// 取得 列表 page 參數
export function getPageSetting(page) {
  let pageSetting = sessionStorage.getItem("pageSetting");
  if (pageSetting) pageSetting = JSON.parse(pageSetting);
  if (pageSetting && page.indexOf(pageSetting.page) !== -1) return pageSetting;

  return null;
}

// excel 日期傳換
export function parseExcelDateToDate(serial) {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );
}

// 身分證驗證
export function idCardCheck(idCard) {
  //建立字母分數陣列(A~Z)
  let city = [
    1, 10, 19, 28, 37, 46, 55, 64, 39, 73, 82, 2, 11, 20, 48, 29, 38, 47, 56,
    65, 74, 83, 21, 3, 12, 30,
  ];
  idCard = idCard.toUpperCase();
  //使用「正規表達式」檢驗格式
  if (idCard.search(/^[A-Z]{1}[1-28-9A-D]{1}[0-9]{8}$/i) === -1) {
    return false;
  } else {
    //將字串分割為陣列(IE必需這麼做才不會出錯)
    let word = idCard.split("");
    let total = 0;
    if (idCard.search(/^[A-Z]{1}[1-28-9]{1}[0-9]{8}$/i) !== -1) {
      // 新版身分證
      total = city[word[0].charCodeAt(0) - 65];
      for (var i = 1; i <= 8; i++) {
        total += eval(word[i]) * (9 - i);
      }
      //補上檢查碼(最後一碼)
      total += eval(word[9]);
    } else if (idCard.search(/^[A-Z]{1}[A-D]{1}[0-9]{8}$/i) !== -1) {
      // 舊版身分證
      let conver = "ABCDEFGHJKLMNPQRSTUVXYWZIO";
      let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];
      idCard =
        String(conver.indexOf(idCard[0]) + 10) +
        String((conver.indexOf(idCard[1]) + 10) % 10) +
        idCard.slice(2);
      word = idCard.split("");
      for (let i = 0; i < word.length; i++) {
        let c = parseInt(word[i]);
        let w = weights[i];
        total += c * w;
      }
    }

    //檢查比對碼(餘數應為0);
    if (!(total % 10 === 0)) {
      return false;
    }
  }
  return true;
}

export function blobToFile({ blob, fileName }) {
  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type: blob.type,
  });
}

const canvasToBlob = async (canvas) => {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    });
  });
};

// dataURL to File
export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    // mime = arr[0].match(/:(.*?);/)[1],
    mime = arr[0].split(":")[1].split(";")[0],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

// 截圖 (html)
export async function convertToCanvas({ targetId }) {
  let target = document.getElementById(targetId);

  return await html2canvas(target, {
    backgroundColor: null,
    allowTaint: true,
    useCORS: true,
  }).then(async (canvas) => {
    let pngData = canvas.toDataURL("image/png");

    let blob = await canvasToBlob(canvas);

    return {
      pngData: pngData,
      file: dataURLtoFile(pngData, "map.png"),
      blob,
    };
  });
}

// 數字切割(逗號、千分位)
export function splitNumber(number) {
  let [numInteger, numDecimal] = String(number).split("."); // 原數字轉字串
  let tempCount = Math.floor(numInteger.length / 3); // 有幾組3位數
  let tempRemain = numInteger.length % 3; // 多幾位，放逗號左側
  let reStr = "";

  for (let i = 0; i < tempRemain; i++) {
    // 位數不為3的倍數，先將多餘的產生，之後再併逗號產生後續數字
    reStr += String(numInteger[i]);
  }

  for (let i = 0; i < tempCount; i++) {
    if ((tempRemain == 0 && i > 0) || tempRemain != 0) {
      // 位數為3的倍數，第一組前面不加逗號 || 位數不為3的倍數
      reStr += ",";
    }

    reStr += String(numInteger[tempRemain + 0 + 3 * i]);
    reStr += String(numInteger[tempRemain + 1 + 3 * i]);
    reStr += String(numInteger[tempRemain + 2 + 3 * i]);
  }

  if (tempCount == 0) {
    reStr = number;
  } else if (numDecimal) {
    reStr += `.${numDecimal}`;
  }

  return reStr;
}

// 小數補0
export function decimalAddZero({
  rawVal = 0,
  decimalCount = 0,
  decimalShowRaw = false,
  isZero = true,
} = {}) {
  // 若超出小數點位數，四捨五入
  let pow = Math.pow(10, decimalCount);
  rawVal = decimalShowRaw ? rawVal : Math.round(rawVal * pow) / pow;

  // 是否為負數
  let isNegative = rawVal < 0;
  rawVal = Math.abs(rawVal);

  // 分割 整數位 小數位
  let temp = String(rawVal),
    [intVal, doubleVal = ""] = temp.split(".");
  intVal = splitNumber(intVal);

  // 若小數位數不夠，補0
  let finalDouble = doubleVal;
  if (isZero && !decimalShowRaw && doubleVal.length < decimalCount) {
    for (let i = doubleVal.length; i < decimalCount; i++) finalDouble += "0";
  }

  let finalVal = intVal;
  if ((decimalCount > 0 || decimalShowRaw) && finalDouble)
    finalVal = intVal + "." + finalDouble;

  if (isNegative) finalVal = "-" + finalVal;

  return finalVal;
}

// 整理 Tree 層級資料
export const formatTreeData = ({
  module,
  key = "code",
  label = "label",
  childs,
} = {}) => {
  let tempData = [];
  childs.forEach(({ sub, ...item }) => {
    let children = [];

    if (sub) {
      children = formatTreeData({ module, key, label, childs: sub });
    }

    tempData.push(
      module === "table"
        ? {
            key: item[key],
            data: item,
            children,
          }
        : {
            key: item[key],
            label: item[label],
            data: item[label],
            children,
          }
    );
  });

  return tempData;
};

// 加密
export const handleEncrypt = ({ CRYPTO_KEY, text } = {}) => {
  const SHA256 = CryptoJS.SHA256(CRYPTO_KEY).toString(),
    MD5 = CryptoJS.MD5(CRYPTO_KEY).toString();
  return CryptoJS.AES.encrypt(text, CryptoJS.enc.Hex.parse(SHA256), {
    iv: CryptoJS.enc.Hex.parse(MD5),
  }).toString();
};

// 解密
export const handleDecrypt = ({ CRYPTO_KEY, text } = {}) => {
  const SHA256 = CryptoJS.SHA256(CRYPTO_KEY).toString(),
    MD5 = CryptoJS.MD5(CRYPTO_KEY).toString();
  return CryptoJS.AES.decrypt(text, CryptoJS.enc.Hex.parse(SHA256), {
    mode: CryptoJS.mode.CBC,
    iv: CryptoJS.enc.Hex.parse(MD5),
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
};
