import request from "../request";
import Url from "../url/contract";

const laborContractFileUpload = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.laborContractFileUpload + path, data, options, {
    requestSecret: false,
  });
};
const laborContractDeleteFile = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.laborContractDeleteFile + path, data, options);
};
const signFileUpload = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.signFileUpload + path, data, options, {
    requestSecret: false,
  });
};
const signDeleteFile = ({ path = "", data, options = {} } = {}) => {
  return request.post(Url.signDeleteFile + path, data, options);
};

export default {
  laborContractFileUpload,
  laborContractDeleteFile,
  signFileUpload,
  signDeleteFile,
};
