import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import {
  useAbortedEffect,
  useHookForm,
  usePop,
  useMultiRecords,
} from "../../../../components/hooks";
import { MemberAPI, SettingAPI } from "../../../../axios/api";
import {
  axios,
  AxiosMulti,
  formatApiResult,
} from "../../../../axios/request/common";

import ModuleSignOnline from "./signOnline";
import ModulePopUploadConfirm from "./popConfirm";
import { ModuleProfileHumanChangeRecords } from "./form";
import {
  FormInputtext,
  FormDropdown,
  FormCalendar,
  FormTreeSelect,
  FormRadio,
} from "../../../../components/formElement";
import {
  formatOptions,
  formatResData,
  formatTreeData,
  checkFormUpdate,
  idCardCheck,
} from "../../../../service/common";
import { option_salary_type } from "../../../../service/option";

import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

export default function ModulePopContract({
  prePopOption,
  closePrePop,
  setPreReload,
}) {
  const dispatch = useDispatch();
  const [contractFile, setContractFile] = useState(null);
  const [optionData, setOptionData] = useState(null);
  const { formatMultiRecords } = useMultiRecords();
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "sign",
      title: () => "",
      isOpen: false,
    },
    {
      key: "confirm",
      title: () => "",
      isOpen: false,
    },
  ]);
  // 表單欄位預設值
  const defaultValues = {
    id: null,
    method: 1,
    departmentid: null,
    jobtitleid: null,
    ch_last_name: "",
    ch_first_name: "",
    id_number: "",
    contact_address: "",
    entry_date: null,
    human_change_records: [],
  };
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
    watch,
    yup,
    setSchema,
    errors,
    setting,
  } = useHookForm({
    defaultValues,
  });
  // 監聽欄位
  const watchColumn = {
    departmentid: watch("departmentid"),
  };

  useEffect(() => {
    setSchema(
      yup
        .object({
          departmentid: yup.number().required("必填").nullable(),
          jobtitleid: yup.number().required("必填").nullable(),
          ch_last_name: yup.string().required("必填"),
          ch_first_name: yup.string().required("必填"),
          id_number: yup
            .string()
            .test("isValidPass", "身分證字號格式錯誤", (value) => {
              return idCardCheck(value);
            })
            .required("必填"),
          contact_address: yup.string().required("必填"),
          entry_date: yup.string().required("必填").nullable(),
          human_change_records: yup
            .array()
            .of(
              yup.object().shape({
                salary: yup.number().required("必填").nullable(),
              })
            )
            .test("isValidPass", "必填", (value) => {
              return value.filter((item) => item.flag !== "delete").length > 0;
            }),
        })
        .required()
    );
  }, []);

  useEffect(() => {
    if (!optionData) return;

    let submitData = getValues();
    let match =
      optionData?.jobtitle?.find((item) => item.id === submitData.jobtitleid) ??
      {};

    setValue("jobtitleid", match.id ?? null);
  }, [watchColumn.departmentid]);

  useAbortedEffect((signal) => {
    dispatch(setProgress(true));

    let params = {
        status: {
          status: 1,
        },
        list: {
          page: -1,
        },
        form: {
          id: prePopOption.data.id,
        },
      },
      promise = [
        SettingAPI.getDepartmentTreeList({
          data: params.list,
          options: { signal },
        }),
        SettingAPI.getJobTitleList({
          data: { ...params.list, ...params.status },
          options: { signal },
        }),
        MemberAPI.getMemberDetailsData({
          data: params.form,
          options: { signal },
        }),
      ];

    AxiosMulti(promise).then(
      axios.spread(
        (result_department = {}, result_jobtitle = {}, result_form = {}) => {
          let tempOptions = {};

          // 部門
          let { data: result_department_data } = result_department;
          if (result_department.Response === 1) {
            result_department_data = formatTreeData({
              module: "select",
              key: "id",
              label: "name",
              childs: result_department_data,
            });
            tempOptions = {
              ...tempOptions,
              ...formatOptions(
                { department: result_department_data },
                { uniqueKey: "key", showLabel: "label", childKey: "children" }
              ),
            };
          }

          // 職稱
          let { data: result_jobtitle_data } = result_jobtitle;
          if (result_jobtitle.Response === 1) {
            tempOptions = {
              ...tempOptions,
              ...formatOptions(
                { jobtitle: result_jobtitle_data },
                { uniqueKey: "id", showLabel: "name" }
              ),
            };
          }

          // 表單資料
          let { data: result_form_data } = result_form;
          if (result_form.Response === 1) {
            let temp = formatResData(defaultValues, result_form_data);
            temp.human_change_records = temp.human_change_records.map(
              (item) => ({
                ...item,
                is_str: !tempOptions.department_obj[item.department]?.showLabel,
              })
            );

            tempOptions.form = temp;
            reset(temp);
          }

          let isError = formatApiResult({
            data: [result_department, result_jobtitle, result_form],
            action: (message) => {
              dispatch(
                setToast({
                  severity: "error",
                  summary: message,
                  detail: "",
                })
              );
            },
          });
          if (isError) {
            dispatch(setProgress(false));
            return;
          }

          setOptionData({
            ...tempOptions,
            ...formatOptions({
              salary_type: option_salary_type,
            }),
          });

          dispatch(setProgress(false));
        }
      )
    );
  }, []);

  // 上傳檔案
  const uploadFile = (e) => {
    let { files } = e.target;

    // 至少一個圖檔
    if (files.length != 0) {
      for (const item of files) {
        let reader = new FileReader();
        reader.onload = (event) => {
          let path = event.target.result;
        };
        reader.readAsDataURL(item);
      }

      if (files[0]) {
        setContractFile(files[0]);
        openPop({
          type: "confirm",
          data: {
            file: files[0],
          },
        });
      }

      setTimeout(() => {
        document.getElementById("uploadFile").value = "";
      }, 200);
    }
  };

  const onSubmit = async (submitData) => {
    dispatch(setProgress(true));

    let isAdd = false,
      jsonData = {
        id: prePopOption.data.id,
        ...submitData,
      };

    submitData.human_change_records = submitData.human_change_records.map(
      (item) => ({
        ...item,
        department:
          optionData.department_obj[item.department]?.showLabel ??
          item.department,
        job_title:
          optionData.jobtitle_obj[item.job_title]?.showLabel ?? item.job_title,
      })
    );

    let human_change_records = formatMultiRecords({
      data: submitData.human_change_records,
      isAdd,
    });
    human_change_records.insert = human_change_records.insert.map((item) => ({
      ...item,
      salary_info: item.salary_info.map(({ id, flag, ...rest }) => ({
        ...rest,
      })),
    }));
    human_change_records.update = human_change_records.update.map((item) => ({
      ...item,
      salary_info: formatMultiRecords({
        data: item.salary_info,
        isAdd: false,
      }),
    }));
    jsonData.human_change_records = human_change_records;
    delete jsonData.method;

    if (!checkFormUpdate(jsonData, prePopOption.data)) {
      goSign({ data: optionData.form });
      dispatch(setProgress(false));
      return;
    }

    MemberAPI.updateMemberDetailsData({
      data: jsonData,
    }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        goSign({ data });
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `修改失敗`,
            detail: message ?? "",
          })
        );
      }
      dispatch(setProgress(false));
    });
  };

  const goSign = ({ data }) => {
    openPop({ type: "sign", data });
  };

  return (
    <>
      {/* <div className="text-center px-3 pb-3">
        <Button
          className="p-button-main px-4 mr-4"
          type="button"
          label="線上簽署"
          onClick={() => openPop({ type: "sign" })}
        />
        <Button
          className="p-button-warning px-4"
          type="button"
          label="檔案上傳"
          onClick={() => document.getElementById("uploadFile").click()}
        />
        <input
          id="uploadFile"
          className="hidden"
          name="uploadFile"
          type="file"
          accept=".pdf"
          multiple={false}
          onChange={(e) => uploadFile(e)}
        />
      </div> */}

      <div className="px-3 pb-3">
        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid">
          <div className="flex flex-column align-items-start sm:flex-row field col-12">
            <label
              htmlFor="type"
              className={classNames(
                { "p-error": errors.type },
                "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
              )}
            >
              方式
            </label>
            <div className="col-12 sm:col-9 lg:col-6">
              <div className="flex flex-wrap">
                <FormRadio
                  setting={setting}
                  data={{
                    name: "method",
                    label: "線上簽署",
                    value: 1,
                  }}
                />
                <FormRadio
                  setting={setting}
                  data={{
                    name: "method",
                    label: "檔案上傳",
                    value: 2,
                  }}
                />
              </div>

              {getValues("method") === 1 && (
                <div className="text-lg text-orange-400">
                  請確認下方欄位資料
                </div>
              )}
            </div>
          </div>

          {getValues("method") === 1 && (
            <>
              <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
                <label
                  htmlFor="departmentid"
                  className={classNames(
                    { "p-error": errors.departmentid || errors.jobtitleid },
                    "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
                  )}
                >
                  <span className="text-red-400 mr-1">*</span>部門/職稱
                </label>
                <div className="flex col-12 sm:col-9 lg:col-6">
                  <FormTreeSelect
                    className="w-6"
                    setting={setting}
                    data={{
                      name: "departmentid",
                      placeholder: "部門",
                      options: optionData?.department ?? [],
                      filter: true,
                    }}
                  />
                  <FormDropdown
                    className="w-6 ml-2"
                    setting={setting}
                    data={{
                      name: "jobtitleid",
                      placeholder: "職稱",
                      options:
                        (optionData?.jobtitle || []).filter(
                          (item) =>
                            item.departmentid === getValues("departmentid")
                        ) ?? [],
                      optionLabel: "name",
                      optionValue: "id",
                      showClear: false,
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
                <label
                  htmlFor="ch_last_name"
                  className={classNames(
                    { "p-error": errors.ch_last_name || errors.ch_first_name },
                    "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
                  )}
                >
                  <span className="text-red-400 mr-1">*</span>中文姓名
                </label>
                <div className="flex col-12 sm:col-9 lg:col-6">
                  <FormInputtext
                    className="w-5 lg:w-5"
                    setting={setting}
                    data={{
                      name: "ch_last_name",
                      placeholder: "姓",
                    }}
                  />
                  <FormInputtext
                    className="w-7 lg:w-7 ml-2"
                    setting={setting}
                    data={{
                      name: "ch_first_name",
                      placeholder: "名",
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
                <label
                  htmlFor="id_number"
                  className={classNames(
                    { "p-error": errors.id_number },
                    "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
                  )}
                >
                  <span className="text-red-400 mr-1">*</span>身分證字號
                </label>
                <div className="col-12 sm:col-9 lg:col-6">
                  <FormInputtext
                    className="w-full"
                    setting={setting}
                    data={{
                      name: "id_number",
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
                <label
                  htmlFor="contact_address"
                  className={classNames(
                    { "p-error": errors.contact_address },
                    "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
                  )}
                >
                  <span className="text-red-400 mr-1">*</span>通訊地址
                </label>
                <div className="col-12 sm:col-9 lg:col-6">
                  <FormInputtext
                    setting={setting}
                    data={{
                      name: "contact_address",
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
                <label
                  htmlFor="entry_date"
                  className={classNames(
                    { "p-error": errors.entry_date },
                    "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
                  )}
                >
                  <span className="text-red-400 mr-1">*</span>入職日期
                </label>
                <div className="col-12 sm:col-9 lg:col-6">
                  <FormCalendar
                    className="w-full"
                    setting={setting}
                    data={{
                      name: "entry_date",
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-column align-items-start sm:flex-row field col-12">
                <label
                  htmlFor="human_change_records"
                  className={classNames(
                    { "p-error": errors.human_change_records },
                    "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0 sm:mt-2"
                  )}
                >
                  <span className="text-red-400 mr-1">*</span>人事動態記錄
                </label>
                <div className="col-12 sm:col-9">
                  <ModuleProfileHumanChangeRecords
                    options={{
                      setting,
                      getValues,
                      setValue,
                      trigger,
                      watch,
                      optionData,
                      isLimitOne: true,
                    }}
                  />
                </div>
              </div>

              <div className="col-12 flex">
                <Button
                  className="p-button-main p-button-sm px-4 ml-auto mr-2"
                  type="submit"
                  label="下一步"
                />
              </div>
            </>
          )}

          {getValues("method") === 2 && (
            <>
              <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
                <label
                  htmlFor="entry_date"
                  className={classNames(
                    { "p-error": errors.entry_date },
                    "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
                  )}
                >
                  <span className="text-red-400 mr-1">*</span>檔案
                </label>
                <div className="col-12 sm:col-9 lg:col-6">
                  <Button
                    className="p-button-warning p-button-sm px-4"
                    type="button"
                    label="選擇"
                    onClick={() =>
                      document.getElementById("uploadFile").click()
                    }
                  />
                  <input
                    id="uploadFile"
                    className="hidden"
                    name="uploadFile"
                    type="file"
                    accept=".pdf"
                    multiple={false}
                    onChange={(e) => uploadFile(e)}
                  />
                </div>
              </div>
            </>
          )}
        </form>
      </div>

      {renderDialog({
        className: classNames("w-11 md:w-9", {
          "h-full": isPop.sign?.isOpen,
          "max-w-40rem": isPop.confirm?.isOpen,
        }),
        children: (
          <>
            {isPop.sign?.isOpen && (
              <div className="h-full px-3">
                <ModuleSignOnline
                  prePopOption={popOption}
                  closePrePop={closePop}
                  closeMainPop={closePrePop}
                  optionData={optionData}
                  setPreReload={setPreReload}
                />
              </div>
            )}
            {isPop.confirm?.isOpen && (
              <ModulePopUploadConfirm
                prePopOption={popOption}
                closePrePop={closePop}
                closeMainPop={closePrePop}
                setPreReload={setPreReload}
                memberId={prePopOption.data.id}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
