import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";

export default function FormRadio(props) {
  const {
    className = "",
    setting: { Controller, control, trigger, isSubmitted, isEdit = true },
    data: { name, label, value, disabled = false, cancel = false },
  } = props;

  const onChange = (e, field) => {
    let value = e.value,
      checked = e.target.checked;
    if (cancel && !checked) {
      value = null;
    }

    field.onChange(value);

    if (isSubmitted) {
      trigger();
    } else {
      trigger(field.name);
    }
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <div
              className={classNames(
                "relative flex align-items-center",
                {
                  "min-w-220 ml-1 mr-3 my-2": !className,
                },
                className
              )}
            >
              <RadioButton
                inputId={`${field.name}_${value}`}
                className={classNames({ "p-invalid": fieldState.invalid })}
                {...field}
                value={value}
                checked={value === field.value}
                onChange={(e) => onChange(e, field)}
                disabled={!isEdit || disabled}
              />
              {label && (
                <label htmlFor={`${field.name}_${value}`} className="ml-2">
                  {label}
                </label>
              )}
            </div>
          </>
        )}
      />
    </>
  );
}
