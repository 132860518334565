import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { RecordAPI } from "../../../../axios/api";
import {
  useAbortedEffect,
  useSearch,
  useTable,
  usePop,
  useConfirm,
} from "../../../../components/hooks";
import moment from "moment";

import ModuleBreadCrumb from "../../../../components/breadCrumb";
import ModulePopRecordAwardForm from "./popForm";
import {
  formatOptions,
  setPageSetting,
  formatNumber,
} from "../../../../service/common";
import { system_dropdown, system_table } from "../../../../service/system";
import { option_award } from "../../../../service/option";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

export default function BackRecordAwardList() {
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbItem = [{ key: 1, label: "獎勵管理" }];
  const [reload, setReload] = useState({ list: false });
  const {
    setTbData,
    tbTotalRecords,
    setTbTotalRecords,
    setTbFirstIndex,
    setTbRows,
    setTbColumns,
    renderTable,
    setPageAction,
  } = useTable();
  // 篩選條件
  const defaultFilter = {
      page: 1,
      size: system_table.size,
    },
    pagePath = "/record/award";
  const {
    filterData,
    setFilterData,
    search,
    setSearch,
    changeFilter,
    renderSearchWrap,
  } = useSearch({
    defaultFilter,
    pagePath: [pagePath],
    tbSetting: { setTbFirstIndex, setTbRows },
    reloadAction: () => setReload((state) => ({ ...state, list: true })),
  });
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "form",
      title: (props) => `${props?.data?.id ? "編輯" : "新增"}`,
      isOpen: false,
    },
  ]);
  const { confirmOption, setConfirmOption, renderConfirm } = useConfirm();
  const [optionData, setOptionData] = useState(null);

  useEffect(() => {
    const action = ({ startIndex, rows }) => {
      let temp = {
        ...search,
        page: Math.floor(startIndex / rows) + 1,
        size: rows,
      };
      setFilterData(temp);
      setSearch(temp);
    };
    setPageAction(
      () =>
        ({ startIndex, rows }) =>
          action({ startIndex, rows })
    );
  }, [search]);

  useEffect(() => {
    let temp = formatOptions({
      award: option_award,
    });

    setOptionData((state) => ({
      ...state,
      ...temp,
    }));
  }, []);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list || !optionData) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = {
        ...search,
      };

      RecordAPI.getAwardRecordsList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, total, message } = result;

        if (Response === 1) {
          data = data.map((item) => ({
            ...item,
            name_format: `${item.memberdetails.ch_last_name ?? ""}${
              item.memberdetails.ch_first_name ?? ""
            }`,
            type_format: optionData.award_obj[item.type]?.label,
          }));
          setTbData(data);
          setTbTotalRecords(total);
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload, optionData]
  );

  useEffect(() => {
    if (!optionData) return;

    const renderDate = (date) => moment(date).format("YYYY/MM/DD");

    const renderBtnAction = (data) => (
      <>
        <Button
          className="p-button-sm"
          type="button"
          label="編輯"
          onClick={() => openPop({ type: "form", data })}
        />
        <Button
          id={`button_delete_${data.id}`}
          className="p-button-danger p-button-sm ml-2"
          type="button"
          label="刪除"
          onClick={() =>
            setConfirmOption({
              setting: {
                target: document.getElementById(`button_delete_${data.id}`),
                message: "確認是否刪除?",
                icon: "pi pi-exclamation-triangle",
                visible: !confirmOption.setting.visible,
                acceptLabel: "是",
                rejectLabel: "否",
                accept: () => deleteAction({ data }),
                reject: () => null,
              },
              reOpen: confirmOption.setting.visible,
            })
          }
        />
      </>
    );

    setTbColumns([
      {
        field: "username",
        header: "員工編號",
        className: "flex-grow-1",
        style: { flexBasis: "70px" },
      },
      {
        field: "name_format",
        header: "姓名",
        className: "flex-grow-1",
        style: { flexBasis: "60px" },
      },
      {
        field: "type_format",
        header: "獎勵類型",
        className: "flex-grow-1",
        style: { flexBasis: "70px" },
      },
      {
        field: "date",
        header: "獎勵日期",
        className: "flex-grow-1",
        style: { flexBasis: "70px" },
        body: (data) => renderDate(data.date),
      },
      {
        field: "id",
        header: "進階",
        className: "w-9rem froze-right",
        alignFrozen: "right",
        frozen: true,
        body: (data) => renderBtnAction(data),
      },
    ]);
  }, [pagePath, optionData]);

  const deleteAction = ({ data }) => {
    dispatch(setProgress(true));

    let jsonData = { data: [data.id] };

    RecordAPI.deleteAwardRecords({ data: jsonData }).then((result) => {
      let { Response } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "成功刪除",
            detail: "",
          })
        );
        setReload((state) => ({
          ...state,
          list: true,
        }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: "刪除失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      {renderConfirm}

      <ModuleBreadCrumb crumbItem={crumbItem} />

      <h2 className="my-3 ml-1">獎勵管理</h2>

      <div className="border-round-lg bg-white p-3">
        {renderSearchWrap({
          isShow: false,
          template: (
            <>
              {/* <div className="col-12 md:col-4 xl:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                <label
                  htmlFor="username"
                  className="w-4rem white-space-nowrap text-left mb-1"
                >
                  啟用狀態
                </label>
                <Dropdown
                  name="enabled"
                  className="w-full"
                  value={filterData.enabled}
                  onChange={(e) => changeFilter(e)}
                  options={optionData?.enabled ?? []}
                  optionLabel="label"
                  optionValue="value"
                  emptyFilterMessage={system_dropdown.emptyFilter}
                  showClear
                />
              </div> */}
              <div
                className={classNames(
                  "col-12 flex align-items-end mt-2 md:mt-0"
                  // md:col-8 xl:col-9
                )}
              >
                {/* <Button
                  className="p-button-info p-button-sm w-auto ml-auto md:ml-0 md:mt-0 px-3"
                  type="button"
                  icon="pi pi-search"
                  label="查詢"
                  onClick={() => {
                    setSearch({
                      ...filterData,
                      page: 1,
                    });
                    setTbFirstIndex(0);
                  }}
                /> */}

                <Button
                  className="p-button-warning p-button-sm w-auto md:ml-auto ml-3 px-3"
                  type="button"
                  label="新增"
                  onClick={() => openPop({ type: "form", data: null })}
                />
              </div>
            </>
          ),
        })}

        {renderTable({
          className: "mt-2",
          options: { scrollable: true, scrollDirection: "both" },
        })}
      </div>

      {renderDialog({
        className: "w-11 md:w-10 lg:w-8 max-w-30rem",
        children: (
          <>
            {isPop.form?.isOpen && (
              <ModulePopRecordAwardForm
                prePopOption={popOption}
                closePrePop={closePop}
                setReload={setReload}
                optionData={optionData}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
