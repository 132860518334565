import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { CommonAPI } from "../../axios/api";
import { useClearLocalStorage, useHookForm } from "../../components/hooks";

import ModuleBreadCrumb from "../../components/breadCrumb";
import { FormInputpassword } from "../../components/formElement/index";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function BackChangePassword(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clearLocalStorage = useClearLocalStorage();
  const crumbItem = [
    {
      key: 1,
      label: "變更密碼",
    },
  ];
  // 表單欄位預設值
  const defaultValues = {
    old_password: "",
    password: "",
    repassword: "",
  };
  const {
    handleSubmit,
    trigger,
    reset,
    watch,
    yup,
    setSchema,
    errors,
    setting,
  } = useHookForm({
    defaultValues,
  });
  // 監聽欄位
  const watchColumn = {
    password: watch("password"),
    repassword: watch("repassword"),
  };

  useEffect(() => {
    setSchema(
      yup
        .object({
          old_password: yup.string().required().nullable(),
          password: yup
            .string()
            .test(
              "isValidPass",
              "必填",
              (value, { from: [{ value: data }] }) => {
                return data.repassword
                  ? value && value === data.repassword
                  : true;
              }
            ),
          repassword: yup
            .string()
            .test(
              "isValidPass",
              "必填",
              (value, { from: [{ value: data }] }) => {
                return data.password ? value && value === data.password : true;
              }
            ),
        })
        .required()
    );
  }, []);

  // 動態判斷欄位
  useEffect(() => {
    if (setting.isSubmitted) trigger(["password", "repassword"]);
  }, [watchColumn.password, watchColumn.repassword]);

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    let jsonData = {
      old_password: submitData.old_password,
      new_password: submitData.password,
    };

    if (submitData.old_password === submitData.password) {
      reset(defaultValues);
      dispatch(
        setToast({
          severity: "warn",
          summary: "新舊密碼不可相同",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      return;
    }

    CommonAPI.changePassword({
      data: jsonData,
    }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: `修改成功`,
            detail: "請重新登入",
          })
        );

        // 清除 loacl 資料
        clearLocalStorage();

        // 導向登入畫面
        navigate("/login");
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `修改失敗`,
            detail: message ?? "",
          })
        );
      }
      dispatch(setProgress(false));
    });
  };

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <h2 className="my-3 ml-1">變更密碼</h2>

      <div className="border-round-lg bg-white p-3">
        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid">
          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="old_password"
              className={classNames(
                { "p-error": errors.old_password },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              舊密碼
            </label>
            <div className="col-12 sm:col-10 md:col-7 lg:col-4">
              <FormInputpassword
                setting={setting}
                data={{ name: "old_password" }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="password"
              className={classNames(
                { "p-error": errors.password },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              新密碼
            </label>
            <div className="col-12 sm:col-10 md:col-7 lg:col-4">
              <FormInputpassword
                setting={setting}
                data={{
                  name: "password",
                }}
              />
            </div>
          </div>

          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="repassword"
              className={classNames(
                { "p-error": errors.repassword },
                "col-12 sm:col-2 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              確認密碼
            </label>
            <div className="col-12 sm:col-10 md:col-7 lg:col-4">
              <FormInputpassword
                setting={setting}
                data={{ name: "repassword" }}
              />
            </div>
          </div>

          <div className="field col-12 text-right">
            <Button type="submit" label="儲存" className="ml-2 px-3" />
          </div>
        </form>
      </div>
    </>
  );
}
