import { useEffect } from "react";
import { useHookForm } from "../../../../components/hooks";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { SettingAPI } from "../../../../axios/api";

import { FormInputtext } from "../../../../components/formElement";
import { formatResData, checkFormUpdate } from "../../../../service/common";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function ModulePopJobtitleTreeForm({
  prePopOption,
  closePrePop,
  formOptions,
  reloadAction,
}) {
  const dispatch = useDispatch();
  // 表單欄位預設值
  const defaultValues = {
    name: "",
  };
  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    yup,
    setSchema,
    errors,
    setting,
    setIsFormUpdate,
  } = useHookForm({
    defaultValues,
  });

  useEffect(() => {
    setSchema(
      yup
        .object({
          name: yup.string().required("必填"),
        })
        .required()
    );
  }, []);

  useEffect(() => {
    if (!prePopOption.data.data) return;

    reset(prePopOption.data.data);
  }, [prePopOption]);

  const onSubmit = (submitData) => {
    dispatch(setProgress(true));
    setIsFormUpdate(false);

    let jsonData = formatResData(defaultValues, submitData);
    jsonData.departmentid = formOptions.departmentid;

    if (!checkFormUpdate(jsonData, prePopOption.data ?? {})) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      closePrePop({ type: "form" });
      return;
    }

    let promise,
      toast = "",
      saveData = { insert: [], update: [], delete: [] };
    if (prePopOption.data.parentcode === "root") {
      // 新增
      jsonData.data = [];
      promise = SettingAPI.setJobTitleTree({
        data: {
          data: jsonData,
        },
      });
      toast = "新增";
    } else {
      // 修改
      if (prePopOption.data.parentcode !== "self") {
        jsonData.parentcode = prePopOption.data.parentcode;
        saveData.insert = [jsonData];
      } else {
        jsonData.id = prePopOption.data.data.id;
        saveData.update = [jsonData];
      }
      promise = SettingAPI.updateJobTitleTree({
        data: {
          data: saveData,
        },
      });
      toast = "修改";
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: `${toast}成功`,
            detail: "",
          })
        );
        closePrePop({ type: "form" });
        reloadAction();
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: `${toast}失敗`,
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  return (
    <>
      <div className="px-4">
        <form onSubmit={handleSubmit(onSubmit)} className="formgrid grid">
          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12">
            <label
              htmlFor="name"
              className={classNames(
                { "p-error": errors.name },
                "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              職稱
            </label>
            <div className="col-12 sm:col-9">
              <FormInputtext
                setting={setting}
                data={{
                  name: "name",
                }}
              />
            </div>
          </div>

          <div className="col-12 text-right">
            <Button
              type="button"
              label="取消"
              className="p-button-secondary p-button-outlined ml-auto px-3"
              onClick={() => closePrePop({ type: "form" })}
            />
            <Button type="submit" label="儲存" className="ml-2 px-3" />
          </div>
        </form>
      </div>
    </>
  );
}
