import { Button } from "primereact/button";

export default function ModulePopTemplateConfirm(props) {
  const { prePopOption, closePrePop, saveData } = props;

  return (
    <>
      <div className="text-center px-3 pb-3">
        <div className="inline-flex align-items-center justify-content-center w-5rem h-5rem text-6xl text-white border-circle bg-red-500">
          ！
        </div>
        {prePopOption.data.score !== 100 && (
          <div className="text-xl font-bold text-red-400 my-3">
            考核總分未滿 100 分<br />
            (目前：{prePopOption.data.score}分)
          </div>
        )}
        {prePopOption.data.type === "publish" &&
          prePopOption.data.weights !== 100 && (
            <div className="text-xl font-bold text-red-400 my-3">
              評核者權重未滿 100 %<br />
              (目前：{prePopOption.data.weights}%)
            </div>
          )}
        <h1 className="mt-4 mb-6">確定要儲存?</h1>
        <div className="w-full">
          <Button
            className="p-button-secondary px-4"
            type="button"
            label="取消"
            onClick={() => closePrePop({ type: "confirm" })}
          />
          <Button
            className="ml-4 px-4"
            type="button"
            label="確定"
            onClick={() => saveData()}
          />
        </div>
      </div>
    </>
  );
}
